import { WizardTranslator } from '../../translators/wizardTranslator';
import { createAppAsyncThunk } from '../../../hooks/redux';
import { StoreSelector } from '@teleport/schemas-protobuf';
// import { IWizardData } from '../slices/wizardSlice';

export const wizardGetState = createAppAsyncThunk('wizard/wizardGetState', async (params: {selector?: StoreSelector}, thunkAPI) => {
  const { selector = StoreSelector.UNSPECIFIED } = params;
  const wizardState = await thunkAPI.extra.hubApi.wizardGetState({selector});
  const state = thunkAPI.getState();
  const config = state.config.config;

  if (wizardState.infra.portApiUrl && wizardState.infra.portApiUrl !== config.portApiUrl) {
    thunkAPI.extra.portClientApi.init(wizardState.infra.portApiUrl);
    thunkAPI.extra.portOwnerApi.init(wizardState.infra.portApiUrl);
    thunkAPI.extra.portAdminApi.init(wizardState.infra.portApiUrl);
  }

  return WizardTranslator.fromWizardStateResponse(wizardState, config.toggles.debugWizard);
});
