import { forwardRef, memo, ReactNode, useEffect, } from 'react';
import { layoutRules } from './layout.style';
import { useStyle } from '../../utils/theme/useStyle';

interface ILayout {
  children: ReactNode;
  header?: ReactNode;
  footer?: ReactNode;
  mainFlexColumn?: boolean;
  padding?: string;
  position?: 'fixed';
  top?: number;
  left?: number;
  zIndex?: number;
}
export const Layout = memo(
  forwardRef<HTMLDivElement, ILayout>(function Layout(props, ref) {

    const {
      children,
      header,
      footer,
      padding,
      top,
      left,
      position,
      zIndex,
    } = props;

    // // Telegram scroll fix !!!
    useEffect(() => {
      const overflow = 1;
      function setupDocument() {
        document.body.style.overflowY = 'hidden';
        document.body.style.marginTop = `${overflow}px`;
        document.body.style.height = window.innerHeight + overflow + 'px';
        document.body.style.paddingBottom = `${overflow}px`;
        // смещаем окно так, чтобы body оказался в верхнем левом углу
        window.scrollTo(0, overflow);
        // setTimeout(() => {
        //   document.body.style.marginTop = '';
        //   document.body.style.paddingBottom = '';
        //   window.scrollTo(0, 0);
        // }, 100)
      }

      const onScroll = () => {
        window.scrollTo(0, overflow);
      }

      setupDocument();
      window.addEventListener('focusout', onScroll);
      return () => {
        window.removeEventListener('focusout', onScroll);
      }
    }, []);

    const { css } = useStyle(layoutRules, {
      mainFlexColumn: props.mainFlexColumn,
      padding,
      position,
      top,
      left,
      zIndex,
    });



    return (
      <div className={`${css.root} root-layout`} ref={ref}>
        {header}
        <main className={css.main}>{children}</main>
        {footer}
      </div>
    );
  }),
);
