import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { createAppAsyncThunk } from '../../hooks/redux';
import { openErrorModal } from './modalErrorSlice';
import type { Timestamp } from '@bufbuild/protobuf';
import { NewsLetterTranslator } from '../translators/newslettersTranslator';

interface INewslettersSlice {
  newsLetters: INewsLetter[];
  newslettersListStatus: NetworkStatus;
  createNewsletterNetworkStatus: NetworkStatus;
}

interface INewsLetterStatistic {
  total: number;
  sent: number;
}

interface ITimeLifecycle {
  createdAt?: Timestamp;
  updatedAt?: Timestamp;
  deletedAt?: Timestamp;
}

export interface INewsLetter {
  uuid: string;
  imageUrl: string;
  title: string;
  messageText: string;
  statistic?: INewsLetterStatistic;
  lifecycle?: ITimeLifecycle;
}

export interface INewsLetterData {
  title: string,
  messageText: string,
  total: number,
}

interface INewsLetterParams {
  data: INewsLetterData,
  imageFile: File,
}

export const getNewsLetterList = createAppAsyncThunk(
  'newsletters/getNewsLetterList',
  async (_, thunkAPI) => {
    return await thunkAPI.extra.portOwnerApi().newsLetterList({})
  },
);

export const createNewsLetter = createAppAsyncThunk('newsletters/createNewsLetter',
  async ({ data, imageFile }: INewsLetterParams, thunkAPI) => {
    let imageUrls: string[] = [];

    if (imageFile) {
      try {
        imageUrls = await thunkAPI.extra.filesUpload([imageFile]);
      } catch (e) {
        console.info('Upload files failed: ', e);
        thunkAPI.dispatch(openErrorModal({
          errorTitle: 'Image upload error',
          errorText: 'The image could not be uploaded, please try again',
        }))
      }
    }
    const newsletter = NewsLetterTranslator.toUpsertNewsLetterRequest(data, imageUrls);
    await thunkAPI.extra.portOwnerApi().newsLetterUpsert({ newsletter });
  })

const initialState: INewslettersSlice = {
  newsLetters: [],
  newslettersListStatus: NetworkStatus.None,
  createNewsletterNetworkStatus: NetworkStatus.None,
};

export const newslettersSlice = createSlice({
  name: 'Newsletters',
  initialState,
  reducers: {
    resetNetworkStatus: (
      state,
      { payload }: PayloadAction<'newslettersListStatus' | 'createNewsletterNetworkStatus'>,
    ) => {
      state[payload] = NetworkStatus.None;
    },
  },
  extraReducers: builder => {
    builder.addCase(getNewsLetterList.pending, state => {
      state.newslettersListStatus = NetworkStatus.Loading;
    });
    builder.addCase(getNewsLetterList.fulfilled,(state, action) => {
      state.newsLetters = action.payload.newsletters;
      state.newslettersListStatus = NetworkStatus.Done;
    });
    builder.addCase(getNewsLetterList.rejected, state => {
      state.newslettersListStatus = NetworkStatus.Failed;
    });

    builder.addCase(createNewsLetter.pending, state => {
      state.createNewsletterNetworkStatus = NetworkStatus.Loading;
    });
    builder.addCase(createNewsLetter.fulfilled, state => {
      state.createNewsletterNetworkStatus = NetworkStatus.Done;
      state.newslettersListStatus = NetworkStatus.None;
    });
    builder.addCase(createNewsLetter.rejected, state => {
      state.createNewsletterNetworkStatus = NetworkStatus.Failed;
    });


  },
});

export const { resetNetworkStatus } = newslettersSlice.actions;
export const newslettersSelector = (state: RootState) => state[newslettersSlice.name];
