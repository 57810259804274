import { NetworkStatus } from '../../utils/network/network.constant';
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { createAppAsyncThunk } from '../../hooks/redux';
import {
  StatisticAggFunc,
  StatisticPeriod,
} from '@teleport/schemas-protobuf/port/v1/port_statistic_pb';
import { Timestamp } from '../../types/timestamp';

interface IStatisticUnitAggregator {
  aggFunc: StatisticAggFunc;
  value: bigint;
  deltaInPercent: number;
}

interface IStatisticUnit {
  period: StatisticPeriod;
  calculated: boolean;
  calculatedAt?: Timestamp;
  product?: IStatisticUnitAggregator;
  client?: IStatisticUnitAggregator;
  order?: IStatisticUnitAggregator;
  orderPrice?: IStatisticUnitAggregator;
  revenue?: IStatisticUnitAggregator;
}

interface IStatisticSlice {
  statistics:  IStatisticUnit[],
  currentStatistics: IStatisticUnit;
  networkStatus: NetworkStatus,
}

const initialState: IStatisticSlice = {
  statistics: [],
  currentStatistics: undefined,
  networkStatus: NetworkStatus.None,
};

export const getStatistic = createAppAsyncThunk(
  'Statistic/getStatistic',
  async (_, thunkAPI) => {
    return await thunkAPI.extra.portOwnerApi().statisticGet({})
  },
);

export const statisticsSlice = createSlice({
  name: 'Statistic',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getStatistic.pending, state => {
      state.networkStatus = NetworkStatus.Loading;
    });builder.addCase(getStatistic.fulfilled,(state, action) => {
      state.statistics = action.payload.units;
      state.currentStatistics = action.payload.units.find(el => el.period === StatisticPeriod.NOW);
      state.networkStatus = NetworkStatus.Done;
    });
    builder.addCase(getStatistic.rejected, state => {
      state.networkStatus = NetworkStatus.Failed;
    });
  },
});

export const statisticSelector = (state: RootState) => state[statisticsSlice.name];
