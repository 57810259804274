
import { createAppAsyncThunk } from '../../../hooks/redux';
import { ISelectionWithProducts } from '../../slices/selectionsSlice';

export const createSelection = createAppAsyncThunk(
  'selections/createSelection',
  async (selection: ISelectionWithProducts, thunkAPI): Promise<ISelectionWithProducts> => {
    const { selectionUuid } = await thunkAPI.extra.portOwnerApi().selectionUpsert({ selection });

    return {
      ...selection,
      uuid: selectionUuid,
    };
  },
);
