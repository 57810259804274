import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { RootState } from '../store';
import { UserRole } from '@teleport/schemas-protobuf';
import {createAppAsyncThunk} from '../../hooks/redux';
import {NetworkStatus} from '../../utils/network/network.constant';
import {adminTranslator} from '../translators/adminTranslator';

interface IUserIdentity {
    tgId: number;
    uuid: string;
}

export interface IUser {
    identity?: IUserIdentity;
    username: string;
    fullName: string;
    photo: string;
    role: UserRole;
}

interface IAdministratorsSlice {
  administrators: IUser[],
  foundAdmins: IUser[],
  adminListNetworkStatus: NetworkStatus,
  searchAdminNetworkStatus: NetworkStatus,
  addAdminNetworkStatus: NetworkStatus,
  deleteAdminNetworkStatus: NetworkStatus,
  transferOwnershipNetworkStatus: NetworkStatus,
}

const initialState: IAdministratorsSlice = {
  administrators: [],
  adminListNetworkStatus: NetworkStatus.None,
  addAdminNetworkStatus: NetworkStatus.None,
  deleteAdminNetworkStatus: NetworkStatus.None,
  searchAdminNetworkStatus: NetworkStatus.None,
  transferOwnershipNetworkStatus: NetworkStatus.None,
  foundAdmins: []
};

export const getAdminList = createAppAsyncThunk(
  'administrators/getAdminList',
  async (_, thunkAPI) => {
    const result = await thunkAPI.extra.hubApi.storeAdminList({});
    return adminTranslator.fromAdminList(result.admins);
  }
)

export const storeAdminSearch = createAppAsyncThunk(
  'administrators/storeAdminSearch',
  async (query: string, thunkAPI) => {
    const result = await thunkAPI.extra.hubApi.storeAdminSearch({query});
    return adminTranslator.fromAdminList(result.clients);
  }
)

export const addAdmin = createAppAsyncThunk(
  'administrators/addAdmin',
  async (clientUuid: string, thunkAPI) => {
    await thunkAPI.extra.hubApi.storePermissionSet({
      clientUuid,
      clientRole: UserRole.ADMIN,
    });
    return clientUuid
  }
)

export const transferOwnership = createAppAsyncThunk(
  'administrators/transferOwnership',
  async (clientUuid: string, thunkAPI) => {
    await thunkAPI.extra.hubApi.storePermissionSet({
      clientUuid,
      clientRole: UserRole.OWNER,
    });
    return clientUuid
  }
)

export const deleteAdmin = createAppAsyncThunk(
  'administrators/deleteAdmin',
  async (clientUuid: string, thunkAPI) => {
    await thunkAPI.extra.hubApi.storePermissionSet({
      clientUuid,
      clientRole: UserRole.UNSPECIFIED,
    });
    return clientUuid
  }
)

export const administratorsSlice = createSlice({
  name: 'Administrators',
  initialState,
  reducers: {
    resetNetworkStatus: (
      state,
      { payload }: PayloadAction<'adminListNetworkStatus' | 'addAdminNetworkStatus' | 'searchAdminNetworkStatus' | 'deleteAdminNetworkStatus'>,
    ) => {
      state[payload] = NetworkStatus.None;
    },
  },
  extraReducers: builder => {
    builder.addCase(getAdminList.pending, state => {
      state.adminListNetworkStatus = NetworkStatus.Loading;
    });
    builder.addCase(getAdminList.fulfilled, (state, action)=> {
      state.adminListNetworkStatus = NetworkStatus.Done;
      state.administrators = action.payload;
    });
    builder.addCase(getAdminList.rejected, state => {
      state.adminListNetworkStatus = NetworkStatus.Failed;
    });


    builder.addCase(storeAdminSearch.pending, state => {
      state.searchAdminNetworkStatus = NetworkStatus.Loading;
    });
    builder.addCase(storeAdminSearch.fulfilled, (state, action) => {
      state.searchAdminNetworkStatus = NetworkStatus.Done;
      state.foundAdmins = action.payload
    });
    builder.addCase(storeAdminSearch.rejected, state => {
      state.searchAdminNetworkStatus = NetworkStatus.Failed
    });

    builder.addCase(addAdmin.pending, state => {
      state.addAdminNetworkStatus = NetworkStatus.Loading;
    });
    builder.addCase(addAdmin.fulfilled, (state, action) => {
      state.addAdminNetworkStatus = NetworkStatus.Done;
      const newAdmin = state.foundAdmins.find(el => el.identity.uuid === action.payload);
      newAdmin.role = UserRole.ADMIN;
      state.administrators = [...state.administrators, newAdmin]
    });
    builder.addCase(addAdmin.rejected, state => {
      state.addAdminNetworkStatus = NetworkStatus.Failed
    });

    builder.addCase(transferOwnership.pending, state => {
      state.transferOwnershipNetworkStatus = NetworkStatus.Loading;
    });
    builder.addCase(transferOwnership.fulfilled, (state, action) => {
      state.transferOwnershipNetworkStatus = NetworkStatus.Done;
      const oldOwner = state.administrators.find(el => el.role === UserRole.OWNER);
      if (oldOwner) {
        Object.assign(oldOwner, {role: UserRole.ADMIN});
      }
      const newOwner = state.administrators.find(el => el.identity.uuid === action.payload);
      if (newOwner) {
        Object.assign(newOwner, {role: UserRole.OWNER});
      }
    });
    builder.addCase(transferOwnership.rejected, state => {
      state.transferOwnershipNetworkStatus = NetworkStatus.Failed
    });

    builder.addCase(deleteAdmin.pending, state => {
      state.deleteAdminNetworkStatus = NetworkStatus.Loading;
    });
    builder.addCase(deleteAdmin.fulfilled, (state, action) => {
      state.deleteAdminNetworkStatus = NetworkStatus.Done;
      state.administrators = state.administrators.filter(el => el.identity.uuid !== action.payload);
    });
    builder.addCase(deleteAdmin.rejected, state => {
      state.deleteAdminNetworkStatus = NetworkStatus.Failed
    });
  }
});

export const { resetNetworkStatus } = administratorsSlice.actions;

export const administratorsState = (state: RootState) => state[administratorsSlice.name];
