import {
  AppFocusStep,
  BotStep,
  ColorStep,
  ColorStep_Color,
  Currency,
  DeliveryStep,
  Language,
  LocalizationStep,
  MaintenanceStep,
  NamingStep,
  OrderingStep,
  PromoStep,
  SupportStep,
  TermsAndConditionsStep,
  WizardGetStateResponse,
  WizardSaveStateRequest,
  WizardState,
} from '@teleport/schemas-protobuf';
import { WIZARD_STEPS, WizardSteps } from 'src/types/wizard';
import { Basket, IWizardData } from '../slices/wizardSlice';

export const WizardTranslator = {
  toWizardSaveStateRequest(wizardData: IWizardData, wizardCommit: boolean) {
    const stepsArr: { stepId: string; step: any }[] = [];

    // step 1
    const step1 = new NamingStep({
      logo: wizardData.shopLogoUrl,
      name: wizardData.shopName,
      description: wizardData.shopDescription,
    });

    // step 2
    const color = new ColorStep_Color({
      accentColor: wizardData.accentColor,
      backgroundColor: '#F0F0F0',
      fontColor: '#1C1916',
    });

    const step2 = new ColorStep({ color: color });

    // step 4
    const step4 = new DeliveryStep({ delivery: wizardData.deliveryType });

    // step 5
    const step5 = new OrderingStep({
      cartEnabled: wizardData.hasBasket === Basket.HasBasket,
      minimalPriceEnabled: wizardData.minimalPriceEnabled,
      minimalPrice: BigInt(wizardData.minimalPrice),
      purchaseConfirmationMessage: wizardData.purchaseConfirmationMessage,
      checkoutNotesPlaceholder: wizardData.checkoutNotesPlaceholder,
    });

    const step6 = new LocalizationStep({
      language: wizardData.language,
      currency: wizardData.currency,
    });

    const step7 = new SupportStep({
      supportLink: wizardData.supportLink,
    });

    const step8 = new PromoStep({
      promocode: wizardData.promocode,
    });

    // step 9
    if (wizardData.botToken || wizardData.welcomeImage || wizardData.welcomeMessage) {
      const step9 = new BotStep({
        personalBotToken: wizardData.botToken,
        welcomeImage: wizardData.welcomeImage,
        welcomeMessage: wizardData.welcomeMessage,
      });
      // step9 = removeEmptyProperties(step9);
      stepsArr.push({ stepId: 'step9', step: step9 });
    }

    // step 10
    if (wizardData.maintenanceEnabled || wizardData.maintenanceMessage) {
      const step10 = new MaintenanceStep({
        maintenanceEnabled: wizardData.maintenanceEnabled,
        maintenanceMessage: wizardData.maintenanceMessage,
      });
      // step10 = removeEmptyProperties(step10);
      stepsArr.push({ stepId: 'step10', step: step10 });
    }

    // step11
    if (wizardData.appFocus) {
      const step11 = new AppFocusStep({
        appFocus: wizardData.appFocus
      });
      stepsArr.push({ stepId: 'step11', step: step11 });
    }

    const step16 = new TermsAndConditionsStep({
      accepted: true,
    });

    const stepsObject = {
      step1,
      step2,
      // step3,
      step4,
      step5,
      step6,
      step7,
      step8,
      // step9,
      // step10,
      step16,
    };

    stepsArr.forEach(el => {
      Object.defineProperty(stepsObject, el.stepId, { value: el.step });
    });

    // if (step9) {
    //   Object.defineProperty(stepsObject, 'step9', { value: step9 });
    // }

    const state = new WizardState(stepsObject);

    const request = new WizardSaveStateRequest({ commit: wizardCommit, state });

    return request;
  },

  fromWizardStateResponse(response: WizardGetStateResponse, debugWizard?: boolean) {
    const wizardState = response.state;
    const completeObject = wizardIsComplete(wizardState);
    const translatedWizardState: IWizardData = {
      promocode: '',
      promocodeValidityDays: null,
      shopName: '',
      shopDescription: '',
      shopLogoUrl: '',
      accentColor: '',
      storeFocus: { case: undefined, value: undefined },
      appFocus: { case: undefined, value: undefined },
      deliveryType: 0,
      language: Language.RU,
      currency: Currency.UNSPECIFIED,
      hasBasket: null,
      supportLink: '',
      agree: true,
      botToken: '',
      maintenanceEnabled: false,
      maintenanceMessage: '',
      welcomeImage: '',
      welcomeMessage: '',
      minimalPriceEnabled: false,
      minimalPrice: 0,
      purchaseConfirmationMessage: '',
      checkoutNotesPlaceholder: ''
    };

    translatedWizardState.shopName = wizardState.step1?.name;
    translatedWizardState.shopDescription = wizardState.step1.description;
    translatedWizardState.shopLogoUrl = wizardState.step1?.logo;
    translatedWizardState.accentColor = wizardState.step2?.color.accentColor;
    // TODO Delete storeFocus after deleting the toggle productEnabled2_1
    translatedWizardState.storeFocus = wizardState.step3?.storeFocus;
    translatedWizardState.appFocus = wizardState.step11?.appFocus;
    translatedWizardState.deliveryType = wizardState.step4?.delivery;
    translatedWizardState.hasBasket = wizardState.step5?.cartEnabled
      ? Basket.HasBasket
      : Basket.NoBasket;
    translatedWizardState.currency = wizardState.step6?.currency;
    translatedWizardState.language = wizardState.step6?.language;
    translatedWizardState.supportLink = wizardState.step7?.supportLink;
    translatedWizardState.promocode = wizardState.step8?.promocode;
    translatedWizardState.promocodeValidityDays = wizardState.step8?.promocodeValidityDays;
    translatedWizardState.agree = wizardState.step16?.accepted;
    translatedWizardState.botToken = wizardState.step9?.personalBotToken;
    translatedWizardState.welcomeImage = wizardState.step9?.welcomeImage;
    translatedWizardState.welcomeMessage = wizardState.step9?.welcomeMessage;
    translatedWizardState.maintenanceEnabled = wizardState.step10?.maintenanceEnabled;
    translatedWizardState.maintenanceMessage = wizardState.step10?.maintenanceMessage;
    translatedWizardState.minimalPriceEnabled = wizardState.step5?.minimalPriceEnabled;
    translatedWizardState.minimalPrice = Number(wizardState.step5?.minimalPrice);
    translatedWizardState.purchaseConfirmationMessage = wizardState.step5?.purchaseConfirmationMessage;
    translatedWizardState.checkoutNotesPlaceholder = wizardState.step5?.checkoutNotesPlaceholder;

    if (debugWizard) {
      completeObject.complete = false;
    }

    if (debugWizard) {
      completeObject.complete = false;
    }

    return {
      wizardData: translatedWizardState,
      completeObject,
      infraProvisioningStatus: response.infra.provisioningStatus,
      portApiUrl: response.infra.portApiUrl,
      portCodename: response.infra.portCodename,
    };
  },
};

function wizardIsComplete(wizardState: WizardState) {
  const result: { interruptedStep: WizardSteps | null; complete: boolean } = {
    interruptedStep: null,
    complete: true,
  };
  const arrSteps = Object.entries(wizardState);
  const interruptedStep = arrSteps.find(step => step[1].complete === false);

  if (interruptedStep) {
    result.interruptedStep = translateStepToWizardSteps(interruptedStep[0]);
    result.complete = false;
    return result;
  }

  return result;
}

function translateStepToWizardSteps(step: string): WizardSteps {
  switch (step) {
    case 'step1':
      return 'start';
    case 'step2':
      return WIZARD_STEPS.COLOR;
    case 'step3':
      return WIZARD_STEPS.PRODUCT_TYPE;
    case 'step4':
      return WIZARD_STEPS.ORDER_METHOD;
    case 'step5':
      return WIZARD_STEPS.HAS_BASKET;
    case 'step6':
      return 'end';
    case 'step7':
      return 'end';
    case 'step8':
      return 'start';
    case 'step16':
      return 'end';
    default:
      return 'start';
  }
}

// function removeEmptyProperties<T>(obj: T) {
//   let objectInArr = Object.entries(obj);
//   objectInArr = objectInArr.filter(el => {
//     if (el[1] !== null && el[1] !== undefined && el[1] !== '') {
//       return true;
//     }
//     return false;
//   });
//   return Object.fromEntries(objectInArr) as T;
// }
