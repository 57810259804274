import { FC, memo, useEffect } from 'react';
import Footer from 'src/components/Footer/Footer';
import { Layout } from 'src/components/Layout/Layout';
import { Button } from 'src/components/UI/Button/Button';
import Text from 'src/components/UI/Text/Text';
import { useStyle } from 'src/utils/theme/useStyle';
import { integrationsPageRules } from './IntegrationsPage.style';
import { useNavigate } from 'react-router-dom';
import useBackButton from 'src/hooks/useBackButton';
import { useTelegram } from 'src/utils/telegramProvider';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';
import { ListButton } from 'src/components/UI/ListButton/ListButton';
import Container from 'src/components/Container/Container';
import { useTheme } from 'src/utils/theme/useTheme';
import { RoutePath } from 'src/routing/routeConfig';
import { IntegrationType } from 'src/redux/slices/integrationSlice';

export const IntegrationsPage: FC = memo(function IntegrationsPage() {
  const { css } = useStyle(integrationsPageRules);
  const navigate = useNavigate();
  const BackButton = useBackButton();
  const Telegram = useTelegram();

  const { theme } = useTheme();

  const { t } = useTranslation();

  useEffect(() => {
    BackButton.onClickCustom(goBack);
    function goBack() {
      navigate('/');
    }
    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  const connectToSupport = () => {
    Telegram.webApp.openTelegramLink('https://t.me/TeleportHelpBot');
  };

  const selectCurrentIntegration = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLElement;
    const targetParentId = target.closest('.list-button')?.id;
    if (!target.id && !targetParentId) return;
    const targetId = target.id ? target.id : targetParentId;
    switch (targetId) {
      case IntegrationType.telegram:
        navigate(RoutePath.IntegrationTelegram)
        return;
      default:
        return;
    }
  };

  return (
    <Layout
      header={
        <header className={`${css.header} header`}>
          <Text
            text={t('integrationsPage.integrations')}
            mod='title'
            fontWeight={800}
            fontSize={20}
            lineHeight={22}
          />
        </header>
      }
      footer={
        <Footer>
          <Button hasGradient={false} text={t('integrationsPage.contactSupport')} onClick={connectToSupport} propsStyles={{ background: theme.colorBlack, width: '100%' }} />
        </Footer>
      }
    >
      <Container>
        <div className={css.grid}>
          <ListButton
            id={IntegrationType.telegram}
            title={t('integrationsPage.telegram')}
            onClick={selectCurrentIntegration}
            padding='16px'
          />
        </div>
      </Container>
    </Layout>
  );
});
