import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface ISettingButtonKeys {
  settingButton: {};
  number: {};
  text: {};
  iconChevron: {};
  wrap: {};
}

interface ISettingButtonProps {
  soon?: boolean;
}

export type ISettingButton = IRuleFn<ISettingButtonKeys, ISettingButtonProps>;

const SettingButtonRuleFn: ISettingButton = props => {
  const { colorDarkGrey, colorRgbBlack, colorBlack } = props.theme;
  const { soon } = props;

  return {
    settingButton: {
      pointerEvents: soon ? 'none' : 'auto',
      border: 'none',
      backgroundColor: 'transparent',
      ...padding(8, 0),
      borderBottom: `1px solid rgba(${colorRgbBlack}, 0.1)`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',

      ':last-child': {
        borderBottom: 'none',
      },
    },
    text: {
      color: colorBlack,
    },
    number: {
      color: colorDarkGrey,
    },
    iconChevron: {
      width: 16,
      height: 16,
      fill: colorDarkGrey,
    },
    wrap: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 12,
    },
  };
};

export const SettingButtonRules = createCachedStyle(SettingButtonRuleFn);
