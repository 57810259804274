import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { createAppAsyncThunk } from 'src/hooks/redux';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { SubscriptionTriggerType } from '@teleport/schemas-protobuf-billing-v1';
import type { Currency, SubscriptionPeriod } from '@teleport/schemas-protobuf-billing-v1/billing_dictionary_pb';
import { SubscriptionTranslator } from '../translators/subscriptionTranslator';

interface ISubscriptionTariff {
  uuid: string;
  subscriptionPeriod: SubscriptionPeriod;
  currency: Currency;
  price: number;
}

interface ISubscriptionSlice {
  finishedAt?: number
  deletedAt?: number
  startedAt?: number
  subscriptionActive?: boolean
  networkStatus: NetworkStatus;
  subscriptionTariff?: ISubscriptionTariff
  subscriptionTriggerType?: SubscriptionTriggerType
}

const initialState: ISubscriptionSlice = {
  finishedAt: 0,
  deletedAt: 0,
  subscriptionActive: undefined, 
  networkStatus: NetworkStatus.None,
  subscriptionTariff: undefined, 
  subscriptionTriggerType: undefined, 
};

export const subsctiptionStatusGet = createAppAsyncThunk(
  'subsctiption/getStatus',
  async (_, thunkAPI) => {
    const result = await thunkAPI.extra.billingApi.subsctiptionStatusGet({});
    return SubscriptionTranslator.fromSubscriptionStatusGetResponse(result)
  },
);

export const subscriptionSlice = createSlice({
  name: 'Subscription',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(subsctiptionStatusGet.pending, state => {
      state.networkStatus = NetworkStatus.Loading;
    });
    builder.addCase(subsctiptionStatusGet.fulfilled, (state, action) => {
      state.subscriptionActive = action.payload.active;
      state.finishedAt = Number(action.payload.finishedAt.seconds)
      state.subscriptionTariff = action.payload.subscriptionTariff
      state.networkStatus = NetworkStatus.Done;
    });
    builder.addCase(subsctiptionStatusGet.rejected, state => {
      state.networkStatus = NetworkStatus.Failed;
    });
  },
});

export const subscriptionState = (state: RootState) => state[subscriptionSlice.name];
