import { ChangeEvent, FC, memo, useCallback, useMemo } from 'react';
import { Step } from 'src/components/Step/Step';
import { useStyle } from 'src/utils/theme/useStyle';
import { OptionCard } from 'src/components/OptionCard/OptionCard';
import { wizardHasBasketRules } from './wizardHasBasket.style';
import { useTranslation } from '../../../utils/i18n/hooks/useTranslation';
import { Basket, setHasBasket, wizardStateSelector } from '../../../redux/slices/wizardSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';

export const WizardHasBasket: FC = memo(function WizardHasBasket() {
  const { wizard: { hasBasket } } = useAppSelector(wizardStateSelector);
  const { css } = useStyle(wizardHasBasketRules);
  const { t } = useTranslation();

  const isSelected = useCallback(value => {
    return hasBasket === value;
  }, [hasBasket]);

  const checkedINeedCard = useMemo(() => {
    return isSelected(Basket.HasBasket)
  }, [isSelected])

  const dispatch = useAppDispatch();



  const onChangeBasket = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as Basket;

    dispatch(setHasBasket({
      hasBasket: value,
    }));
  }, [dispatch]);

  return (
    <Step
      stepNumber={4}
      title={t('wizardStepBasket.doYouNeedCart')}
      subtitle={t('wizardStepBasket.cartEnablesOrderOfMoreThanOneProductAndPromoCodesUsage')}
    >
      <div className={css.wrapper}>
        <OptionCard
          name="order-method"
          title={t('wizardStepBasket.yesINeedCart')}
          text={t('wizardStepBasket.customerWillBeAbleToAddMultipleProductsAndCreateAnOrderFromCart')}
          type="radio"
          value={Basket.HasBasket}
          checked={checkedINeedCard}
          onChange={onChangeBasket}
        />
        <OptionCard
          name="order-method"
          title={t('wizardStepBasket.noWithoutCart')}
          text={t('wizardStepBasket.customerWillBeAbleToOrderOneProductFromProductPageAtATime')}
          type="radio"
          value={Basket.NoBasket}
          checked={isSelected(Basket.NoBasket)}
          onChange={onChangeBasket}
        />
      </div>
    </Step>
  );
});
