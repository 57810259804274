import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {UserRole} from '@teleport/schemas-protobuf';
import {getStoreList} from './multipleStoreSlice';
import {transferOwnership} from './administratorsSlice';

interface IUserSlice {
  userName: string;
  userRole: UserRole;
}

const initialState: IUserSlice = {
  userName: '',
  userRole: UserRole.UNSPECIFIED
};

// todo Тут будет экшн для получения токена

export const userSlice = createSlice({
  name: 'User',
  initialState,
  reducers: {
    setUserName: (state, action: PayloadAction<string>) => {
      state.userName = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getStoreList.fulfilled, (state, action) => {
      const activeStore = action.payload.stores.find(el => el.active);
      if (activeStore) {
        state.userRole = activeStore.userRole
      }
    }),

    builder.addCase(transferOwnership.fulfilled, state => {
      state.userRole = UserRole.ADMIN
    });
  }
});

export const { setUserName } = userSlice.actions;

export const userStateSelector = (state: RootState) => state[userSlice.name];
