import { LoyaltyError } from '@teleport/schemas-protobuf';

export function findEnumValueByPartialMatch(inputString: string, enumType: 'LoyaltyError'): LoyaltyError | undefined {
  if (enumType === 'LoyaltyError') {
    const enumKeys = Object.keys(LoyaltyError).filter(key => isNaN(Number(key)));
    const matchingKey = enumKeys.find(key => inputString.includes(key));
    if (matchingKey) {
      return LoyaltyError[matchingKey as keyof typeof LoyaltyError];
    }
    return undefined;
  }
  return undefined;
}