import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Currency, DeliveryType, InfraProvisioningStatus, Language, } from '@teleport/schemas-protobuf';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { RootState } from '../store';
import { AppFocus, StoreFocus, WizardSteps } from 'src/types/wizard';
import { wizardGetState } from '../api/wizard/wizardGetState';
import { wizardSaveState } from '../api/wizard/wizardSaveState';

export enum Basket {
  HasBasket = 'HasBasket',
  NoBasket = 'NoBasket',
}

export interface IWizardData {
  promocode: string;
  promocodeFromUrl?: string;
  promocodeValidityDays: number;
  shopName: string;
  shopDescription: string;
  shopLogoUrl: string;
  accentColor: string;
  // TODO Delete storeFocus after deleting the toggle productEnabled2_1
  storeFocus: StoreFocus | null;
  appFocus: AppFocus | null;
  deliveryType: DeliveryType;
  language?: Language;
  currency?: Currency;
  hasBasket: Basket | null;
  supportLink: string;
  agree: boolean;
  botToken: string;
  welcomeImage: string;
  welcomeMessage: string;
  maintenanceEnabled: boolean;
  maintenanceMessage: string;
  minimalPriceEnabled: boolean;
  minimalPrice: number;
  purchaseConfirmationMessage: string;
  checkoutNotesPlaceholder: string;
}

interface IWizardState {
  wizard: IWizardData;
  complete: boolean;
  interruptedStep: WizardSteps;
  networkStatus: NetworkStatus;
  saveNetworkStatus: NetworkStatus;

  saveStateError?: string;

  // todo move to config
  infraProvisioningStatus: InfraProvisioningStatus;

  // todo simplify
  startCreatingStore: boolean;
  noNextStep: boolean;
}

// products services courses events

const initialState: IWizardState = {
  wizard: {
    shopName: '',
    shopDescription: '',
    shopLogoUrl: '',
    accentColor: '',
    // TODO Delete storeFocus after deleting the toggle productEnabled2_1
    storeFocus: null,
    appFocus:  null,
    deliveryType: 0,
    language: Language.UNSPECIFIED,
    currency: Currency.UNSPECIFIED,
    hasBasket: null,
    supportLink: '',
    promocode: '',
    promocodeFromUrl: '',
    promocodeValidityDays: null,
    agree: true,
    botToken: '',
    maintenanceEnabled: false,
    maintenanceMessage: '',
    welcomeImage: '',
    welcomeMessage: '',
    minimalPriceEnabled: false,
    minimalPrice: 0,
    purchaseConfirmationMessage: '',
    checkoutNotesPlaceholder: '',
  },
  interruptedStep: null,
  complete: false,
  networkStatus: NetworkStatus.None,
  saveNetworkStatus: NetworkStatus.None,
  infraProvisioningStatus: 0,
  saveStateError: '',
  startCreatingStore: false,
  noNextStep: false,
};

export const WizardSlice = createSlice({
  name: 'Wizard',
  initialState,
  reducers: {
    setWizardComplete: (state, action: PayloadAction<boolean>) => {
      state.complete = action.payload;
    },
    setPromocode: (state, { payload }: PayloadAction<string>) => {
      state.wizard = { ...state.wizard, promocode: payload };
    },
    setPromocodeFromUrl: (state, { payload }: PayloadAction<string>) => {
      state.wizard = { ...state.wizard, promocodeFromUrl: payload };
    },
    setShopName: (state, { payload }: PayloadAction<string>) => {
      state.wizard = { ...state.wizard, shopName: payload };
    },
    setShopDescription: (state, { payload }: PayloadAction<string>) => {
      state.wizard = { ...state.wizard, shopDescription: payload };
    },
    setShopLogoUrl: (state, { payload }: PayloadAction<string>) => {
      state.wizard.shopLogoUrl = payload;
    },
    setAccentColor: (state, { payload }: PayloadAction<string>) => {
      state.wizard = { ...state.wizard, accentColor: payload };
    },
    setAppFocus: (state, { payload }: PayloadAction<AppFocus>) => {
      state.wizard = { ...state.wizard, appFocus: payload };
    },
    setDeliveryType: (state, { payload }: PayloadAction<{
      deliveryType: DeliveryType,
    }>) => {
      const { deliveryType } = payload;
      state.wizard = { ...state.wizard, deliveryType };
    },
    setHasBasket: (state, { payload }: PayloadAction<{
      hasBasket: Basket,
    }>) => {
      const { hasBasket } = payload;
      state.wizard = { ...state.wizard, hasBasket, deliveryType: hasBasket === Basket.HasBasket ? DeliveryType.REQUEST : DeliveryType.UNSPECIFIED };
    },
    setCurrency: (state, { payload }: PayloadAction<Currency>) => {
      state.wizard = { ...state.wizard, currency: payload };
    },
    setLanguage: (state, { payload }: PayloadAction<Language>) => {
      state.wizard = { ...state.wizard, language: payload };
    },
    setSupportLink: (state, { payload }: PayloadAction<string>) => {
      state.wizard = { ...state.wizard, supportLink: payload };
    },
    setMinimalPriceEnabled: (state, { payload }: PayloadAction<boolean>) => {
      state.wizard = { ...state.wizard, minimalPriceEnabled: payload };
    },
    setMinimalPrice: (state, { payload }: PayloadAction<number>) => {
      state.wizard = { ...state.wizard, minimalPrice: payload };
    },
    setPurchaseConfirmationMessage: (state, { payload }: PayloadAction<string>) => {
      state.wizard = { ...state.wizard, purchaseConfirmationMessage: payload };
    },
    setCheckoutNotesPlaceholder: (state, { payload }: PayloadAction<string>) => {
      state.wizard = { ...state.wizard, checkoutNotesPlaceholder: payload };
    },
    setStartCreatingStore: (state, { payload }: PayloadAction<boolean>) => {
      state.startCreatingStore = payload;
    },
    resetNetworkStatus: (
      state,
      { payload }: PayloadAction<'saveNetworkStatus' | 'networkStatus'>,
    ) => {
      state[payload] = NetworkStatus.None;
    },
  },
  extraReducers: builder => {
    builder.addCase(wizardGetState.pending, state => {
      state.networkStatus = NetworkStatus.Loading;
    });
    builder.addCase(wizardGetState.fulfilled, (state, { payload }) => {
      if (!payload) return;
      state.wizard = {
        ...payload.wizardData,
        promocodeFromUrl: payload.wizardData.promocode? undefined : state.wizard.promocodeFromUrl,
        promocode: state.wizard.promocodeFromUrl && !payload.wizardData.promocode? state.wizard.promocodeFromUrl : payload.wizardData.promocode,
      };
      state.complete = payload.completeObject.complete;
      state.interruptedStep = payload.completeObject.interruptedStep;
      state.infraProvisioningStatus = payload.infraProvisioningStatus;
      state.networkStatus = NetworkStatus.Done;
    });
    builder.addCase(wizardGetState.rejected, state => {
      state.networkStatus = NetworkStatus.Failed;
    });

    builder.addCase(wizardSaveState.pending, state => {
      state.saveStateError = undefined;
      state.noNextStep = false;
      state.saveNetworkStatus = NetworkStatus.Loading;
    });
    builder.addCase(wizardSaveState.fulfilled, (state, action) => {
      state.saveNetworkStatus = NetworkStatus.Done;
      state.wizard.shopLogoUrl = action.payload.wizardData.step1?.logo ?? '';
      state.wizard.shopName = action.payload.wizardData.step1?.name ?? '';
      state.wizard.shopDescription = action.payload.wizardData.step1?.description ?? '';
      state.wizard.welcomeMessage = action.payload.wizardData.step9?.welcomeMessage;
      state.wizard.currency = action.payload.wizardData.step6?.currency;
      state.wizard.language = action.payload.wizardData.step6?.language ?? Language.RU;
      state.complete = action.payload.committed;
      state.wizard.welcomeImage = action.payload.wizardData.step9?.welcomeImage;
      state.wizard.maintenanceMessage = action.payload.wizardData?.step10?.maintenanceMessage;
      state.wizard.maintenanceEnabled = action.payload.wizardData?.step10?.maintenanceEnabled;
      state.wizard.minimalPriceEnabled = action.payload.wizardData?.step5?.minimalPriceEnabled;
      state.wizard.minimalPrice = Number(action.payload.wizardData?.step5?.minimalPrice);
      state.wizard.purchaseConfirmationMessage = action.payload.wizardData?.step5?.purchaseConfirmationMessage;
      state.wizard.checkoutNotesPlaceholder = action.payload.wizardData?.step5?.checkoutNotesPlaceholder;
      state.wizard.promocode = action.payload.wizardData?.step8?.promocode;
      state.wizard.promocodeValidityDays = action.payload.wizardData?.step8?.promocodeValidityDays;
      state.noNextStep = action.payload.noNextStep;
    });
    builder.addCase(wizardSaveState.rejected, (state, action) => {
      state.saveStateError = action.error.message;
      state.saveNetworkStatus = NetworkStatus.Failed;
    });
  },
});

export const {
  setWizardComplete,
  setPromocode,
  setPromocodeFromUrl,
  setShopName,
  setShopDescription,
  setShopLogoUrl,
  setAccentColor,
  setAppFocus,
  setDeliveryType,
  setHasBasket,
  setLanguage,
  setSupportLink,
  resetNetworkStatus,
  setMinimalPriceEnabled,
  setMinimalPrice,
  setPurchaseConfirmationMessage,
  setCheckoutNotesPlaceholder,
  setCurrency,
  setStartCreatingStore,
} = WizardSlice.actions;

export const wizardStateSelector = (state: RootState) => state[WizardSlice.name];
