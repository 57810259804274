import {combineReducers, configureStore} from '@reduxjs/toolkit';
import { WizardSlice } from './slices/wizardSlice';
import { categorySlice } from './slices/categorySlice';
import { productSlice } from './slices/productSlice';
import { modifiersSlice } from './slices/modifiersSlice';
import { productsSlice } from './slices/productsSlice';
import { textBlockSlice } from './slices/textBlockSlice';
import { storiesSlice } from './slices/storiesSlice';
import { selectionSlice } from './slices/selectionsSlice';
import { configSlice } from './slices/configSlice';
import { sliderSlice } from './slices/sliderSlice';
import { uiBlocksSlice } from './slices/uiBlocksSlice';
import { catalogMetadataSlice } from './slices/catalogMetadataSlice';
import { hubApi } from './middleware/hubApi';
import { PortClientApi } from './middleware/portClientApi';
import { PortOwnerApi } from './middleware/portOwnerApi';
import { IConfig } from '../utils/config/config.interface';
import { filesUpload } from './middleware/filesUpload';
import { userSlice } from './slices/userSlice';
import { recomendationSlice } from './slices/recomendationSlice';
import { uploadedFilesSlice } from './slices/uploadedFilesSlice';
import { subscriptionSlice } from './slices/subscriptionSlice';
import { billingApi } from './middleware/billingApi';
import {multipleStoreSlice} from './slices/multipleStoreSlice';
import { integrationSlice } from './slices/integrationSlice';
import { newslettersSlice } from './slices/newslettersSlice';
import { statisticsSlice } from './slices/statisticsSlice';
import { promoCodesSlice } from './slices/promoCodesSlice';
import { qrSlice } from './slices/qrSlice';
import { modalErrorSlice } from './slices/modalErrorSlice';
import { administratorsSlice } from './slices/administratorsSlice';
import { PortAdminApi } from './middleware/PortAdminApi';
import {resetAction} from './actions/resetAction';

export const appReducer = combineReducers({
  [WizardSlice.name]: WizardSlice.reducer,
  [categorySlice.name]: categorySlice.reducer,
  [textBlockSlice.name]: textBlockSlice.reducer,
  [productSlice.name]: productSlice.reducer,
  [productsSlice.name]: productsSlice.reducer,
  [modifiersSlice.name]: modifiersSlice.reducer,
  [storiesSlice.name]: storiesSlice.reducer,
  [selectionSlice.name]: selectionSlice.reducer,
  [configSlice.name]: configSlice.reducer,
  [sliderSlice.name]: sliderSlice.reducer,
  [uiBlocksSlice.name]: uiBlocksSlice.reducer,
  [catalogMetadataSlice.name]: catalogMetadataSlice.reducer,
  [userSlice.name]: userSlice.reducer,
  [integrationSlice.name]: integrationSlice.reducer,
  [recomendationSlice.name]: recomendationSlice.reducer,
  [uploadedFilesSlice.name]: uploadedFilesSlice.reducer,
  [subscriptionSlice.name]: subscriptionSlice.reducer,
  [multipleStoreSlice.name]: multipleStoreSlice.reducer,
  [newslettersSlice.name]: newslettersSlice.reducer,
  [promoCodesSlice.name]: promoCodesSlice.reducer,
  [statisticsSlice.name]: statisticsSlice.reducer,
  [administratorsSlice.name]: administratorsSlice.reducer,
  [qrSlice.name]: qrSlice.reducer,
  [modalErrorSlice.name]: modalErrorSlice.reducer,
})

const rootReducer = (state: ReturnType<typeof appReducer>, action: any) => {
  if (action.type === resetAction.type) {
    return appReducer({
      ...state,
      Wizard: undefined,
      Category: undefined,
      TextBlock: undefined,
      Product: undefined,
      Products: undefined,
      Modifiers: undefined,
      Stories: undefined,
      Selections: undefined,
      Slider: undefined,
      UiBlocks: undefined,
      CatalogMetadata: undefined,
      Integration: undefined,
      Recomendation: undefined,
      uploadedFiles: undefined,
      Subscription: undefined,
      Newsletters: undefined,
      PromoCodes: undefined,
      Statistic: undefined,
      Administrators: undefined,
      Qr: undefined,
      User: undefined,
      MultipleStore: undefined,
    }, action);
  }
  return appReducer(state, action);
}

export const getStore = (config: IConfig) =>
  configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: {
            hubApi: hubApi(config.hubApiUrl),
            billingApi: billingApi(config.billingApiUrl),
            portClientApi: PortClientApi.create(),
            portOwnerApi: PortOwnerApi.create(),
            portAdminApi: PortAdminApi.create(),
            filesUpload: filesUpload(config.fsApiUrl),
          },
        },
        serializableCheck: false,
      })
  });

type IStore = ReturnType<typeof getStore>;
export type RootState = ReturnType<IStore['getState']>;
export type AppDispatch = IStore['dispatch'];
