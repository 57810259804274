import { FC, memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { BlockCardRules } from './BlockCard.style';
import Text from 'src/components/UI/Text/Text';
import { Icon } from 'src/components/UI/Icon/Icon';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {BlockType, IUiBlock} from 'src/redux/slices/uiBlocksSlice';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';


interface IProps {
  blockData: IUiBlock;
  disabled?: boolean
}

export const BlockCard: FC<IProps> = memo(function BlockCard(props) {
  const { blockData, disabled } = props;
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: blockData.uuid,
  });

  const { css } = useStyle(BlockCardRules, {
    transform: CSS.Transform.toString(transform),
    transition,
  });

  const { t } = useTranslation()

  const renderTitle = () => {
    if (!blockData) return 'Unknown block type'
    switch (blockData.type) {
      case BlockType.TextBlock:
        return t('blockCard.textBlock');
      case BlockType.StoriesBlock:
        return t('blockCard.stories');
      case BlockType.SliderBlock:
        return t('blockCard.slider');
      case BlockType.SelectionBlock:
        return t('blockCard.selection');
      default:
        return 'Unknown block type';
    }
  };

  const renderAdditionalText = () => {
    switch (blockData.type) {
      case BlockType.StoriesBlock:
        return '';
      case BlockType.SliderBlock:
        return '';
      default:
        return blockData.title;
    }
  }

  return (
    <div className={css.card} id={`${blockData.uuid}`} {...attributes} ref={setNodeRef}>
      <div className={css.info}>
        <Text extend={css.text} mod="title" text={renderTitle()} fontSize={14} lineHeight={16} />
        {renderAdditionalText().length > 0 && (
          <Text
            mod="text"
            text={renderAdditionalText()}
            fontSize={12}
            lineHeight={16}
            fontWeight={500}
            extend={css.additionalText}
          />
        )}
      </div>
      {disabled ? (
        <Icon name="lock" />
      ) : (
        <button {...listeners} className={css.buttonDrag}>
          <Icon name="bars" properties={{ className: css.iconBars }} />
        </button>
      )}
    </div>
  );
});
