import {memo, useCallback, useEffect, useMemo, useState} from 'react';
import Text from '../../components/UI/Text/Text';
import Footer from '../../components/Footer/Footer';
import { Button } from '../../components/UI/Button/Button';
import { Layout } from '../../components/Layout/Layout';
import { useStyle } from '../../utils/theme/useStyle';
import { ManageAdministratorsPageRules } from './ManageAdministratorsPage.style';
import { useTranslation } from '../../utils/i18n/hooks/useTranslation';
import Container from '../../components/Container/Container';
import { UserCard } from '../../components/UserCard/UserCard';
import { ModalManageAdministrator } from '../../components/ModalManageAdministrator/ModalManageAdministrator';
import { useModalState } from '../../hooks/useModalState';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {administratorsState, getAdminList} from '../../redux/slices/administratorsSlice';
import { UserRole } from '@teleport/schemas-protobuf';
import {NetworkStatus} from '../../utils/network/network.constant';
import {Spinner} from '../../components/UI/Spinner/Spinner';
import {openErrorModal} from '../../redux/slices/modalErrorSlice';
import useBackButton from '../../hooks/useBackButton';
import {useNavigate} from 'react-router-dom';
import {getStoreList, multipleStoreState} from '../../redux/slices/multipleStoreSlice';
import {togglesState} from '../../redux/slices/configSlice';

export const ManageAdministratorsPage = memo(function ManageAdministratorsPage() {
  const { css } = useStyle(ManageAdministratorsPageRules);
  const { t } = useTranslation();
  const [renderModal, activeModal, openModal, closeModal] = useModalState();
  const [selectedAdminUuid, setSelectedAdminUuid] = useState('');
  const { networkStatus: getStoreListNetworkStatus } = useAppSelector(multipleStoreState);
  const { multipleStoreEnabled } = useAppSelector(togglesState);
  const BackButton = useBackButton();
  const navigate = useNavigate();
  const { administrators, adminListNetworkStatus } = useAppSelector(administratorsState)
  const onClickAddAdmin = () => {
    setSelectedAdminUuid('');
    openModal();
  }
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (getStoreListNetworkStatus === NetworkStatus.None && multipleStoreEnabled) {
      dispatch(getStoreList());
    }
  }, [dispatch, getStoreListNetworkStatus, multipleStoreEnabled]);

  useEffect(() => {
    if (adminListNetworkStatus === NetworkStatus.None) {
      dispatch(getAdminList());
    }
    if (adminListNetworkStatus === NetworkStatus.Failed) {
      dispatch(openErrorModal({errorTitle: t('manageAdministratorsPage.failedToGetAdminList')}))
    }
  }, [t, dispatch, adminListNetworkStatus]);

  useEffect(() => {
    function goBack() {
      navigate(-1);
    }
    BackButton.onClickCustom(goBack);

    return () => {
      BackButton.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  const onClickCard = useCallback((uuid: string) => {
    setSelectedAdminUuid(uuid);
    openModal();
  }, [openModal])

  const content = useMemo(() => {
    if (adminListNetworkStatus === NetworkStatus.Loading) {
      return (
        <div className={css.spinnerWrapper}>
          <Spinner />
        </div>
      )
    }
    if (administrators) {
      return (
        <div className={css.wrapper}>
          {
            administrators.map(el =>
              <UserCard
                key={el.identity.uuid}
                data={el}
                onClick={el.role === UserRole.ADMIN ? onClickCard : undefined}
              />
            )
          }
        </div>
      )
    }
    return undefined;
  }, [onClickCard, css, administrators, adminListNetworkStatus])

  return (
    <>
      <Layout
        header={
          <header className={`${css.header} header`}>
            <Text
              text={t('manageAdministratorsPage.manageAdministrators')}
              mod="title"
              fontWeight={800}
              fontSize={20}
              lineHeight={22}
            />
          </header>
        }
        footer={
          <Footer>
            <Button
              text={t('manageAdministratorsPage.addAdministrator')}
              propsStyles={{ width: '100%' }}
              onClick={onClickAddAdmin}
            />
          </Footer>
        }
      >
        <Container extend={css.container}>
          {content}
        </Container>
      </Layout>
      {renderModal
        && <ModalManageAdministrator
          adminUuid={selectedAdminUuid}
          active={activeModal}
          onClose={closeModal}
        />
      }
    </>
  );
});
