import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IPillSoonKeys {
  root: {};
  text: {};
}

interface IPillSoonProps {
  extend?: Partial<IPillSoonKeys>
}

export type IPillSoon = IRuleFn<IPillSoonKeys, IPillSoonProps>;

const PillSoonRuleFn: IPillSoon = props => {
  const { colorWhite, colorGrayLight } = props.theme;
  const {} = props;

  return {
    root: {
      ...padding(5, 8),
      borderRadius: 8,
      backgroundColor: colorGrayLight,
      textAlign: 'center',
      display: 'inline-flex',
    },
    text: {
      color: colorWhite,
      letterSpacing: 0,
    },
  };
};

export const PillSoonRules = createCachedStyle(PillSoonRuleFn);
