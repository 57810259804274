
import { createAppAsyncThunk } from '../../../hooks/redux';
import { ISelectionWithProducts } from '../../slices/selectionsSlice';

export const editSelection = createAppAsyncThunk(
  'selections/editSelection',
  async (selection: ISelectionWithProducts, thunkAPI) => {
    await thunkAPI.extra.portOwnerApi().selectionUpsert({ selection });
    return selection;
  },
);
