import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IProductFormKeys {
  wrapperJustify: {};
  wrapper: {};
  footer: {};
  removeBtnWrapper: {};
}

interface IProductFormProps {
  footerHeight: number;
}

export type IProductForm = IRuleFn<IProductFormKeys, IProductFormProps>;

const ProductFormRuleFn: IProductForm = props => {
  const { colorWhite, sidePadding } = props.theme;
  const { footerHeight } = props;

  // console.log(footerHeight);

  return {
    wrapper: {
      // position: 'relative',
      paddingTop: 12,
      paddingBottom: footerHeight + 8,
      display: 'flex',
      flexDirection: 'column',
      rowGap: 24,
    },
    wrapperJustify: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },

    footer: {
      position: 'fixed',
      backgroundColor: colorWhite,
      left: 0,
      bottom: 0,
      width: '100%',
      ...padding(12,sidePadding, 30),
    },
    removeBtnWrapper: {},
  };
};

export const ProductFormRules = createCachedStyle(ProductFormRuleFn);
