import { NewsLetter } from '@teleport/schemas-protobuf/port/v1/port_newsletter_pb';
import { INewsLetterData } from '../slices/newslettersSlice';

export const NewsLetterTranslator = {
  toUpsertNewsLetterRequest(data: INewsLetterData, imageUrls: string[]) {
    return new NewsLetter({
      title: data.title,
      messageText: data.messageText,
      imageUrl: imageUrls[0],
      uuid: '',
      statistic: {
        total: data.total,
      }
    })
  },
}
