import { SelectionsTranslator } from 'src/redux/translators/selectionsTranslator';
import { createAppAsyncThunk } from '../../../hooks/redux';
import { ISelectionWithProducts } from '../../slices/selectionsSlice';

export const getSelections = createAppAsyncThunk(
  'selections/getSelections',
  async (_, thunkAPI) => {
    const result = await thunkAPI.extra.portOwnerApi().selectionList({});
    const selections = SelectionsTranslator.fromSelectionListResponse(result);
    const selectionsWithProducts: ISelectionWithProducts[] = [];

    for (const selection of selections) {
      const requestData = SelectionsTranslator.toSelectionProductList(selection.uuid);
      const selectionProductsListResponse = await thunkAPI.extra
        .portClientApi()
        .clientCatalogProductList(requestData);
      const productList = SelectionsTranslator.fromSelectionProductList(
        selectionProductsListResponse,
      ); 
      selectionsWithProducts.push({
        title: selection.title,
        uuid: selection.uuid,
        visibility: {
          showInRecommendations: selection.visibility.showInRecommendations,
          showOnMain: selection.visibility.showOnMain,
        },
        products: productList,
      });
    }

    return selectionsWithProducts;
  },
);
