import { PromiseClient } from '@connectrpc/connect/dist/cjs/promise-client';
import { PortAdminService } from '@teleport/schemas-protobuf';
import { createGrpcWebTransport } from '@connectrpc/connect-web';
import { createPromiseClient } from '@connectrpc/connect';
import { statusCodeInterceptor } from './interceptors/statusCodeInterceptor';
import { headersAuthInterceptor } from './interceptors/headersAuthInterceptor';
import { isValidUrl } from 'src/utils/url/isValidUrl';

export interface IPortAdminApi {
  (): PromiseClient<typeof PortAdminService>;
  init: (baseUrl: string) => void;
}

export class PortAdminApi {
  public instance: PromiseClient<typeof PortAdminService>;

  public init(baseUrl: string) {
    if (!isValidUrl(baseUrl)) {
      console.error('Invalid port admin api url', baseUrl);
      return null;
    }

    const transport = createGrpcWebTransport({
      baseUrl,
      useBinaryFormat: true,
      binaryOptions: {
        readUnknownFields: true,
      },
      interceptors: [headersAuthInterceptor, statusCodeInterceptor],
    });

    this.instance = createPromiseClient(PortAdminService, transport);
  }

  public call(): PromiseClient<typeof PortAdminService> {
    if (this.instance === undefined) {
      console.error('Port admin api is not initialized');
      return null;
    }

    return this.instance;
  }

  public static create(): IPortAdminApi {
    const instance = new PortAdminApi();

    return <IPortAdminApi>Object.assign(() => instance.call(), {
      init: (baseUrl: string) => instance.init(baseUrl),
    });
  }
}
