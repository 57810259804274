import { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import Modal, { ModalNames } from 'src/components/Modal/Modal';
import Text from 'src/components/UI/Text/Text';
import { modalProductCategoriesRules } from './modalProductCategories.style';
import { SubcategoryCard } from './components/SubcategoryCard/SubcategoryCard';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { Spinner } from '../UI/Spinner/Spinner';
import { Button } from '../UI/Button/Button';
import { RootCategory, SubCategory } from '@teleport/schemas-protobuf';
import { addSelectedCategory } from 'src/redux/slices/productsSlice';
import { getProductsByCategory } from 'src/redux/api/products/getProductsByCategory';
import { ProductsTranslator } from 'src/redux/translators/productsTranslator';
import { categoryState, getRootCategoryList } from 'src/redux/slices/categorySlice';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';
import { CategoryTranslator } from '../../redux/translators/categoryTranslator';

interface IProps {
  active: boolean;
  categoryState: ICategoryState[];
  onClose: () => void;
  setCategoryState: (state: ICategoryState[]) => void;
  clickSelectCategories: (categories: ICategoryState[]) => void;
}

export interface ICategoryState {
  case: 'rootCategory' | 'subCategory';
  value: RootCategory | SubCategory;
}

export const ModalProductCategories: FC<IProps> = memo(function ModalProductCategories(props) {
  const { active, categoryState: categoryStateFromProps, setCategoryState, onClose, clickSelectCategories } = props;
  const dispatch = useAppDispatch();
  const { categories, networkStatus } = useAppSelector(categoryState);
  const [selectedCategory, setSelectedCategory] = useState<ICategoryState[]>(categoryStateFromProps);
  useEffect(() => {
    if (networkStatus === NetworkStatus.None) {
      dispatch(getRootCategoryList());
    }
  }, [dispatch, networkStatus]);

  const [footerHeight, setFooterHeight] = useState(0);

  const selectAllInCategory = event => {
    const categoryId = event.currentTarget.dataset.categoryId;
    const category = categories.find(category => category.uuid === categoryId);

    const stateItemCategory: ICategoryState = {
      case: 'rootCategory',
      value: CategoryTranslator.toRootCategory({uuid: category.uuid, title: category.title}),
    };

    const requestData = ProductsTranslator.toProductListByCategory([stateItemCategory]);
    dispatch(getProductsByCategory(requestData)).then(() => {
      dispatch(addSelectedCategory({ uuid: category.uuid, title: category.title } as RootCategory));
      onClose();
    });

    // let subCategoriesItems: ICategoryState[] = category.subCategories.map(subcategory => ({
    //   case: 'subCategory',
    //   value: new SubCategory({
    //     categoryUuid: category.uuid,
    //     uuid: subcategory.uuid,
    //     title: subcategory.title,
    //   }),
    // }));

    // subCategoriesItems = subCategoriesItems.filter(item => {
    //   const itIsInArray = selectedCategory.find(el => el.value.uuid === item.value.uuid);
    //   if (itIsInArray) {
    //     return false;
    //   }
    //   return true;
    // });

    // const newCategoryState = [...selectedCategory, ...subCategoriesItems];

    // if (!newCategoryState.find(el => el.value.uuid === categoryId)) {
    //   newCategoryState.push(stateItemCategory);
    // }

    // setSelectedCategory(newCategoryState);
  };

  function onChangeSubcategory(event) {
    const isChecked = event.currentTarget.checked;
    const categoryId = event.currentTarget.dataset.categoryId;
    const subcategoryId = event.currentTarget.value;
    const category = categories.find(category => category.uuid === categoryId);
    const subcategory = category.subCategories.find(
      subcategory => subcategory.uuid === subcategoryId,
    );

    const stateItemCategory: ICategoryState = {
      case: 'rootCategory',
      value: CategoryTranslator.toRootCategory({ uuid: category.uuid, title: category.title }),
    };

    const stateItemSubCategory: ICategoryState = {
      case: 'subCategory',
      value: CategoryTranslator.toSubCategory({
        categoryUuid: category.uuid,
        uuid: subcategory.uuid,
        title: subcategory.title,
      }),
    };

    let newCategoryState = [...selectedCategory];

    if (isChecked) {
      newCategoryState.push(stateItemSubCategory);
    } else {
      newCategoryState = newCategoryState.filter(el => el.value.uuid !== subcategory.uuid);
    }

    // rootCategory in category state
    if (newCategoryState.find(el => el.value.uuid === categoryId)) {
      const subCategories = newCategoryState.filter(
        (el): el is { case: 'subCategory'; value: SubCategory } => el.case === 'subCategory',
      );
      if (subCategories.length === 0) {
        newCategoryState = newCategoryState.filter(el => el.value.uuid !== categoryId);
      }
    }

    // all subcategories is checked
    const subcategoriesInState = category.subCategories.map(el => {
      if (newCategoryState.find(item => item.value.uuid === el.uuid)) {
        return true;
      }
      return false;
    });
    if (subcategoriesInState.indexOf(false) === -1) {
      newCategoryState.push(stateItemCategory);
    } else {
      newCategoryState = newCategoryState.filter(el => el.value.uuid !== categoryId);
    }

    setSelectedCategory(newCategoryState);
  }

  function applyCategoryFilter() {
    setCategoryState(selectedCategory);
    clickSelectCategories(selectedCategory);
    onClose();
  }

  function closeWithoutApply() {
    setSelectedCategory([]);
    onClose();
  }

  const modalHeaderRef = useRef<HTMLDivElement>(null);
  const modalFooterRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setFooterHeight(node.clientHeight);
    }
  }, []);

  const { css } = useStyle(modalProductCategoriesRules, {
    headerHeight: modalHeaderRef.current?.clientHeight,
    footerHeight: footerHeight,
  });

  const { t } = useTranslation()

  return (
    <Modal
      name={ModalNames.SettingStory}
      active={active}
      propsStyles={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
      onClose={closeWithoutApply}
      expand={true}
    >
      <div ref={modalHeaderRef} className={css.header}>
        <Text text={t('modalProductCategories.productCategory')} mod="title" />
      </div>
      {networkStatus === NetworkStatus.Loading ? (
        <div className={css.spinnerWrapper}>
          <Spinner />
        </div>
      ) : (
        <div className={css.modalInner}>
          {categories.map(({ uuid, title, subCategories }) => (
            <div key={uuid} className={css.category}>
              <div className={css.categoryHeader}>
                <Text text={title} mod="title" fontSize={14} fontWeight={800} lineHeight={16} />
                <button
                  className={css.selectAllBtn}
                  type="button"
                  data-category-id={uuid}
                  onClick={selectAllInCategory}
                >
                  {t('modalProductCategories.selectEntireCategory')}
                </button>
              </div>
              <div className={css.subcategories}>
                {subCategories && subCategories.map(subcategory => (
                  <SubcategoryCard
                    categoryId={uuid}
                    name={subcategory.title}
                    value={subcategory.uuid}
                    key={subcategory.uuid}
                    onChange={onChangeSubcategory}
                    isChecked={Boolean(
                      selectedCategory.find(item => item.value.uuid === subcategory.uuid),
                    )}
                  />
                ))}
              </div>
            </div>
          ))}
          <div className={css.footer} ref={modalFooterRef}>
            <Button
              text="Выбрать"
              propsStyles={{ width: '100%' }}
              type="button"
              onClick={applyCategoryFilter}
            />
          </div>
        </div>
      )}
    </Modal>
  );
});
