import React, { FC, memo, useEffect, useRef, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { ModalSettingStoryRules } from './ModalSettingStory.style';
import Modal, { ModalNames } from 'src/components/Modal/Modal';
import Text from 'src/components/UI/Text/Text';
import { ImageLoader } from 'src/components/UI/ImageLoader/ImageLoader';
import { Input } from 'src/components/UI/Input/Input';
import { useTheme } from 'src/utils/theme/useTheme';
import { Button } from 'src/components/UI/Button/Button';
import { IStory } from 'src/types/stories';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { deleteStory } from 'src/redux/api/stories/deleteStory';
import { deleteStoryById, resetNetworkStatus, storiesState } from 'src/redux/slices/storiesSlice';
import { createStory } from 'src/redux/api/stories/createStory';
import { editStory } from 'src/redux/api/stories/editStory';
import { useScrollModal } from 'src/hooks/useScrollModal';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { correctUrlOnPaste, trimAndReplaceFunction } from 'src/utils/validateUrl';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';
import { Trans } from 'src/utils/i18n/components/trans';
import { linkMaxLength } from 'src/utils/constants';
import { useValidateUrl } from '../../../../utils/hooks/useValidateUrl';
import { NetworkStatus } from '../../../../utils/network/network.constant';

interface IProps {
  active: boolean;
  onClose: () => void;
  story?: IStory;
  editingStory?: boolean;
}

interface IFormStory {
  text: string;
  clickUrl: string;
}

const minimumResolution = '300×420';

export const ModalSettingStory: FC<IProps> = memo(function ModalSettingStory(props) {
  const { active, editingStory, story, onClose } = props;
  const dispatch = useAppDispatch();
  const formRef = useRef(null);
  const modalHeaderRef = useRef<HTMLDivElement>(null);
  const modalFooterRef = useRef<HTMLDivElement>(null);
  const modalInnerRef = useRef<HTMLDivElement>(null);
  const validateUrl = useValidateUrl();
  const [image, setImage] = useState<{ file: null | File; url: string }>({
    file: null,
    url: story?.imageUrl,
  });
  const { upsertNetworkStatus, deleteNetworkStatus } = useAppSelector(storiesState);
  const { t, tPlural } = useTranslation();

  // scroll modal on focus
  useScrollModal(modalInnerRef);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      text: story?.text,
      clickUrl: story?.clickUrl,
    },
  });

  useEffect(() => {
    if (deleteNetworkStatus === NetworkStatus.Done || upsertNetworkStatus === NetworkStatus.Done) {
      onClose();
      dispatch(resetNetworkStatus('deleteNetworkStatus'));
      dispatch(resetNetworkStatus('upsertNetworkStatus'));
    }
  }, [
    dispatch,
    onClose,
    deleteNetworkStatus,
    upsertNetworkStatus
  ]);

  const onSubmit: SubmitHandler<IFormStory> = data => {
    const { clickUrl, text } = data;

    if(!image.url) {
      modalInnerRef.current?.scrollTo(0, 0);
      return;
    }

    const storyEntity: IStory = {
      id: '',
      text,
      clickUrl,
      imageUrl: story?.imageUrl,
    };

    if (editingStory) {
      storyEntity.id = story.id;
      dispatch(editStory({ story: storyEntity, file: image.file })).then(() => onClose());
    } else {
      // storyEntity.id = '';
      dispatch(createStory({ story: storyEntity, file: image.file })).then(() => onClose());
    }


  };

  function onClickDelete() {
    dispatch(deleteStory(story)).then(() => {
      onClose();
      dispatch(deleteStoryById(story.id));
    });
  }

  const onChangeFile = (file: File) => {
    const url = URL.createObjectURL(file);
    setImage({ file: file, url: url });
  };

  const { css } = useStyle(ModalSettingStoryRules, {
    headerHeight: modalHeaderRef.current?.clientHeight,
    footerHeight: modalFooterRef.current?.clientHeight,
  });
  const { theme } = useTheme();
  const { colorGrey, colorBlack } = theme;

  const createOnChangeUrlFunction = (onChangeCallBack: (...event: any[]) => void) => {
    return function (event: React.ChangeEvent<HTMLInputElement>) {
      const { inputType } = event.nativeEvent as InputEvent;
      if (inputType === 'insertFromPaste') {
        const newVal = correctUrlOnPaste(event.target.value)
        onChangeCallBack(newVal);
      } else {
        onChangeCallBack(trimAndReplaceFunction(event.target.value));
      }
    }
  }

  return (
    <Modal
      name={ModalNames.SettingStory}
      active={active}
      propsStyles={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
      onClose={onClose}
    >
      <div ref={modalHeaderRef} className={css.header}>
        <Text text={t('modalSettingStory.story')} mod="title" />
      </div>
      <div className={css.modalInner} ref={modalInnerRef}>
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          <ImageLoader
            name="story-image"
            propsStyles={{ width: 110, height: 164, borderRadius: '8px' }}
            message={t('modalSettingStory.minimumResolution', minimumResolution)}
            onChangeFile={onChangeFile}
            imageUrl={image.url}
            extend={css.imageLoader}
          />
          <div className={css.textBlock}>
            <Input
              {...register('text', { required: t('modalSettingStory.requiredField') })}
              type="text"
              label={t('modalSettingStory.text')}
              controlled={false}
              letterCounter={true}
              placeholder={t('modalSettingStory.displayedOverTheImage')}
              propsStyles={{ backgroundColor: theme.background }}
              maxLength={60}
              errorMessage={errors.text?.message}
              labelTextTransform='uppercase'
            />
          </div>
          <div className={css.linkBlock}>
            <Controller
              control={control}
              name='clickUrl'
              rules={{
                required: t('modalSettingStory.requiredField'),
                validate: {
                  checkUrl: validateUrl,
                },
                maxLength: {
                  value: linkMaxLength,
                  message: tPlural('modalSettingStory.linkShouldBeLessThanCharacters', linkMaxLength)
                },
              }}
              render={({ field: { name, onChange, value, ref } }) => (
                <Input
                  ref={ref}
                  name={name}
                  type="text"
                  onChange={createOnChangeUrlFunction(onChange)}
                  value={value}
                  controlled={true}
                  label={t('modalSettingStory.link')}
                  placeholder={t('modalSettingStory.opensWhenTheStoryIsClicked')}
                  propsStyles={{ backgroundColor: theme.background }}
                  maxLength={1000}
                  labelTextTransform='uppercase'
                  errorMessage={errors.clickUrl?.message}
                  message={
                    <Trans
                      text={t(
                        'modalSettingStory.weRecommendToUseTeletypeSoUsersDontLeaveYourStore',
                        '<0>',
                        '</0>',
                      )}
                      components={[
                        <a key={1} href="https://teletype.in/" target="_blank" rel="noreferrer" />,
                      ]}
                    />
                  }
                />
              )}
            />
          </div>
          {editingStory && (
            <div className={css.removeBtnWrapper}>
              <Button
                text={t('modalSettingStory.delete')}
                propsStyles={{ background: colorGrey, color: colorBlack, width: '100%' }}
                hasGradient={false}
                disabled={upsertNetworkStatus === NetworkStatus.Loading || deleteNetworkStatus === NetworkStatus.Loading}
                onClick={onClickDelete}
              />
            </div>
          )}

          <div className={css.footer} ref={modalFooterRef}>
            <Button
              disabled={upsertNetworkStatus === NetworkStatus.Loading || deleteNetworkStatus === NetworkStatus.Loading}
              text={editingStory ? t('modalSettingStory.save') : t('modalSettingStory.createStory')}
              propsStyles={{ width: '100%' }}
              type="submit"
            />
          </div>
        </form>
      </div>
    </Modal>
  );
});
