export enum Toggles {
  loyaltyEnabled = 'loyaltyEnabled',
  multipleStoreEnabled = 'multipleStoreEnabled',
  promoCodeEnabled = 'promoCodeEnabled',
  newsletterEnabled = 'newsletterEnabled',
  productEnabled2_2 = 'productEnabled2_2',
  administratorsEnabled = 'administratorsEnabled',
  showBackendErrors = 'showBackendErrors',

  // DEV TOGGLES:
  debugWizard = 'debugWizard',
}

export interface IRemoteConfig {
  router?: {
    'hub-api': string;
    'fs-api': string;
    'billing-api': string;
  };
  accentColor?: string;
  hubBotUsername?: string;
  portBotUsername?: string;
  toggles?: Record<Toggles, boolean>;
}

export interface IConfig {
  hubApiUrl?: string;
  fsApiUrl?: string;
  billingApiUrl?: string;
  accentColor?: string;
  hubBotUsername?: string;
  portBotUsername?: string;
  toggles?: Record<Toggles, boolean>;
}
