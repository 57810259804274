import {
  ChangeEvent,
  ReactNode,
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { inputRules } from './input.style';
import Text from '../Text/Text';
import { Icon, IconList } from '../Icon/Icon';
import { telegramStarsString } from 'src/utils/constants';

interface CommonProps {
  name: string;
  type: string;
  id?: string;
  label?: string;
  message?: ReactNode;
  placeholder?: string;
  maxLength?: number;
  letterCounter?: boolean;
  symbol?: string;
  symbolPosition?: 'left' | 'right';
  addLeftIndentation?: number;
  propsStyles?: IStyles;
  iconBtn?: IconList;
  errorMessage?: string;
  autoFocus?: boolean;
  labelTextTransform?: 'uppercase' | 'none' | 'capitalize';
  onIconBtnClick?: (event: string | number) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
  telegramMask?: boolean;
  disabled?: boolean;
}

type ConditionalProps =
  | {
      controlled: true;
      value: string;
      onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    }
  | { controlled: false; value?: never; onChange?: (event: ChangeEvent<HTMLInputElement>) => void };

type IProps = CommonProps & ConditionalProps;

interface IStyles {
  backgroundColor: string;
}

export const Input = memo(
  forwardRef<HTMLInputElement, IProps>(function Input(props, ref) {
    const {
      id,
      name,
      type,
      controlled,
      value = '',
      placeholder,
      letterCounter,
      label,
      maxLength = 100,
      propsStyles,
      message,
      errorMessage,
      symbol,
      symbolPosition = 'right',
      addLeftIndentation,
      iconBtn,
      autoFocus,
      onChange,
      onFocus,
      onBlur,
      onIconBtnClick,
      labelTextTransform,
      telegramMask,
      disabled = false
    } = props;

    const inputRef = useRef<HTMLInputElement>(null);
    const [currentValue, setCurrentValue] = useState(value);
    const [valid, setValid] = useState({ valid: true, message: '' });

    useImperativeHandle(ref, () => inputRef.current, []);

    useEffect(() => {
      if (errorMessage && errorMessage.length) {
        setValid({ valid: false, message: errorMessage });
      } else {
        setValid({ valid: true, message: '' });
      }
    }, [errorMessage]);

    useEffect(() => {
      const input = inputRef.current;

      if (!input) return null;

      setCurrentValue(input.value);

      function listenInputKey(event) {
        if (event.key === 'Enter') {
          input.blur();
        }
      }

      function stopBrowserSubmit(event) {
        if (event.key === 'Enter') {
          event.preventDefault();
        }
      }

      function addDisableWheel() {
        input.addEventListener('wheel', disableWheel);
      }

      function removeDisableWheel() {
        input.removeEventListener('wheel', disableWheel);
      }

      if (input.type === 'number') {
        input.addEventListener('wheel', disableWheel);
        input.addEventListener('focus', addDisableWheel);
        input.addEventListener('blur', removeDisableWheel);
      }

      input.addEventListener('keypress', stopBrowserSubmit);
      input.addEventListener('keyup', listenInputKey);

      return () => {
        input.removeEventListener('keyup', listenInputKey);
        input.removeEventListener('keypress', stopBrowserSubmit);
        if(input.type === 'number') {
          input.removeEventListener('focus', addDisableWheel);
          input.removeEventListener('blur', removeDisableWheel);
        }
      };
    }, [inputRef]);

    function disableWheel(event: any) {
      event.preventDefault();
    }

    const { css } = useStyle(inputRules, {
      disabled,
      backgroundColor: propsStyles?.backgroundColor,
      isInvaild: !valid.valid,
      hasSymbol: Boolean(symbol) || Boolean(iconBtn),
      symbolPosition,
      addLeftIndentation
    });

    function onChangeValue(event: ChangeEvent<HTMLInputElement>) {
      if (telegramMask &&  !inputRef.current.value.startsWith('@')) {
        inputRef.current.value = `@${inputRef.current.value}`
      }
      // ! Не подчёркиваются красным необязательные props. Не работает ts Cannot invoke an object which is possibly 'undefined'
      if (!errorMessage) setValid({ valid: true, message: '' });
      setCurrentValue(event.target.value);
      onChange && onChange(event);
    }

    function handleFocus(event: React.FocusEvent<HTMLInputElement, Element>) {
      if (telegramMask &&  !inputRef.current.value.startsWith('@')) {
        inputRef.current.value = `@${inputRef.current.value}`
      }
      const input = event.currentTarget;
      // fix safari open keyboard scroll
      // input.style.opacity = '0';
      // setTimeout(() => (input.style.opacity = '1'));
      // fix carret position
      if (type === 'string') {
        setTimeout(() => {
          input.selectionStart = input.selectionEnd = 10000;
        }, 0);
      }
      onFocus && onFocus(event);
    }

    const onIconClickFunction = () => {
      onIconBtnClick && onIconBtnClick(id);
    };

    return (
      <div>
        {(label || letterCounter) && (
          <div className={css.top}>
            {label && (
              <label>
                <Text
                  text={label}
                  mod="text"
                  textTransform={labelTextTransform ? labelTextTransform : 'none'}
                  fontSize={12}
                  fontWeight={800}
                />
              </label>
            )}
            {letterCounter && (
              <Text mod="text" text={`${currentValue.length}\u2009/\u2009${maxLength}`} fontSize={12} />
            )}
          </div>
        )}

        <div className={`${css.inputWrapper} inputWrapper`}>
          {controlled ? (
            <input
              id={id}
              name={name}
              value={value}
              placeholder={placeholder}
              type={type}
              className={css.input}
              maxLength={maxLength}
              ref={inputRef}
              onChange={onChangeValue}
              onFocus={handleFocus}
              onBlur={onBlur}
              autoFocus={autoFocus}
              disabled={disabled}
              enterKeyHint={'done'}
            />
          ) : (
            <input
              id={id}
              name={name}
              placeholder={placeholder}
              type={type}
              className={css.input}
              maxLength={maxLength}
              ref={inputRef}
              onChange={onChangeValue}
              onFocus={handleFocus}
              onBlur={onBlur}
              autoFocus={autoFocus}
              disabled={disabled}
              enterKeyHint={'done'}
            />
          )}

          {!(telegramStarsString === symbol) && symbol && !iconBtn && (
            <Text
              text={symbol}
              mod="title"
              fontWeight={700}
              fontSize={16}
              lineHeight={18}
              extend={css.symbol}
            />
          )}
          {
            telegramStarsString === symbol && (
              <img className={css.symbol} src={`/images/currencies/${symbol}.svg`} alt='' />
            )
          }
          {!symbol && iconBtn && (
            <div onClick={onIconClickFunction} className={css.iconBtn}>
              <Icon name={iconBtn} />
            </div>
          )}
        </div>
        {(valid.message || message) && (
          <p className={css.message}>{valid.message ? valid.message : message}</p>
        )}
      </div>
    );
  }),
);
