import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useStyle } from '../../utils/theme/useStyle';
import { EditPromoCodePageRules } from '../EditPromoCodePage/EditPromoCodePage.style';
import { useScrollViewport } from '../../hooks/useScrollViewport';
import { Layout } from '../../components/Layout/Layout';
import Container from '../../components/Container/Container';
import { PromoCodeForm } from '../../components/PromoCodeForm/PromoCodeForm';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from '../../utils/i18n/hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { promoCodesSelector } from '../../redux/slices/promoCodesSlice';
import useBackButton from '../../hooks/useBackButton';
import { NetworkStatus } from '../../utils/network/network.constant';
import { openErrorModal } from '../../redux/slices/modalErrorSlice';

export const EditPromoCodePage: FC = memo(function EditPromoCodePage() {
  const { css } = useStyle(EditPromoCodePageRules);
  const [layoutEl, setLayoutEl] = useState({ current: null });
  const { t } = useTranslation();
  const { upsertPromoCodeStatus } = useAppSelector(promoCodesSelector);
  const layoutRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setLayoutEl({ current: node });
    }
  }, []);
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const BackButton = useBackButton();
  const navigate = useNavigate();

  useEffect(() => {
    if (upsertPromoCodeStatus === NetworkStatus.Failed) {
      dispatch(openErrorModal({
        errorTitle: t('editPromoCodesPage.failedToEditPromoCode')
      }));
    }
  }, [dispatch, t, upsertPromoCodeStatus]);
  useScrollViewport({scrollElementRef: layoutEl});

  useEffect(() => {
    BackButton.onClickCustom(goBack);
    function goBack() {
      navigate(-1);
    }
    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);


  return (
    <Layout
      ref={layoutRef}
    >
      <Container extend={css.container}>
        <PromoCodeForm promoCodeUuid={id} />
      </Container>
    </Layout>
  );
});
