import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { ProductFormRules } from './ProductForm.style';
import Toggler from 'src/components/UI/Toggler/Toggler';
import Text from 'src/components/UI/Text/Text';
import { ProductImagesLoader } from '../ProductImagesLoader/ProductImagesLoader';
import { Textarea } from 'src/components/UI/Textarea/Textarea';
import { Input } from 'src/components/UI/Input/Input';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import {
  changeProductImageUrls, productState,
  resetNetworkStatus,
  resetProductModifiers,
  setInstallingCategories,
  setInstallingModifiers,
  setProductCategory,
  setProductSubcategory,
  setSelectionsForProduct,
} from '../../../redux/slices/productSlice';
import { ListButton } from 'src/components/UI/ListButton/ListButton';
import { useModalState } from 'src/hooks/useModalState';
import { ModalSelectSelection } from '../ModalSelectSelection/ModalSelectSelection';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Button } from 'src/components/UI/Button/Button';
import { configState } from 'src/redux/slices/configSlice';
import { DeliveryType } from '@teleport/schemas-protobuf';
import { createProduct } from 'src/redux/api/products/createProduct';
import { useTheme } from 'src/utils/theme/useTheme';
import { ModalProductSuccessfulCreated } from '../ModalProductSuccessfulCreated/ModalProductSuccessfulCreated';
import { OwnerCatalogProductFull } from '@teleport/schemas-protobuf';
import { upsertProduct } from 'src/redux/api/products/upsertProduct';
import { useNavigate } from 'react-router-dom';
import { deleteProduct } from 'src/redux/api/products/deleteProduct';
import { useTelegram } from 'src/utils/telegramProvider';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { IProduct } from 'src/types/product';
import { getDiscountedPrice } from 'src/redux/translators/productsTranslator';
import ModalConfirm from 'src/components/ModalConfirm/ModalConfirm';
import { correctUrlOnPaste, trimAndReplaceFunction } from 'src/utils/validateUrl';
import { useTranslation } from '../../../utils/i18n/hooks/useTranslation';
import { useFirstRender } from 'src/hooks/useFirstRender';
import useCurrencySymbol from 'src/hooks/useCurrencySymbol';
import { wizardStateSelector } from 'src/redux/slices/wizardSlice';
import { linkMaxLength } from 'src/utils/constants';
import { uploadFiles } from 'src/redux/api/products/uploadFiles';
import { resetUploadedFilesNetworkStatus, uploadedFilesState } from 'src/redux/slices/uploadedFilesSlice';
import { getSelections } from 'src/redux/api/selections/getSelections';
import { selectionsState } from 'src/redux/slices/selectionsSlice';
import { categoryState, getRootCategoryList } from 'src/redux/slices/categorySlice';
import { ModalModifiers } from '../ModifiersForProduct/components/ModalModifiers/ModalModifiers';
import { getModifiersList, modifiersSelector } from '../../../redux/slices/modifiersSlice';
import { productsState } from '../../../redux/slices/productsSlice';
import { useAnalytics } from '../../../services/analytics/hooks/useAnalytics';
import { useValidateUrl } from '../../../utils/hooks/useValidateUrl';
import { openErrorModal } from '../../../redux/slices/modalErrorSlice';

interface IProductFormProps {
  // fixme product type
  product?: OwnerCatalogProductFull;
  editingProduct: boolean;
}

interface IFormProduct {
  showInCatalog: boolean;
  hasDiscount: boolean;
  productName: string;
  productPrice: number;
  productDescription: string;
  linkForOrder?: string;
  linkOrCode?: string;
  percentDiscount?: number;
  files?: File[];
}

const minimumResolution = '1125×1125';

export const ProductForm: FC<IProductFormProps> = memo(function ProductForm(props) {
  const { editingProduct, product } = props;

  const { config } = useAppSelector(configState);

  const {
    selections,
    category,
    subcategory,
    createNetworkStatus,
    upsertNetworkStatus,
    modifiers: productModifiers,
  } = useAppSelector(productState);
  const { modifiers, networkStatus: modifiersNetworkStatus } = useAppSelector(modifiersSelector)
  const { products } = useAppSelector(productsState)
  const [modifiersTitles, setModifiersTitles] = useState([])
  const {networkStatus: uploadNetWorkStatus, uploadedFilesUrls} = useAppSelector(uploadedFilesState)
  const { networkStatus: selectionsNetworkStatus, selections: selectionsFromResponse } = useAppSelector(selectionsState);
  const { categories: categoriesFromResponse, networkStatus: categoriesNetworkStatus } = useAppSelector(categoryState);
  const { networkStatus: getModifiersNetworkStatus } = useAppSelector(modifiersSelector);
  const { installingModifiers, deleteNetworkStatus } = useAppSelector(state => state.Product);

  const hasSelections = selectionsFromResponse?.length > 0
  const hasCategories = categoriesFromResponse?.length > 0

  const Telegram = useTelegram();
  const dispatch = useAppDispatch();

  const { theme } = useTheme();

  const navigate = useNavigate();

  const [footerHeight, setFooterHeight] = useState(0);

  const { css } = useStyle(ProductFormRules, { footerHeight });

  const { t, tPlural } = useTranslation();

  const validateUrl = useValidateUrl();

  const analytics = useAnalytics();

  const {
    wizard: wizardData,
  } = useAppSelector(wizardStateSelector);

  const [defaultValues] = useState<Partial<IFormProduct>>({
    showInCatalog: product ? Boolean(product?.enabled) : true,
    productName: product?.title || '',
    productPrice:
      (product?.price.discountEnabled
        ? Number(product?.price.oldAmount)
        : Number(product?.price.amount)) || null,
    productDescription: product?.description || '',
    hasDiscount: Boolean(product?.price.discountEnabled),
    percentDiscount: product?.price.discountInPercent || null,
    linkForOrder:
      product?.deliveryAction.case === 'deliveryLink' ? product.deliveryAction.value : '',
    linkOrCode:
      product?.deliveryAction.case === 'deliveryRedeemCode' ? product.deliveryAction.value : '',
    files: [],
  });

  const [formButtonIsDisabled, setFormButtonIsDisabled] = useState(false);

  const [savedSuccessfully, setSavedSuccessfully] = useState(false);

  const { colorBlack, colorGrey } = theme;

  const [renderSuccessModal, activeSuccessModal, openSuccessModal, closeSuccessModal] =
    useModalState();
  const [renderDeleteModal, activeDeleteModal, openDeleteModal, closeDeleteModal] = useModalState();

  useEffect(() => {
    const titles = [];
    const forEachModifiersUuids = [];
    productModifiers.forEach(el => {
      modifiers.forEach(modifier => {
        const matchingOption = modifier.variants.find(variant => variant.uuid === el.modifierVariantUuid);
        if (matchingOption && !forEachModifiersUuids.includes(modifier.uuid)) {
          titles.push(modifier.title);
          forEachModifiersUuids.push(modifier.uuid);
        }
      })
    })
    setModifiersTitles(titles)
  }, [modifiers, productModifiers]);

  useEffect(() => {
    if (modifiersNetworkStatus === NetworkStatus.None) {
      dispatch(getModifiersList());
    }
  }, [modifiersNetworkStatus, dispatch])

  useEffect(() => {
    if (categoriesNetworkStatus === NetworkStatus.None) {
      dispatch(getRootCategoryList());
    }
  }, [dispatch, categoriesNetworkStatus]);

  useEffect(() => {
    if (selectionsNetworkStatus === NetworkStatus.None) {
      dispatch(getSelections());
    }
  }, [dispatch, selectionsNetworkStatus]);

  useEffect(() => {
    return () => {
      dispatch(setProductSubcategory(null));
      dispatch(setProductCategory(null));
      dispatch(setInstallingCategories(false));
      dispatch(setSelectionsForProduct([]));
    };
  }, [dispatch]);

  useEffect(() => {
    if (upsertNetworkStatus === NetworkStatus.Loading || createNetworkStatus === NetworkStatus.Loading || uploadNetWorkStatus === NetworkStatus.Loading) {
      setFormButtonIsDisabled(true);
    }
    if (editingProduct && upsertNetworkStatus === NetworkStatus.Done) {
      // todo refactor me
      setSavedSuccessfully(true);
      setTimeout(() => {
        setSavedSuccessfully(false);
        navigate('/products');
        dispatch(resetNetworkStatus('upsertNetworkStatus'));
        setFormButtonIsDisabled(false);
      }, 1500);
    } else if (createNetworkStatus === NetworkStatus.Done) {
      openSuccessModal();
      dispatch(resetNetworkStatus('createNetworkStatus'));
      setFormButtonIsDisabled(false);
    }
  }, [
    uploadNetWorkStatus,
    upsertNetworkStatus,
    createNetworkStatus,
    editingProduct,
    dispatch,
    navigate,
    openSuccessModal,
  ]);

  useEffect(() => {
    return () => {
      dispatch(resetProductModifiers())
    }
  }, [dispatch])

  const modalFooterRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setFooterHeight(node.clientHeight);
    }
  }, []);

  const {
    register,
    watch,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
    control,
  } = useForm<IFormProduct>({
    defaultValues: defaultValues,
  });

  useEffect(() => {
    if (deleteNetworkStatus === NetworkStatus.Loading) {
      setFormButtonIsDisabled(true)
    } else {
      setFormButtonIsDisabled(false)
    }
  }, [deleteNetworkStatus]);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      setFormButtonIsDisabled(false);
    }
  }, [errors]);

  const onSubmit = useCallback<SubmitHandler<IFormProduct>>(data => {
    dispatch(resetUploadedFilesNetworkStatus());

    const {
      hasDiscount,
      productDescription,
      productName,
      productPrice,
      showInCatalog,
      linkForOrder,
      linkOrCode,
      percentDiscount,
      files,
    } = data;

    const requestData: { productData: IProduct; deliveryType: DeliveryType } = {
      productData: {
        id: '',
        enabled: showInCatalog,
        price: {
          amount: productPrice,
          discountEnabled: hasDiscount,
          discountInPercent: percentDiscount,
          oldAmount: 0,
        },
        modifiers: productModifiers,
        productName,
        productDescription,
        linkForOrder,
        linkOrCode,
        category,
        subCategory: subcategory,
        selections,
        imageUrls: [...new Set([...(product?.imageUrls ?? []), ...uploadedFilesUrls])],
      },
      deliveryType: config.deliveryType,
    };

    if (files.length > 0) {
      dispatch(uploadFiles({ files }));
    } else {
      if (editingProduct) {
        requestData.productData.id = product.uuid;
        dispatch(upsertProduct(requestData));
      } else {
        dispatch(createProduct(requestData));
      }
    }
  }, [
    dispatch,
    product?.imageUrls,
    uploadedFilesUrls,
    productModifiers,
    category,
    subcategory,
    selections,
    config.deliveryType,
    editingProduct,
    product?.uuid
  ]);
  //Submit form after uploadNetWorkStatus success
  useEffect(() => {
    if (uploadNetWorkStatus !== NetworkStatus.Done) return
    setValue('files', []);
    handleSubmit(onSubmit)()
  }, [uploadNetWorkStatus, handleSubmit, setValue, onSubmit])

  const [renderModal, activeModal, openModal, closeModal] = useModalState();
  const [renderModalModifier, activeModalModifier, openModalModifier, closeModalModifier] = useModalState();
  const hasDiscount = watch('hasDiscount');

  const onCloseModifierModal = () => {
    dispatch(setInstallingModifiers(false));
    closeModalModifier();
  }

  useEffect(() => {
    if(installingModifiers) {
      openModalModifier();
    }
  }, [installingModifiers, openModalModifier]);

  useEffect(() => {
    if (!hasDiscount) {
      setValue('percentDiscount', null);
    }
  }, [hasDiscount, setValue]);

  const setProductModifiers = () => {
    dispatch(setInstallingModifiers(true));
  };

  const setProductCategories = () => dispatch(setInstallingCategories(true));

  const deleteProductFn = () => {
    dispatch(deleteProduct(product.uuid)).then(() => {
      navigate('/products');
    });
    closeDeleteModal();
  };

  const closeConfirmModal = (isClickOk: boolean) => {
    if (isClickOk) {
      deleteProductFn();
    }
  };

  const onClickDelete = () => {
    if (Telegram.webApp.initData) {
      Telegram.webApp.showConfirm(t('productForm.deleteProduct'), closeConfirmModal);
    } else {
      openDeleteModal();
      // deleteProductFn();
    }
  };

  const handleFilesChange = (files: File[]) => {
    setValue('files', files);
  };

  const handleChangeUrlsOrder = (imageUrls: string[]) => {
    if (!product?.imageUrls) return
    dispatch(changeProductImageUrls(product.imageUrls = imageUrls.filter(image => image.length)))
  };

  const deleteImageUrls = url => {
    if (!product?.imageUrls) return
    dispatch(changeProductImageUrls(product.imageUrls.filter(el => el !== url)))
  };

  const isFirstRender = useFirstRender();

  const currentCurrencySymbol = useCurrencySymbol(wizardData?.currency);

  useEffect(() => {
    if (
      createNetworkStatus === NetworkStatus.None
      && upsertNetworkStatus === NetworkStatus.None
      && getModifiersNetworkStatus === NetworkStatus.None
    ) return

    if (createNetworkStatus === NetworkStatus.Loading || upsertNetworkStatus === NetworkStatus.Loading) {
      setFormButtonIsDisabled(true);
    }

    if (
      createNetworkStatus === NetworkStatus.Failed
      || upsertNetworkStatus === NetworkStatus.Failed) {
      setFormButtonIsDisabled(false);
      dispatch(openErrorModal({
        errorTitle: t('productForm.failedToUpdateTheProduct')
      }));
      dispatch(resetNetworkStatus('createNetworkStatus'))
      dispatch(resetNetworkStatus('upsertNetworkStatus'))
    }

    if (createNetworkStatus === NetworkStatus.Loading || upsertNetworkStatus === NetworkStatus.Loading) {
      setFormButtonIsDisabled(true);
    }

    if (getModifiersNetworkStatus === NetworkStatus.Failed) {
      dispatch(openErrorModal({
        errorTitle: t('productForm.failedToLoadProductModifiers')
      }));
      dispatch(resetNetworkStatus('createNetworkStatus'))
      dispatch(resetNetworkStatus('upsertNetworkStatus'))
    }

    if (createNetworkStatus === NetworkStatus.Done && products.length === 1) {
      analytics.firstProductCreated();
      if (product?.imageUrls?.length > 0) {
        analytics.firstImageUploaded();
      }
    }

  }, [
    t,
    dispatch,
    createNetworkStatus,
    upsertNetworkStatus,
    getModifiersNetworkStatus,
    analytics,
    product,
    products
  ])

  const createOnChangeUrlFunction = (onChangeCallBack: (...event: any[]) => void) => {
    return function (event: React.ChangeEvent<HTMLInputElement>) {
      const { inputType } = event.nativeEvent as InputEvent;
      if (inputType === 'insertFromPaste') {
        const newVal = correctUrlOnPaste(event.target.value)
        onChangeCallBack(newVal);
      } else {
        onChangeCallBack(trimAndReplaceFunction(event.target.value));
      }
    }
  }

  const categoryPlaceholder = hasCategories? t('productForm.chooseFromTheList') : t('productForm.youDontHaveAnyСategoriesYet')
  const selectionPlaceholder = hasSelections ? t('productForm.chooseFromTheList') : t('productForm.youDontHaveAnySelectionsYet')
  return (
    <>
      <form className={css.wrapper} onSubmit={handleSubmit(onSubmit)}>
        <div className={css.wrapperJustify}>
          <Text
            text={t('productForm.showInCatalog')}
            mod="title"
            fontSize={12}
          />
          <Controller
            name="showInCatalog"
            control={control}
            render={({ field: { name, onChange } }) => (
              <Toggler
                name={name}
                checked={watch('showInCatalog')}
                value="show-in-catalog"
                onChange={onChange}
              />
            )}
          />
        </div>
        <ProductImagesLoader
          name="product-images"
          message={t(
            'productForm.minimumResolutionIsNumberFirstImageIsMainOrderCanBeChangedByDragging',
            minimumResolution,
          )}
          deleteImageUrls={deleteImageUrls}
          onChangeFile={handleFilesChange}
          onChangeUrlsOrder={handleChangeUrlsOrder}
          initialImages={product?.imageUrls}
          uploadedFilesUrls={uploadedFilesUrls}
        />
        <Textarea
          {...register('productName', { required: t('productForm.requiredField') })}
          controlled={false}
          label={t('productForm.name')}
          placeholder={t('productForm.enterProductName')}
          maxLength={70}
          letterCounter={true}
          errorMessage={errors.productName?.message}
        />
        <Input
          {...register('productPrice', {
            required: t('productForm.requiredField'),
            min: {
              value: 1,
              message: t('productForm.cannotBeLessThan', 1)
            },
            valueAsNumber: true,
          })}
          controlled={false}
          label={t('productForm.price')}
          type="number"
          placeholder={t('productForm.enterProductPrice')}
          maxLength={70}
          symbol={currentCurrencySymbol}
          errorMessage={errors.productPrice?.message}
        />
        <div className={css.wrapperJustify}>
          <Text
            text={t('productForm.discount')}
            mod="title"
            fontSize={12}
          />
          <Controller
            name="hasDiscount"
            defaultValue={product?.price.discountEnabled}
            control={control}
            render={({ field: { name, onChange } }) => (
              <Toggler
                name={name}
                value="discount"
                checked={Boolean(watch('hasDiscount'))}
                onChange={onChange}
              />
            )}
          />
        </div>
        {watch('hasDiscount') && (
          <Input
            {...register('percentDiscount', {
              valueAsNumber: true,
              max: {
                value: 100,
                message: t('productForm.cannotBeMoreThan100'),
              },
              min: {
                value: 1,
                message: t('productForm.cannotBeLessThan', 1)
              },
              required: t('productForm.requiredField'),
            })}
            placeholder={t('productForm.enterDiscountPercent')}
            autoFocus={isFirstRender ? false : true}
            type="number"
            controlled={false}
            symbol="%"
            errorMessage={errors.percentDiscount?.message}
            message={t('productForm.discountedPrice', getDiscountedPrice(watch('productPrice'), watch('percentDiscount')), currentCurrencySymbol)}
          />
        )}
        <Textarea
          {...register('productDescription')}
          controlled={false}
          label={t('productForm.description')}
          placeholder={t('productForm.enterProductDescription')}
          maxLength={1000}
        />

        {/* This StoreType will appear in the future */}
        {/* {storeType === StoreType.ELECTRONIC && (
        <>
          <div className={css.wrapperJustify}>
            <Text
              text="Многоразовый код или ссылка"
              mod="title"

              fontSize={12}
            />
            <Toggler name="code-or-link" value="code-or-link" onChange={onChangeHasCode} />
          </div>
          {hasCode && (
            <Input
              controlled={true}
              name="percent-discount"
              type="string"
              placeholder="Вставьте код или ссылку"
              value={codeValue}
              onChange={onChangeCode}
            />
          )}
        </>
      )} */}

        {config.deliveryType === DeliveryType.SHOWCASE && (
          <Controller
            control={control}
            name='linkForOrder'
            rules={{
              required: t('productForm.requiredField'),
              validate: {
                checkUrl: validateUrl,
              },
              maxLength: {
                value: linkMaxLength,
                message: tPlural('productForm.linkShouldBeLessThanCharacters', linkMaxLength)
              },
            }}
            render={({ field: { name, onChange, value, ref } }) => (
              <Input
                ref={ref}
                name={name}
                type="text"
                onChange={createOnChangeUrlFunction(onChange)}
                value={value}
                controlled={true}
                label={t('productForm.linkToOrderTheProduct')}
                placeholder={t('productForm.enterLink')}
                maxLength={2000}
                labelTextTransform='uppercase'
                errorMessage={errors.linkForOrder?.message}
              />
            )}
          />
        )}
        {/* // Если включены подкатегории, то товары хранятся в них !!!
      // Получается при включенных подкатегориях, нельзя создать товар без подкатегории? */}
        <ListButton
          title={t('productForm.categories')}
          onClick={setProductCategories}
          bg="transparent"
          placeholder={category?.title ?? categoryPlaceholder}
          titleFontSize={12}
          disabled={!hasCategories}
        />
        <ListButton
          title={t('productForm.productModifier')}
          onClick={setProductModifiers}
          bg="transparent"
          titleFontSize={12}
          placeholder={modifiersTitles.length > 0 ? modifiersTitles.join(', ') : t('productForm.selectFromTheListAndFillIn')}
        />
        <ListButton
          title={t('productForm.addToSelection')}
          onClick={openModal}
          bg="transparent"
          placeholder={selections.length > 0
            ? tPlural('productForm.countSelections', selections.length)
            : selectionPlaceholder
          }
          titleFontSize={12}
          disabled={!hasSelections}
        />
        {renderModal && <ModalSelectSelection active={activeModal} onClose={closeModal} />}
        {editingProduct && (
          <div className={css.removeBtnWrapper}>
            <Button
              text={t('productForm.delete')}
              propsStyles={{ background: colorGrey, color: colorBlack, width: '100%' }}
              hasGradient={false}
              onClick={onClickDelete}
              disabled={formButtonIsDisabled}
            />
          </div>
        )}
        <div className={css.footer} ref={modalFooterRef}>
          <Button
            disabled={formButtonIsDisabled}
            text={editingProduct ? t('productForm.save') : t('productForm.createProduct')}
            successfully={savedSuccessfully}
            propsStyles={{ width: '100%' }}
            type="submit"
          />
        </div>
      </form>
      {renderSuccessModal && (
        <ModalProductSuccessfulCreated
          showInCatalog={getValues('showInCatalog')}
          active={activeSuccessModal}
          onClose={closeSuccessModal}
        />
      )}
      {renderDeleteModal && (
        <ModalConfirm
          active={activeDeleteModal}
          onClose={closeDeleteModal}
          title={t('productForm.deleteProduct')}
          confirmAction={deleteProductFn}
        />
      )}
      {renderModalModifier && <ModalModifiers active={activeModalModifier} onClose={onCloseModifierModal} />}
    </>
  );
});
