import { useModalState } from 'src/hooks/useModalState';
import { ModalUniversal } from '../ModalUniversal/ModalUniversal';
import {  memo, useEffect, useState } from 'react';
import { IntegrationTelegramForm } from '../IntegrationTelegramForm/IntegrationTelegramForm';
import Container from '../Container/Container';
import { ListButton } from '../UI/ListButton/ListButton';
import { ModalUniversalContentType } from 'src/pages/StoreSetupPage/StoreSetupPageEnums';
import { useStyle } from 'src/utils/theme/useStyle';
import { integrationsBlockRules } from './IntegrationBlock.style';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { integrationState } from 'src/redux/slices/integrationSlice';
import { ModalWarningAsyncChanges } from '../ModalWarningAsyncChanges/ModalWarningAsyncChanges';
import { useLocalStorage } from 'src/hooks/useLocalStorage';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';
import { wizardStateSelector, resetNetworkStatus } from 'src/redux/slices/wizardSlice';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { togglesState } from 'src/redux/slices/configSlice';
import { openErrorModal } from 'src/redux/slices/modalErrorSlice';

export const IntegrationBlock = memo(function IntegrationBlock() {
  const { css } = useStyle(integrationsBlockRules);
  const [renderModalUniversal, activeModalUniversal, openModalUniversal, closeModalUniversal] =
    useModalState();
  const [renderAsyncModal, activeAsyncModal, openAsyncModal, closeAsyncModal] = useModalState();

  const [optionsTitle, setOptionsTitle] = useState('');
  const [modalUniversalContentType, setModalUniversalContentType] = useState('');
  const { saveNetworkStatus, saveStateError: wizardSaveStateError } = useAppSelector(wizardStateSelector);
  const { integration} = useAppSelector(integrationState);
  const [noShowModalWarningAsyncChanges, setNoShowModalWarningAsyncChanges] = useLocalStorage('noShowModalWarningAsyncChanges');

  const dispatch = useAppDispatch()
  const { t } = useTranslation();

  const { showBackendErrors } = useAppSelector(togglesState);
  
  const onClickListButton = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLElement;
    const targetParentId = target.closest('.list-button')?.id;
    //todo избавиться от работы с ДОМ https://app.clickup.com/t/86c1nm25z
    if (!target.id && !targetParentId) return;
    const targetId = target.id ? target.id : targetParentId;
    switch (targetId) {
      case ModalUniversalContentType.integrationIntoChannel:
        setOptionsTitle(t('storeSetupPage.integrationIntoTheChannel'));
        setModalUniversalContentType(ModalUniversalContentType.integrationIntoChannel);
        openModalUniversal();
        return;
      default:
        setOptionsTitle('');
        return;
    }
  };


  useEffect(() => {
    if (saveNetworkStatus === NetworkStatus.Done) {
      setTimeout(() => {
        if (!noShowModalWarningAsyncChanges) {
          openAsyncModal();
        }
      }, 1500);
      dispatch(resetNetworkStatus('saveNetworkStatus'));
      closeModalUniversal();
    }
    if (saveNetworkStatus === NetworkStatus.Failed) {
      dispatch(openErrorModal({
        errorText: showBackendErrors ? wizardSaveStateError : ''
      }))
    }
  }, [showBackendErrors, saveNetworkStatus, wizardSaveStateError, noShowModalWarningAsyncChanges, closeModalUniversal, dispatch, openAsyncModal]);

  return (
    <>
      
      <Container>
        <div className={css.content}>
          <div className={css.grid}>
            <ListButton
              id={ModalUniversalContentType.integrationIntoChannel}
              title={t('integrationBlock.integrationIntoTheChannel')}
              placeholder={integration.channelLink ? `@${integration.channelLink.split('/').pop()}` : t('integrationBlock.addApplicationInformation')}
              onClick={onClickListButton}
              padding='16px'
            />
          </div>
        </div>
      </Container>
      {renderModalUniversal && (
        <ModalUniversal
          title={optionsTitle}
          active={activeModalUniversal}
          onClose={closeModalUniversal}
          paddingBottom={modalUniversalContentType === ModalUniversalContentType.integrationIntoChannel ? 126 : undefined}
        >
          {modalUniversalContentType === ModalUniversalContentType.integrationIntoChannel && (
            <IntegrationTelegramForm onSubmitCallback={closeModalUniversal} />
          )}
        </ModalUniversal>
      )}
      {renderAsyncModal && <ModalWarningAsyncChanges onClickNoShowMore={setNoShowModalWarningAsyncChanges} onClose={closeAsyncModal} active={activeAsyncModal} />}
    </>
  )
})
