import { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { ModalSelectSelectionRules } from './ModalSelectSelection.style';
import Modal, { ModalNames } from 'src/components/Modal/Modal';
import Text from 'src/components/UI/Text/Text';
import { Button } from 'src/components/UI/Button/Button';
import { Option } from 'src/components/UI/Option/Option';
import { useTheme } from 'src/utils/theme/useTheme';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { ISelection, selectionsState } from 'src/redux/slices/selectionsSlice';
import { getSelections } from 'src/redux/api/selections/getSelections';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { setSelectionsForProduct } from 'src/redux/slices/productSlice';
import { Spinner } from 'src/components/UI/Spinner/Spinner';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';

interface IProps {
  active: boolean;
  onClose: () => void;
}

export const ModalSelectSelection: FC<IProps> = memo(function ModalSelectSelection(props) {
  const { active, onClose } = props;
  const dispatch = useAppDispatch();
  const { networkStatus, selections } = useAppSelector(selectionsState);
  const { selections: productSelections } = useAppSelector(state => state.Product);

  const { t } = useTranslation()

  const [selectedSelections, setSelectedSelections] = useState<ISelection[]>(productSelections);
  const selectSelection = value => {
    const selection = selections.find(el => el.uuid === value);
    if (!selection) return;

    if (selectedSelections.find(el => el.uuid === selection.uuid)) {
      setSelectedSelections(selectedSelections.filter(el => el.uuid !== selection.uuid));
    } else {
      setSelectedSelections([
        ...selectedSelections,
        { title: selection.title, uuid: selection.uuid, visibility: selection.visibility },
      ]);
    }
  };

  const modalHeaderRef = useRef<HTMLDivElement>(null);
  const { css } = useStyle(ModalSelectSelectionRules, {
    headerHeight: modalHeaderRef.current?.clientHeight,
  });
  const { theme } = useTheme();

  useEffect(() => {
    if (networkStatus === NetworkStatus.None) {
      dispatch(getSelections());
    }
  }, [dispatch, networkStatus]);

  const clickSaveButton = useCallback(() => {
    dispatch(setSelectionsForProduct(selectedSelections));
    onClose();
  }, [selectedSelections, dispatch, onClose]);

  return (
    <Modal
      name={ModalNames.SelectSelection}
      active={active}
      propsStyles={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
      onClose={onClose}
    >
      <div ref={modalHeaderRef} className={css.header}>
        <Text text={t('modalSelectSelection.addToSelection')} mod="title" />
      </div>
      <div className={css.modalInner}>
        {networkStatus === NetworkStatus.Loading ? (
          <div className={css.spinnerWrapper}>
            <Spinner />
          </div>
        ) : (
          <form>
            <div className={css.optionsWrapper}>
              {selections.map(el => (
                <Option
                  key={el.uuid}
                  type="checkbox"
                  title={el.title}
                  value={el.uuid}
                  onChange={selectSelection}
                  checked={Boolean(
                    selectedSelections.find(selection => selection.uuid === el.uuid),
                  )}
                  bg={theme.colorGrey}
                />
              ))}
            </div>
            <div className={css.footer}>
              <Button
                text="Сохранить"
                propsStyles={{ width: '100%' }}
                type="button"
                onClick={clickSaveButton}
              />
            </div>
          </form>
        )}
      </div>
    </Modal>
  );
});
