import { IRuleFn } from '../../utils/theme/fela.interface';
import { padding } from '../../utils/applyCSSProperty ';
import { createCachedStyle } from '../../utils/theme/createCachedStyle';

export interface IManageAdministratorsPageKeys {
  header: {};
  wrapper: {};
  spinnerWrapper: {};
  container: {};
}

export type IManageAdministratorsPage = IRuleFn<IManageAdministratorsPageKeys>;

const ManageAdministratorsPageRuleFn: IManageAdministratorsPage = () => {
  return {
    header: {
      ...padding(16),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 10,
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 16,
    },
    spinnerWrapper: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      justifyContent: 'center',
    },
    container: {
      height: '100%',
    }
  };
};

export const ManageAdministratorsPageRules = createCachedStyle(ManageAdministratorsPageRuleFn)
