import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { NetworkStatus } from '../../utils/network/network.constant';
import { UiBlocksTranslator } from '../translators/uiBlocksTranslator';
import { createAppAsyncThunk } from '../../hooks/redux';

export enum BlockType {
  TextBlock = 'textBlock',
  StoriesBlock = 'storiesBlock',
  SliderBlock = 'sliderBlock',
  SelectionBlock = 'selectionBlock'
}

export interface ITextBlockButton {
  enabled: boolean;
  text: string;
  clickUrl: string;
}

export interface ITextBlock {
  uuid: string;
  title: string;
  text: string;
  accentColor: boolean;
  button?: ITextBlockButton;
  type: BlockType.TextBlock;
}

export interface IStory {
  uuid: string;
  text: string;
  imageUrl: string;
  clickUrl: string;
}

export interface IStoryItem {
  story?: IStory;
  viewed: boolean;
}

export interface IStoriesBlock {
  uuid: string;
  items: IStoryItem[];
  type: BlockType.StoriesBlock;
}

export interface ISliderItem {
  imageUrl: string;
  clickUrl: string;
}

export interface ISliderBlock {
  uuid: string;
  items: ISliderItem[];
  type: BlockType.SliderBlock;
}

export interface ISelectionBlockVisibility {
  showOnMain: boolean;
  showInRecommendations: boolean;
}

export interface ISelectionBlock {
  title: string;
  uuid: string;
  visibility?: ISelectionBlockVisibility;
  enabled: boolean;
  type: BlockType.SelectionBlock;
}

export type IUiBlock = ITextBlock | IStoriesBlock | ISliderBlock | ISelectionBlock;

interface IUiBlocksSlice {
  uiBlocks: Record<string, IUiBlock>;
  order: string[];
  networkStatus: NetworkStatus;
  reorderNetworkStatus: NetworkStatus;
}

const initialState: IUiBlocksSlice = {
  uiBlocks: {},
  order: [],
  networkStatus: NetworkStatus.None,
  reorderNetworkStatus: NetworkStatus.None,
};

export const reorderUiBlocksList = createAppAsyncThunk(
  'uiBlocks/reorderUiBlocksList',
  async (data: { blocks: Record<string, IUiBlock>, order: string[] }, thunkAPI) => {
    const request = UiBlocksTranslator.toUIBlockReorderRequest(data.blocks, data.order);
    return await thunkAPI.extra.portOwnerApi().uIBlockReorder(request);
  },
);

export const fetchUiBlocks = createAppAsyncThunk('uiBlocks/fetchUiBlocks', async (_, thunkAPI) => {
  const result = await thunkAPI.extra.portClientApi().status({});
  return UiBlocksTranslator.fromRootUiBlocksListResponse(result);
});

export const uiBlocksSlice = createSlice({
  name: 'UiBlocks',
  initialState,
  reducers: {
    setUiBlockOrder: (state, { payload }: PayloadAction<string[]>) => {
      state.order = payload;
    },
    resetNetworkStatus: state => {
      state.networkStatus = NetworkStatus.None;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchUiBlocks.pending, state => {
      state.networkStatus = NetworkStatus.Loading;
    });
    builder.addCase(fetchUiBlocks.fulfilled, (state, action) => {
      const { blocks, order } = action.payload;
      state.uiBlocks = blocks;
      state.order = order
      state.networkStatus = NetworkStatus.Done;
    });
    builder.addCase(fetchUiBlocks.rejected, state => {
      state.networkStatus = NetworkStatus.Failed;
    });

    builder.addCase(reorderUiBlocksList.rejected, state => {
      state.reorderNetworkStatus = NetworkStatus.Failed;
    });
    builder.addCase(reorderUiBlocksList.pending, state => {
      state.reorderNetworkStatus = NetworkStatus.Loading;
    });
    builder.addCase(reorderUiBlocksList.fulfilled, state => {
      state.reorderNetworkStatus = NetworkStatus.Done
    });

  },
});

export const { setUiBlockOrder, resetNetworkStatus } = uiBlocksSlice.actions;
export const uiBlocksState = (state: RootState) => state[uiBlocksSlice.name];
