import { Modifier, ModifierListResponse, ModifierType, ModifierUpsertRequest } from '@teleport/schemas-protobuf';
import { IModifier, IModifierType } from '../slices/modifiersSlice';


export const ModifiersTranslator = {
  formModifierForRequest(modifier: IModifier) {
    const uiType = modifier.uiType as unknown as ModifierType;
    const modifierForRequest = new Modifier({
      ...modifier,
      uiType: uiType
    })
    const requestData = new ModifierUpsertRequest({modifier: modifierForRequest})
    return  requestData
  },
  formModifierForResponse(modifiersListResponse: ModifierListResponse) {
    const modifiersList:IModifier[]   =  modifiersListResponse.modifiers.map(modifier => {
      return {
        uuid: modifier.uuid,
        uiType: modifier.uiType as unknown as IModifierType,
        title: modifier.title,
        descriptionLink: modifier.descriptionLink,
        affectsPrice: modifier.affectsPrice,
        variants: modifier.variants.map(variant => {
          return {
            uuid: variant.uuid,
            title: variant.title,
          }
        }),
      }
    })
    return modifiersList
  }
}
