import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { botIsNotAdminError } from 'src/utils/customErrors';

interface IModalErrorSlice {
  modalErrorIsOpen: boolean;
  errorTitle:  string;
  errorText: string;
}

const initialState: IModalErrorSlice = {
  modalErrorIsOpen: false,
  errorTitle: '',
  errorText: '',
};

export const modalErrorSlice = createSlice({
  name: 'ModalError',
  initialState,
  reducers: {
    openErrorModal: (state, action: PayloadAction<{
      errorTitle?: string,
      errorText?: string,
    }>) => {
      if(action.payload.errorText?.includes(botIsNotAdminError)) return
      state.modalErrorIsOpen = true;
      if (action.payload) {
        state.errorTitle = action.payload.errorTitle;
        state.errorText = action.payload.errorText
      }
    },
    closeErrorModal: state => {
      state.modalErrorIsOpen = false;
      state.errorTitle = '';
      state.errorText = '';
    },
  },
});

export const { openErrorModal, closeErrorModal } = modalErrorSlice.actions;

export const modalErrorState = (state: RootState) => state[modalErrorSlice.name];
