import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { createAppAsyncThunk } from 'src/hooks/redux';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { openErrorModal } from './modalErrorSlice';

interface IQrSlice {
  qrCodeUrl: string;
  networkStatus: NetworkStatus;
}

const initialState: IQrSlice = {
  qrCodeUrl: undefined,
  networkStatus: NetworkStatus.None,
};

export const uploadQr = createAppAsyncThunk(
  'uploadFiles',
  async (data: {file: File}, thunkAPI) => {
    const { file } = data;
    let qrUrls: string[]

    try {
      qrUrls = await thunkAPI.extra.filesUpload([file], '24h');
    } catch (e) {
      console.info('Upload QR failed: ', e);
      thunkAPI.dispatch(openErrorModal({
        errorTitle: 'Image upload error',
        errorText: 'The image could not be uploaded, please try again',
      }))
    }
    return qrUrls
  }
)


export const qrSlice = createSlice({
  name: 'Qr',
  initialState,
  reducers: {
    resetQrData: state => {
      state.qrCodeUrl = undefined
      state.networkStatus = NetworkStatus.None
    }
  },
  extraReducers: builder => {
    builder.addCase(uploadQr.pending, state => {
      state.networkStatus = NetworkStatus.Loading
    });
    builder.addCase(uploadQr.rejected, state => {
      state.networkStatus = NetworkStatus.Failed
    });
    builder.addCase(uploadQr.fulfilled, (state, action) => {
      state.networkStatus = NetworkStatus.Done
      if (action.payload?.length) {
        state.qrCodeUrl = action.payload[0]
      }
    });
  }
});

export const { resetQrData } = qrSlice.actions;

export const qrState = (state: RootState) => state[qrSlice.name];
