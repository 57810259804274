import { FC, memo, useEffect, useMemo, useState } from 'react';
import Footer from 'src/components/Footer/Footer';
import { Layout } from 'src/components/Layout/Layout';
import { Button } from 'src/components/UI/Button/Button';
import Text from 'src/components/UI/Text/Text';
import { useStyle } from 'src/utils/theme/useStyle';
import { storeSetupPageRules } from './StoreSetupPage.style';
import Container from 'src/components/Container/Container';
import { ListButton } from 'src/components/UI/ListButton/ListButton';
import useLoader from 'src/hooks/useLoader';
import { useModalState } from 'src/hooks/useModalState';
import { ModalUniversal } from 'src/components/ModalUniversal/ModalUniversal';
import { wizardStateSelector, resetNetworkStatus, setAppFocus } from 'src/redux/slices/wizardSlice';
import { Option } from 'src/components/UI/Option/Option';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { useNavigate } from 'react-router-dom';
import { ClientSupportForm } from 'src/components/ClientSupportForm/ClientSupportForm';
import { WelcomeMessage } from 'src/components/WelcomeMessage/WelcomeMessage';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { wizardGetState } from 'src/redux/api/wizard/wizardGetState';
import { WizardTranslator } from 'src/redux/translators/wizardTranslator';
import { wizardSaveState } from 'src/redux/api/wizard/wizardSaveState';
import useBackButton from 'src/hooks/useBackButton';
import { useTelegram } from 'src/utils/telegramProvider';
import { MaintenanceEnabledForm } from 'src/components/MaintenanceEnabledForm/MaintenanceEnabledForm';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';
import { useCurrencyData } from 'src/utils/hooks/useCurrencyData';
import { useLanguageData } from 'src/utils/hooks/useLanguageData';
import useColorName from 'src/hooks/useColorName';
import {UserRole, WizardSaveStateRequest} from '@teleport/schemas-protobuf';
import { useLocalStorage } from 'src/hooks/useLocalStorage';
import { ModalWarningAsyncChanges } from 'src/components/ModalWarningAsyncChanges/ModalWarningAsyncChanges';
import { useAppFocusList } from '../../hooks/useAppFocusList';
import { AppFocusCases } from 'src/types/wizard';
import { ModalUniversalContentType } from './StoreSetupPageEnums';
import { togglesState } from 'src/redux/slices/configSlice';
import { openErrorModal } from '../../redux/slices/modalErrorSlice';
import { RoutePath } from '../../routing/routeConfig';
import { getStoreList, multipleStoreState } from '../../redux/slices/multipleStoreSlice';
import { userStateSelector } from 'src/redux/slices/userSlice';

export const StoreSetupPage: FC = memo(function StoreSetupPage() {
  const { css } = useStyle(storeSetupPageRules);
  const { hideLoader } = useLoader();
  const navigate = useNavigate();
  const BackButton = useBackButton();
  const dispatch = useAppDispatch();
  const { wizard: wizardData, networkStatus, saveNetworkStatus, saveStateError: wizardSaveStateError } = useAppSelector(wizardStateSelector);
  const Telegram = useTelegram();
  const [noShowModalWarningAsyncChanges, setNoShowModalWarningAsyncChanges] = useLocalStorage('noShowModalWarningAsyncChanges');
  const { administratorsEnabled, multipleStoreEnabled } = useAppSelector(togglesState);
  const { networkStatus: getStoreListNetworkStatus } = useAppSelector(multipleStoreState);
  const { userRole } = useAppSelector(userStateSelector);

  const { t } = useTranslation();

  const currencies = useCurrencyData();
  const appFocuses = useAppFocusList();
  const languages = useLanguageData();

  const { showBackendErrors } = useAppSelector(togglesState);

  useEffect(() => {
    if (networkStatus === NetworkStatus.None) {
      dispatch(wizardGetState({}));
    }
    if (networkStatus === NetworkStatus.Done) {
      hideLoader();
    }
  }, [networkStatus, dispatch, hideLoader]);

  useEffect(() => {
    BackButton.onClickCustom(goBack);
    function goBack() {
      navigate('/');
    }
    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  const [renderModalUniversal, activeModalUniversal, openModalUniversal, closeModalUniversal] =
    useModalState();
  const [renderAsyncModal, activeAsyncModal, openAsyncModal, closeAsyncModal] = useModalState();

  const [modalUniversalContentType, setModalUniversalContentType] = useState('');

  const [options, setOptions] = useState([]);
  const [optionsTitle, setOptionsTitle] = useState('');

  const sendLanguageOrCurrency = (wizardStateRequest: WizardSaveStateRequest) => {
    dispatch(wizardSaveState({requestData: wizardStateRequest}))
  }

  const onChangeLanguage = value => {
    const wizardStateRequest = WizardTranslator.toWizardSaveStateRequest({...wizardData, language: Number(value)}, true);
    sendLanguageOrCurrency(wizardStateRequest)
  };
  const onChangeCurrency = value => {
    const wizardStateRequest = WizardTranslator.toWizardSaveStateRequest({...wizardData, currency: Number(value)}, true);
    sendLanguageOrCurrency(wizardStateRequest)
  };

  const onClickListButton = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLElement;
    const targetParentId = target.closest('.list-button')?.id;
    if (!target.id && !targetParentId) return;
    const targetId = target.id ? target.id : targetParentId;
    switch (targetId) {
      case ModalUniversalContentType.storeСard:
        navigate('/profile');
        return;
      case ModalUniversalContentType.languages:
        setOptionsTitle(t('storeSetupPage.language'));
        setOptions(languages);
        setModalUniversalContentType(ModalUniversalContentType.languages);
        openModalUniversal();
        return;
      case ModalUniversalContentType.currency:
        setOptionsTitle(t('storeSetupPage.currency'));
        setOptions(currencies);
        setModalUniversalContentType(ModalUniversalContentType.currency);
        openModalUniversal();
        return;
      case ModalUniversalContentType.storeFocus:
        setOptionsTitle(t('storeSetupPage.applicationTopic'));
        setOptions(appFocuses);
        setModalUniversalContentType(ModalUniversalContentType.storeFocus);
        openModalUniversal();
        return;
      case ModalUniversalContentType.color:
        navigate('/select-color');
        return;
      case ModalUniversalContentType.usersSupport:
        setOptionsTitle(t('storeSetupPage.customerSupport'));
        setModalUniversalContentType(ModalUniversalContentType.usersSupport);
        openModalUniversal();
        return;
      case ModalUniversalContentType.welcomeMessage:
        setOptionsTitle(t('storeSetupPage.welcomeMessage'));
        setModalUniversalContentType(ModalUniversalContentType.welcomeMessage);
        openModalUniversal();
        return;
      case ModalUniversalContentType.manageAdministrators:
        navigate(RoutePath.Administrators);
        return;
      case ModalUniversalContentType.maintenanceEnabled:
        setOptionsTitle(t('storeSetupPage.disablingTheStore'));
        setModalUniversalContentType(ModalUniversalContentType.maintenanceEnabled);
        openModalUniversal();
        return;
      default:
        setOptionsTitle('');
        setOptions([]);
        return;
    }
  };

  const changeStoreFocus = (storeFocus: string) => {
    dispatch(
      setAppFocus({
        case: storeFocus as AppFocusCases,
        value: 0
      }),
    );
    const wizardStateRequest = WizardTranslator.toWizardSaveStateRequest(
      // 'end',
      { ...wizardData, appFocus: {
        case: storeFocus as AppFocusCases,
        value: 0
      }},
      true,
    );

    dispatch(wizardSaveState({ requestData: wizardStateRequest }));
    closeModalUniversal();
  };

  const connectToSupport = () => {
    Telegram.webApp.openTelegramLink('https://t.me/TeleportHelpBot');
  };

  const getColorName = useColorName()

  useEffect(() => {
    if (saveNetworkStatus === NetworkStatus.Done) {
      setTimeout(() => {
        if (!noShowModalWarningAsyncChanges) {
          openAsyncModal();
        }
      }, 1500);
      dispatch(resetNetworkStatus('saveNetworkStatus'));
      closeModalUniversal();
    }
    if (saveNetworkStatus === NetworkStatus.Failed) {
      dispatch(openErrorModal({
        errorText: showBackendErrors ? wizardSaveStateError : ''
      }))
    }
  }, [showBackendErrors, saveNetworkStatus, wizardSaveStateError, noShowModalWarningAsyncChanges, closeModalUniversal, dispatch, openAsyncModal]);

  useEffect(() => {
    if (multipleStoreEnabled && getStoreListNetworkStatus === NetworkStatus.None) {
      dispatch(getStoreList())
    }
    if (getStoreListNetworkStatus === NetworkStatus.Failed) {
      dispatch(openErrorModal({
        errorTitle: t('storeSetupPage.failedToGetStoresInformation')
      }))
    }
  }, [t, dispatch, multipleStoreEnabled, getStoreListNetworkStatus]);

  const currentCurrency = useMemo(() => {
    const filteredCurrency = currencies.filter(el => `${el.value}` === `${wizardData.currency}`)
    if (filteredCurrency[0] && filteredCurrency[0].value) {
      if (filteredCurrency[0].symbol === 'stars') {
        return 'Stars'
      }
      return filteredCurrency[0].subtitle
    } else {
      return ''
    }
  }, [wizardData.currency, currencies])

  const currentLanguage = useMemo(() => {
    const filteredLanguage = languages.filter(el => `${el.value}` === `${wizardData.language}`)
    if (filteredLanguage[0] && filteredLanguage[0].value) {
      return filteredLanguage[0].title
    } else {
      return ''
    }
  }, [wizardData.language, languages])

  const currentAppFocus = useMemo(() => {
    const filteredAppFocus = appFocuses.filter(el => `${el.type}` === `${wizardData.appFocus.case}`)
    if (filteredAppFocus[0] && filteredAppFocus[0].type) {
      return filteredAppFocus[0].title
    } else {
      return ''
    }
  }, [wizardData.appFocus.case, appFocuses])

  return (
    <Layout
      header={
        <header className={`${css.header} header`}>
          <Text
            text={t('storeSetupPage.storeSettings')}
            mod="title"
            fontWeight={800}
            fontSize={20}
            lineHeight={22}
          />
        </header>
      }
      footer={
        <Footer>
          <Button text={t('storeSetupPage.contactSupport')} onClick={connectToSupport} propsStyles={{ width: '100%' }} />
        </Footer>
      }
    >
      <Container>
        <div className={css.content}>

          <div className={css.grid}>
            <ListButton
              id={ModalUniversalContentType.storeСard}
              title={t('storeSetupPage.storeCard')}
              placeholder={t('storeSetupPage.storeNamePhotoAndDescription')}
              onClick={onClickListButton}
              padding="16px"
            />
            <ListButton
              id={ModalUniversalContentType.welcomeMessage}
              title={t('storeSetupPage.welcomeMessage')}
              placeholder={wizardData.welcomeMessage ?? t('storeSetupPage.welcomeMessage')}
              onClick={onClickListButton}
              padding="16px"
            />
            <ListButton
              id={ModalUniversalContentType.usersSupport}
              title={t('storeSetupPage.customerSupport')}
              placeholder={`${wizardData?.supportLink}`}
              onClick={onClickListButton}
              padding="16px"
            />
            <ListButton
              id={ModalUniversalContentType.storeFocus}
              title={t('storeSetupPage.applicationTopic')}
              placeholder={currentAppFocus}
              onClick={onClickListButton}
              padding="16px"
            />
            <ListButton
              id={ModalUniversalContentType.languages}
              title={t('storeSetupPage.language')}
              placeholder={currentLanguage}
              onClick={onClickListButton}
              padding="16px"
            />
            <ListButton
              id={ModalUniversalContentType.currency}
              title={t('storeSetupPage.currency')}
              placeholder={currentCurrency}
              onClick={onClickListButton}
              padding="16px"
            />
            <ListButton
              id={ModalUniversalContentType.color}
              title={t('storeSetupPage.color')}
              placeholder={wizardData.accentColor ? getColorName(wizardData.accentColor) : t('storeSetupPage.color')}
              onClick={onClickListButton}
              padding="16px"
            />
            {administratorsEnabled && userRole === UserRole.OWNER
              && <ListButton
                id={ModalUniversalContentType.manageAdministrators}
                title={t('storeSetupPage.manageAdministrators')}
                placeholder={t('storeSetupPage.owner')}
                onClick={onClickListButton}
                padding="16px"
              />}
            <ListButton
              id={ModalUniversalContentType.maintenanceEnabled}
              title={t('storeSetupPage.disablingTheStore')}
              placeholder={wizardData.maintenanceEnabled ? t('storeSetupPage.storeDisabled') : t('storeSetupPage.storeEnabled')}
              onClick={onClickListButton}
              padding="16px"
            />
          </div>
        </div>
      </Container>
      {renderModalUniversal && (
        <ModalUniversal
          title={optionsTitle}
          active={activeModalUniversal}
          onClose={closeModalUniversal}
          paddingBottom={modalUniversalContentType === ModalUniversalContentType.integrationIntoChannel ? 126 : undefined}
        >
          <>
            <div className={css.grid}>
              {modalUniversalContentType === ModalUniversalContentType.currency &&
                options.map(el => (
                  <Option
                    key={el.value}
                    value={el.value}
                    title={el.title}
                    subtitle={el.subtitle}
                    checked={`${wizardData?.currency}` === `${el.value}`}
                    type="radio"
                    symbol={el.symbol}
                    onChange={onChangeCurrency}
                    soon={el.soon}
                    disabled={el.disabled || saveNetworkStatus === NetworkStatus.Loading}
                  />
                ))}
              {modalUniversalContentType === ModalUniversalContentType.languages &&
                options.map(el => {
                  return (
                    <Option
                      key={el.value}
                      value={el.value}
                      title={el.title}
                      subtitle={el.subtitle}
                      checked={`${wizardData?.language}` === `${el.value}`}
                      type="radio"
                      symbol={el.symbol}
                      onChange={onChangeLanguage}
                      soon={el.soon}
                      disabled={el.disabled || saveNetworkStatus === NetworkStatus.Loading}
                    />
                  )
                })}
              {modalUniversalContentType === ModalUniversalContentType.storeFocus &&
                options.map(el => {
                  return (
                    <Option
                      key={el.id}
                      value={el.type}
                      title={el.title}
                      subtitle={el.text}
                      checked={`${wizardData?.appFocus.case}` === `${el.type}`}
                      type="radio"
                      onChange={changeStoreFocus}
                      disabled={el.disabled || saveNetworkStatus === NetworkStatus.Loading}
                    />
                  )
                })}
            </div>

            {modalUniversalContentType === ModalUniversalContentType.usersSupport && (
              <ClientSupportForm sendRequest={true} closeModal={closeModalUniversal} />
            )}
            {modalUniversalContentType === ModalUniversalContentType.welcomeMessage && <WelcomeMessage />}
            {modalUniversalContentType === ModalUniversalContentType.maintenanceEnabled && (
              <MaintenanceEnabledForm onSubmitCallback={closeModalUniversal} />
            )}
          </>
        </ModalUniversal>
      )}
      {renderAsyncModal && <ModalWarningAsyncChanges onClickNoShowMore={setNoShowModalWarningAsyncChanges} onClose={closeAsyncModal} active={activeAsyncModal} />}
    </Layout>
  );
});
