import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/redux/store';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { PromiseClient } from '@connectrpc/connect/dist/cjs/promise-client';
import { HubClientService } from '@teleport/schemas-protobuf';
import { IPortClientApi } from '../redux/middleware/portClientApi';
import { IPortOwnerApi } from 'src/redux/middleware/portOwnerApi';
import { BillingService } from '@teleport/schemas-protobuf-billing-v1';
import { IPortAdminApi } from 'src/redux/middleware/PortAdminApi';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  extra: {
    hubApi: PromiseClient<typeof HubClientService>;
    billingApi: PromiseClient<typeof BillingService>;
    portClientApi: IPortClientApi;
    portOwnerApi: IPortOwnerApi;
    portAdminApi: IPortAdminApi;
    filesUpload: (files: File[], ttl?: string) => Promise<string[]>;
  };
}>();
