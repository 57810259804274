import { Layout } from '../../components/Layout/Layout';
import Text from '../../components/UI/Text/Text';
import Footer from '../../components/Footer/Footer';
import { Button } from '../../components/UI/Button/Button';
import { generatePath, useNavigate } from 'react-router-dom';
import { RoutePath } from '../../routing/routeConfig';
import { PromoCodesPageRules } from './PromoСodesPage.style';
import { useStyle } from '../../utils/theme/useStyle';
import { FC, memo, useEffect, useMemo } from 'react';
import { PromoCodeCard } from '../../components/PromoCodeCard/PromoCodeCard';
import Container from 'src/components/Container/Container';
import { NoSettings } from 'src/components/NoSettings/NoSettings';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { getPromoCodesList, promoCodesSelector } from '../../redux/slices/promoCodesSlice';
import { NetworkStatus } from '../../utils/network/network.constant';
import { Spinner } from '../../components/UI/Spinner/Spinner';
import { openErrorModal } from '../../redux/slices/modalErrorSlice';
import useBackButton from '../../hooks/useBackButton';

export const PromoCodesPage: FC = memo(function PromoCodesPage(){
  const { css } = useStyle(PromoCodesPageRules);
  const { t } = useTranslation()
  const dispatch = useAppDispatch();
  const { promoCodesListStatus, promoCodes } = useAppSelector(promoCodesSelector);
  const BackButton = useBackButton();
  const navigate = useNavigate();

  useEffect(() => {
    BackButton.onClickCustom(goBack);
    function goBack() {
      navigate(-1);
    }
    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  useEffect(() => {
    if(promoCodesListStatus === NetworkStatus.None) {
      dispatch(getPromoCodesList());
    }
    if (promoCodesListStatus === NetworkStatus.Failed) {
      dispatch(openErrorModal({
        errorTitle: t('promoCodesPage.failedToLoadPromoCodes')
      }));
    }
  }, [t, dispatch, promoCodesListStatus]);

  const content = useMemo(() => {
    if (promoCodesListStatus === NetworkStatus.Loading) {
      return <div className={css.spinnerWrapper}><Spinner/></div>
    } else if (promoCodes.length > 0 ) {
      return (
        <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
          {
            promoCodes.map(promocode => {
              return <PromoCodeCard
                key={promocode.promocode.uuid}
                data={promocode}
              />
            })
          }
        </div>
      )
    } else {
      return (
        <Container>
          <NoSettings
            text={t('promoCodesPage.createPromoCodesForDiscountsForUsers')}
            title={t('promoCodesPage.youHaventCreatedAnyPromoCodesYet')}
            icon="devices-large"
          />
        </Container>
      )
    }
  }, [
    t,
    css,
    promoCodesListStatus,
    promoCodes
  ])

  return (
    <Layout
      header={
        <header className={`${css.header} header`}>
          <Text
            text={t('promoCodesPage.promoCodes')}
            mod="title"
            fontWeight={800}
            fontSize={20}
            lineHeight={22}
          />
        </header>
      }
      footer={
        <Footer>
          <Button
            text={t('promoCodesPage.createPromoCode')}
            propsStyles={{ width: '100%' }}
            href={generatePath(RoutePath.CreatePromoCode)}
          />
        </Footer>
      }
    >
      <div className={css.promoCodesWrapper}>
        {content}
      </div>
    </Layout>
  );
});
