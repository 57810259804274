import { FC, memo, useCallback, useEffect } from 'react';
import { useStyle } from '../../../utils/theme/useStyle';
import Container from 'src/components/Container/Container';
import { wizardEndRules } from './wizardEnd.style';
import { Icon } from 'src/components/UI/Icon/Icon';
import Text from 'src/components/UI/Text/Text';
import { useTheme } from 'src/utils/theme/useTheme';
import Wrapper from 'src/components/Wrapper/Wrapper';
import { ListWithCard } from 'src/components/UI/Lists/ListWithCards/ListWithCards';
import { ListButton } from 'src/components/UI/ListButton/ListButton';
import { useModalState } from 'src/hooks/useModalState';
import { ModalUniversal } from 'src/components/ModalUniversal/ModalUniversal';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { setCurrency, setLanguage, setSupportLink, wizardStateSelector } from 'src/redux/slices/wizardSlice';
import { ClientSupportForm } from 'src/components/ClientSupportForm/ClientSupportForm';
import { userStateSelector } from 'src/redux/slices/userSlice';
import { freeTrialPeriod } from 'src/utils/constants';
import { useTranslation } from '../../../utils/i18n/hooks/useTranslation';
import { useCurrencyData } from '../../../utils/hooks/useCurrencyData';
import { useLanguageData } from '../../../utils/hooks/useLanguageData';
import { Currency, Language } from '@teleport/schemas-protobuf';

export const WizardEnd: FC = memo(function WizardEnd() {
  const {
    wizard: {
      language,
      currency,
      supportLink,
    },
  } = useAppSelector(wizardStateSelector);
  const { userName } = useAppSelector(userStateSelector);
  const { css } = useStyle(wizardEndRules);
  const { theme } = useTheme();
  const { mainColor, background, colorWhite } = theme;
  const [renderModal, activeModal, openModal, closeModal] = useModalState();
  const dispatch = useAppDispatch();

  const { t, tPlural } = useTranslation();

  const currencyOptions = useCurrencyData();
  const languageOptions = useLanguageData();

  useEffect(() => {
    if (!supportLink && userName) {
      dispatch(setSupportLink(`@${userName}`));
    }
  }, [dispatch, userName, supportLink]);

  const onChangeLanguage = useCallback((value: string) => {
    dispatch(setLanguage(Number(value)));
  }, [dispatch]);
  const onChangeCurrency = useCallback((value: string) => {
    dispatch(setCurrency(Number(value)));
  }, [dispatch]);

  useEffect(() => {
    if (language === Language.UNSPECIFIED) {
      onChangeLanguage(String(Language.RU))
    }
    if (currency === Currency.UNSPECIFIED) {
      onChangeCurrency(String(Currency.RUB))
    }
  }, [
    currency,
    language,
    onChangeCurrency,
    onChangeLanguage
  ]);

  return (
    <>
      <div className={css.inner}>
        <Container>
          <div className={css.top}>
            <div className={css.iconWrapper}>
              <Icon name="guard" properties={{ fill: mainColor, className: css.guard }}/>
              <Icon name="check" properties={{ fill: mainColor, className: css.check }}/>
            </div>
            <Text text={t('wizardStepEnd.almostThere')} mod="title" fontSize={24} lineHeight={24}/>
          </div>
        </Container>
        <Wrapper
          propsStyles={{
            backgroundColor: colorWhite,
            borderRadius: '10px 10px 0 0',
            padding: '16px 16px 0 16px',
            flexGrow: '1'
          }}
        >
          <div className={css.cardWrapper}>
            <div className={css.card}>
              <Text
                mod="text"
                text={t('wizardStepEnd.freeTrialPeriod')}
                fontSize={16}
                fontWeight={700}
                lineHeight={18}
              />
              <Text
                text={tPlural('wizardStepEnd.countDays', freeTrialPeriod)}
                mod="text"
                fontSize={16}
                fontWeight={700}
                lineHeight={18}
                extend={css.cardAdditionalText}
              />
            </div>
            <ListWithCard
              options={currencyOptions}
              placeholder={t('wizardStepEnd.chooseCurrency')}
              title={t('wizardStepEnd.currency')}
              modalTitle={t('wizardStepEnd.currency')}
              initialValue={currency !== Currency.UNSPECIFIED ? String(currency) : String(Currency.RUB)}
              onChange={onChangeCurrency}
              propsStyles={{ backgroundColor: background }}
            />
            <ListWithCard
              options={languageOptions}
              placeholder={t('wizardStepEnd.chooseLanguage')}
              title={t('wizardStepEnd.language')}
              modalTitle={t('wizardStepEnd.language')}
              initialValue={language !== Language.UNSPECIFIED ? String(language) : String(Language.RU)}
              onChange={onChangeLanguage}
              propsStyles={{ backgroundColor: background }}
            />
            <ListButton
              id={'client-support'}
              title={t('wizardStepEnd.customerSupport')}
              placeholder={`${supportLink ? supportLink : userName ? `@${userName}` : ''}`}
              bg={theme.background}
              onClick={openModal}
              padding="16px"
            />
          </div>
          <Text
            mod="text"
            text={t('wizardStepEnd.changeSettingsAndFillStoreWithDataThoughtThisAppAtAnyTime')}
            fontSize={14}
            fontWeight={600}
            lineHeight={16}
            extend={css.info}
          />
        </Wrapper>
      </div>
      {renderModal && (
        <ModalUniversal
          active={activeModal}
          title={t('wizardStepEnd.customerSupport')}
          onClose={closeModal}
        >
          <ClientSupportForm sendRequest={false} closeModal={closeModal}/>
        </ModalUniversal>
      )}
    </>
  );
});
