import { IRuleFn } from '../../utils/theme/fela.interface';
import { padding } from '../../utils/applyCSSProperty ';
import { createCachedStyle } from '../../utils/theme/createCachedStyle';

export interface IUserCardRuleKeys {
  card: {};
  imgWrapper: {};
  image: {};
  textInfo: {};
  role: {};
  username: {};
  noImage: {};
}

export interface IUserCardRuleProps {
  backgroundColor: string
}

export type IUserCardRules = IRuleFn<IUserCardRuleKeys, IUserCardRuleProps>;

const userCardRulesFn: IUserCardRules = props => {
  const { colorWhite, colorDarkGrey, colorGrayLight } = props.theme;
  const { backgroundColor } = props;

  return {
    card: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      columnGap: 10,
      ...padding(10, 12),
      background: backgroundColor ?? colorWhite,
      borderRadius: 12,
    },
    imgWrapper: {
      width: 38,
      height: 38,
      flexShrink: 0,
      borderRadius: '50%',
      overflow: 'hidden',
    },
    image: {
      width: '100%',
      height: '100%',
    },
    noImage: {
      width: '100%',
      height: '100%',
      backgroundColor: colorGrayLight,
      opacity: .5,
    },
    username: {
      fontSize: 14,
      lineHeight: '16px',
      fontWeight: 600,
      letterSpacing: '-0.4px',
      marginBottom: 2,
      display: '-webkit-box',
      width: '100%',
      WebkitLineClamp: 1,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    textInfo: {
      flexGrow: 1,
    },
    role: {
      flexShrink: 0,
      fontSize: 12,
      lineHeight: '14px',
      fontWeight: 400,
      letterSpacing: '-0.4px',
      color: colorDarkGrey,
    }
  };
};

export const userCardRules = createCachedStyle(userCardRulesFn)
