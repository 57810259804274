import {IUser} from '../slices/administratorsSlice';
import {Client} from '@teleport/schemas-protobuf';

export const adminTranslator = {
  fromAdminList(clients: Client[]): IUser[] {
    return clients.map(el => (
      {
        username: new RegExp('^@').test(el.username) ? el.username : `@${el.username}`,
        fullName: el.fullName,
        photo: el.photo,
        role: el.role,
        identity: {
          tgId: Number(el.identity.tgId),
          uuid: el.identity.uuid
        }
      }
    ))
  }
};
