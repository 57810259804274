import {
  ClientCatalogProductListRequest,
  ClientCatalogProductListResponse,
  Filter,
  ProductToSelectionBindRequest,
  SelectionBlock,
  SelectionListResponse,
  Sorting,
} from '@teleport/schemas-protobuf';
import { IProduct } from 'src/types/product';
import { ISelection } from '../slices/selectionsSlice';

export const SelectionsTranslator = {
  fromSelectionListResponse(response: SelectionListResponse) {
    const { selections } = response;

    return selections;
  },

  toSelectionProductList(selectionUuid) {
    const filter = new Filter({
      filterDefaultState: { case: 'selectionFilter', value: { selectionUuid } },
    });
    const requestData = new ClientCatalogProductListRequest({
      filters: [filter],
      sorting: Sorting.UNSPECIFIED,
    });
    return requestData;
    // filters: [{ filterDefaultState: { case: 'selectionFilter', value: { selectionUuid: '1' } } }],
  },

  fromSelectionProductList(productList: ClientCatalogProductListResponse) {
    const selectionProducts: IProduct[] = productList.products.map(el => ({
      id: el.uuid,
      imageUrls: el.previewImageUrls,
      price: {
        amount: Number(el.price.amount),
        discountEnabled: el.price.discountEnabled,
        discountInPercent: Number(el.price.discountInPercent),
        oldAmount: Number(el.price.oldAmount),
      },
      productName: el.title,
      enabled: true,
    }));
    return selectionProducts;
  },

  toProductToSelectionRequest({
    selection,
    productUuids,
  }: {
    selection: ISelection;
    productUuids: string[];
  }) {
    const selectionEntity = new SelectionBlock({
      title: selection.title,
      uuid: selection.uuid,
      visibility: selection.visibility,
    });

    const requestData = new ProductToSelectionBindRequest({
      selection: selectionEntity,
      productUuids,
    });

    return requestData;
  },
};
