import {memo, useRef} from 'react';
import Modal, { ModalNames } from '../Modal/Modal';
import { Button } from '../UI/Button/Button';
import { useStyle } from '../../utils/theme/useStyle';
import { modalConfirmAdminRules } from './ModalConfirmAdmin.style';
import Text from '../UI/Text/Text';
import { ImageComponent } from '../ImageComponent/ImageComponent';
import { useTranslation } from '../../utils/i18n/hooks/useTranslation';
import {IUser} from '../../redux/slices/administratorsSlice';

interface IProps {
  active: boolean;
  onClose: () => void;
  confirmFn: (value: boolean) => void;
  adminData: IUser;
  isDeletion?: boolean;
}

export const ModalConfirmAdmin = memo(function ModalConfirmAdmin(props: IProps) {
  const {active, isDeletion, adminData, confirmFn, onClose} = props;
  const modalFooterRef = useRef<HTMLDivElement>(null);
  const modalInnerRef = useRef<HTMLDivElement>(null);
  const { css } = useStyle(modalConfirmAdminRules, {
    footerHeight: modalFooterRef.current?.clientHeight,
    isDeletion
  });
  const { t } = useTranslation();

  const accept = () => {
    confirmFn(true);
  }

  const reject = () => {
    confirmFn(false);
    onClose();
  }

  return (
    <Modal
      active={active}
      name={ModalNames.Color}
      onClose={onClose}
      propsStyles={{paddingLeft: 0, paddingRight: 0, paddingBottom: 0}}
    >
      <div className={css.modalInner} ref={modalInnerRef}>
        <div className={css.info}>
          <div className={css.images}>
            {!isDeletion
              && (
                <ImageComponent
                  className={css.image}
                  alt="store-avatar"
                  src='images/revolt.jpg'
                  fallback={<div className={css.noImage} />}
                />
              )}
            <ImageComponent
              className={`${css.image} ${css.adminAvatar}`}
              alt="admin-avatar"
              src={adminData.photo}
              fallback={<div className={css.noImage} />}
            />
          </div>
          <Text
            mod='text'
            text={isDeletion ? t('modalConfirmAdmin.administratorRemoval') : t('modalConfirmAdmin.youWillLoseStoreOwnershipRights')}
            fontWeight={800}
            fontSize={20}
            lineHeight={26}
            extend={css.title}
          />
          <Text
            mod='text'
            text={
              isDeletion
                ? t('modalConfirmAdmin.afterRemovingTheAdministratorTheUserWillLoseTheAbilityToEditTheStoreTheyCanBeReassignedAsAnAdministratorInTheStoreSettings')
                : t('modalConfirmAdmin.afterConfirmingTheOwnershipTransferYouWillNotBeAbleToAssignOtherAdministratorsOrDeleteTheStoreThisActionCannotBeUndone')
            }
            fontWeight={500}
            fontSize={14}
            lineHeight={18}
          />
        </div>
        <Button
          text={isDeletion ? t('modalConfirmAdmin.doNotRemove') : t('modalConfirmAdmin.doNotTransferOwnership')}
          propsStyles={{
            width: '100%',
          }}
          onClick={reject}
        />
        <div className={css.footer} ref={modalFooterRef}>
          <Button
            text={isDeletion ? t('modalConfirmAdmin.removeAdministrator') : t('modalConfirmAdmin.transferOwnership', adminData.username)}
            propsStyles={{
              width: '100%',
            }}
            onClick={accept}
          />
        </div>
      </div>
    </Modal>
  );
});

export default ModalConfirmAdmin;
