import { ChannelIntegrationUpsertRequest } from '@teleport/schemas-protobuf';
import { IIntegrationData } from '../slices/integrationSlice';

export const IntegrationTranslator = {
  toChannelIntegrationRequest(data: IIntegrationData) {
    return  new ChannelIntegrationUpsertRequest({
      integration: {
        channelLink: data.channelLink,
        messageText: data.messageText,
        buttonText: data.buttonText,
      }
    })
  },
};
