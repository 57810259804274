import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useStyle } from '../../utils/theme/useStyle';
import { settingBlockOrderRules } from '../SettingBlocksOrder/SettingBlocksOrder.style';
import { useTranslation } from '../../utils/i18n/hooks/useTranslation';
import { Layout } from '../../components/Layout/Layout';
import Text from '../../components/UI/Text/Text';
import { NewsLetterForm } from '../../components/NewsLetterForm/NewsLetterForm';
import useLoader from '../../hooks/useLoader';
import useBackButton from '../../hooks/useBackButton';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { newslettersSelector } from '../../redux/slices/newslettersSlice';
import { NetworkStatus } from '../../utils/network/network.constant';
import { openErrorModal } from '../../redux/slices/modalErrorSlice';
import { useScrollViewport } from '../../hooks/useScrollViewport';

export const CreateNewsLetterPage: FC = memo(function SettingsBlockOrder(props) {
  const {} = props;
  const { hideLoader } = useLoader();
  const { css } = useStyle(settingBlockOrderRules);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const BackButton = useBackButton();
  const dispatch = useAppDispatch();
  const { createNewsletterNetworkStatus } = useAppSelector(newslettersSelector)
  const [layoutEl, setLayoutEl] = useState({ current: null });
  const layoutRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setLayoutEl({ current: node });
    }
  }, []);

  useScrollViewport({scrollElementRef: layoutEl});

  useEffect(() => {
    if (createNewsletterNetworkStatus === NetworkStatus.Failed) {
      dispatch(openErrorModal({
        errorTitle: t('createNewsLetterPage.failedToSendTheNewsletter')
      }))
    }
  }, [createNewsletterNetworkStatus, dispatch, t]);

  useEffect(() => {
    function goBack() {
      navigate(-1);
    }
    BackButton.onClickCustom(goBack);

    return () => {
      BackButton.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  useEffect(() => {
    hideLoader();
  }, [hideLoader]);

  return (
    <Layout
      ref={layoutRef}
      header={
        <header className={`${css.header} header`}>
          <Text
            text={t('createNewsLetterPage.newsletter')}
            mod="title"
            fontWeight={800}
            fontSize={20}
            lineHeight={22}
          />
        </header>
      }
    >
      <NewsLetterForm />
    </Layout>
  );
});
