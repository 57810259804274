import {
  SelectionBlock,
  SliderBlock, SliderBlock_SliderItem,
  StatusResponse,
  StoriesBlock_ClientStory, Story,
  TextBlock,
  TextBlock_Button,
  UIBlock
} from '@teleport/schemas-protobuf';
import {BlockType, IUiBlock} from '../slices/uiBlocksSlice';
import {SelectionBlock_Visibility, StoriesBlock} from '@teleport/schemas-protobuf/port/v1/port_main_page_pb';

export const UiBlocksTranslator = {
  fromRootUiBlocksListResponse(response: StatusResponse): {
    order: string[];
    blocks: Record<string, IUiBlock>
  } {

    const blocks: Record<string, IUiBlock> = {};
    const order = [];

    response.mainPageBlocks.forEach(block => {
      switch (block.blockData.case) {
        case 'textBlock':
          blocks[block.blockData.value.uuid] = {
            uuid: block.blockData.value.uuid,
            title: block.blockData.value.title,
            text: block.blockData.value.text,
            accentColor: block.blockData.value.accentColor,
            button: block.blockData.value.button,
            type: BlockType.TextBlock
          }
          order.push(block.blockData.value.uuid);
          return;
        case 'storiesBlock':
          blocks[BlockType.StoriesBlock] = {
            uuid: BlockType.StoriesBlock,
            items: block.blockData.value.items,
            type: BlockType.StoriesBlock
          }
          order.push(BlockType.StoriesBlock);
          return;
        case 'sliderBlock':
          blocks[block.blockData.value.uuid] = {
            uuid: block.blockData.value.uuid,
            items: block.blockData.value.items,
            type: BlockType.SliderBlock
          }
          order.push(block.blockData.value.uuid);
          return;
        case 'selectionBlock':
          blocks[block.blockData.value.uuid] = {
            uuid: block.blockData.value.uuid,
            title: block.blockData.value.title,
            visibility: block.blockData.value.visibility,
            enabled: block.blockData.value.enabled,
            type: BlockType.SelectionBlock
          }
          order.push(block.blockData.value.uuid);
          return;
        default:
          console.warn('unknown blockData case');
      }
    })

    if (order.length === 0 && Object.keys(blocks).length === 0) {
      order.push(BlockType.StoriesBlock);
      blocks[BlockType.StoriesBlock] = {
        uuid: BlockType.StoriesBlock,
        items: [],
        type: BlockType.StoriesBlock,
      }
    }

    return {
      order,
      blocks,
    };
  },

  toUIBlockReorderRequest(blocks: Record<string, IUiBlock>, order: string[]): {mainPageBlocks: UIBlock[]} {
    const result: {mainPageBlocks: UIBlock[]} = {mainPageBlocks: []};
    order.forEach(orderItem => {
      const el = blocks[orderItem];
      switch (el.type) {
        case BlockType.TextBlock:
          result.mainPageBlocks.push(new UIBlock(
            {
              blockData: {
                value: new TextBlock({
                  uuid: el.uuid,
                  title: el.title,
                  text: el.text,
                  accentColor: el.accentColor,
                  button: el.button.enabled
                    ? new TextBlock_Button({
                      enabled: el.button.enabled,
                      text: el.button.text,
                      clickUrl: el.button.clickUrl,
                    })
                    : undefined,
                }),
                case: BlockType.TextBlock,
              }
            }
          ));
          break;
        case BlockType.StoriesBlock:
          result.mainPageBlocks.push(new UIBlock(
            {
              blockData: {
                value: new StoriesBlock({
                  items: el.items.map(item => (new StoriesBlock_ClientStory({
                    story: new Story({
                      uuid: item.story.uuid,
                      text: item.story.text,
                      imageUrl: item.story.imageUrl,
                      clickUrl: item.story.clickUrl,
                    }),
                    viewed: item.viewed,
                  })))
                }),
                case: BlockType.StoriesBlock,
              }
            }
          ));
          break;
        case BlockType.SliderBlock:
          result.mainPageBlocks.push(new UIBlock(
            {
              blockData: {
                value: new SliderBlock({
                  uuid: el.uuid,
                  items: el.items.map(el => (new SliderBlock_SliderItem({
                    imageUrl: el.imageUrl,
                    clickUrl: el.clickUrl
                  })))
                }),
                case: BlockType.SliderBlock,
              }
            }
          ));
          break;
        case BlockType.SelectionBlock:
          result.mainPageBlocks.push(new UIBlock(
            {
              blockData: {
                value: new SelectionBlock({
                  title: el.title,
                  uuid: el.uuid,
                  visibility: el.visibility
                    ? new SelectionBlock_Visibility({
                      showOnMain: el.visibility.showOnMain,
                      showInRecommendations: el.visibility.showInRecommendations,
                    })
                    : undefined,
                  enabled: el.enabled,
                }),
                case: BlockType.SelectionBlock,
              }
            }
          ));
          break;
        default:
          console.warn('unknown blockData case');
      }
    })
    return result;
  }
};
