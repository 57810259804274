import { SelectionsTranslator } from 'src/redux/translators/selectionsTranslator';
import { createAppAsyncThunk } from '../../../hooks/redux';
import { ISelection } from '../../slices/selectionsSlice';

export const productToSelectionBind = createAppAsyncThunk(
  'selections/productToSelection',
  async (
    { selection, productUuids }: { selection: ISelection; productUuids: string[] },
    thunkAPI,
  ) => {
    const requestData = SelectionsTranslator.toProductToSelectionRequest({
      selection,
      productUuids,
    });

    return await thunkAPI.extra.portOwnerApi().productToSelectionBind(requestData);
  },
);
