import { NamingStep, WizardSaveStateRequest } from '@teleport/schemas-protobuf';
import { createAppAsyncThunk } from '../../../hooks/redux';
import { BotStep } from '@teleport/schemas-protobuf/hub/v1/hub_wizard_models_pb';
import { openErrorModal } from '../../slices/modalErrorSlice';

export const wizardSaveState = createAppAsyncThunk(
  'wizard/wizardSaveState',
  async (
    {
      requestData,
      shopLogoFile,
      welcomeImageFile,
      noNextStep,
    }: { requestData: WizardSaveStateRequest; shopLogoFile?: File; welcomeImageFile?: File, noNextStep?: boolean },
    thunkAPI,
  ) => {

    const newRequestData = new WizardSaveStateRequest({
      state: {
        ...requestData.state,
        step1: new NamingStep({
          logo: requestData.state.step1.logo,
          name: requestData.state.step1.name,
          description: requestData.state.step1.description,
          complete: requestData.state.step1.complete,
        }),
        step9: requestData.state.step9
          ? new BotStep({
            welcomeImage: requestData.state.step9.welcomeImage,
            welcomeMessage: requestData.state.step9.welcomeMessage,
            personalBotToken: requestData.state.step9.personalBotToken,
            complete: requestData.state.step9.complete,
          })
          : undefined
      },
      commit: requestData.commit
    });

    if (shopLogoFile) {
      try {
        const imageUrls = await thunkAPI.extra.filesUpload([shopLogoFile]);
        const shopLogoUrl = imageUrls[0];
        newRequestData.state.step1.logo = shopLogoUrl;
      } catch (e) {
        console.info('Upload files failed: ', e);
        thunkAPI.dispatch(openErrorModal({
          errorTitle: 'Logo upload error',
          errorText: 'The logo could not be uploaded, please try again',
        }))
      }
    }
    if (welcomeImageFile) {
      try {
        const imageUrls = await thunkAPI.extra.filesUpload([welcomeImageFile]);
        const welcomeImageUrl = imageUrls[0];
        newRequestData.state.step9.welcomeImage = welcomeImageUrl;
      } catch (e) {
        console.info('Upload files failed: ', e);
        thunkAPI.dispatch(openErrorModal({
          errorTitle: 'Image upload error',
          errorText: 'The image could not be uploaded, please try again',
        }))
      }
    }

    const result = await thunkAPI.extra.hubApi.wizardSaveState(newRequestData);

    return { wizardData: newRequestData.state, committed: result.committed, noNextStep };
  },
);
