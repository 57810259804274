import {
  Category, Filter,
  ProductToCategoryBindRequest,
  RootCategory,
  RootCategoryListResponse, SubCategory,
} from '@teleport/schemas-protobuf';
import { ICategory } from '../slices/categorySlice';

export const CategoryTranslator = {
  fromRootCategoryListResponse(response: RootCategoryListResponse) {
    const { rootCategories } = response;

    return rootCategories.map(category => {
      const { uuid, title } = category;

      return {
        uuid: uuid,
        title,
      };
    });
  },

  //Add products to category
  toProductToCategoryRequest({
    category,
    productUuids,
  }: {
    category: ICategory;
    productUuids: string[];
  }) {
    const categoryValue = new RootCategory({
      uuid: `${category.uuid}`,
      title: category.title,
    });

    const categoryEntity = new Category({
      type: {
        case: 'rootCategory',
        value: categoryValue,
      },
    });

    const requestData = new ProductToCategoryBindRequest({
      category: categoryEntity,
      productUuids,
    });

    return requestData;
  },

  toGetProductsByCategory(categoryId: string, categoryTitle?: string) {
    const category = new Category({
      type: {
        value: {
          uuid: categoryId,
          title:  categoryTitle ? categoryTitle : 'test',
        },
        case: 'rootCategory',
      },
    });

    const filter = new Filter({
      filterDefaultState: { case: 'categoryFilter', value: { category: category } },
    });

    return [filter];
  },

  toRootCategory(data: { uuid: string, title: string }) {
    return new RootCategory({ uuid: data.uuid, title: data.title })
  },

  toSubCategory(data: {
    categoryUuid: string,
    uuid: string,
    title: string,
  }) {
    return  new SubCategory({
      categoryUuid: data.categoryUuid,
      uuid: data.uuid,
      title: data.title,
    })
  }
};
