import { OwnerPromocode, Promocode_PercentImpact, Promocode_PriceImpact, PromocodeListResponse, PromocodeUpsertRequest } from '@teleport/schemas-protobuf';
import { IOwnerPromocode, TypeOfDiscount } from '../slices/promoCodesSlice';
import { IPriceImpact } from 'src/types/impactType';

function getTypeOfDiscountFromEnum(impact: string) {
  switch(impact){
    case TypeOfDiscount.PercentImpact:
      return TypeOfDiscount.PercentImpact;
    case TypeOfDiscount.PriceImpact:
      return TypeOfDiscount.PriceImpact;
    default: return TypeOfDiscount.Unspecified
  }
}


export const PromocodeTranslator = {
  toOwnerPromocodeForRequest(ownerPromocode?: IOwnerPromocode) {
    const formImpactForOwnerPromoCode = (amount: number, type: TypeOfDiscount):IPriceImpact => {
      
      if (type === TypeOfDiscount.PercentImpact) {
        return {
          value: new Promocode_PercentImpact({value: amount}),
          case: TypeOfDiscount.PercentImpact
        }
      }else if (type === TypeOfDiscount.PriceImpact) {
        return {
          value: new Promocode_PriceImpact({value: BigInt(amount)}),
          case: TypeOfDiscount.PriceImpact
        }
      }else {
        return { case: undefined, value: undefined }
      }
    }
    const promocode = new OwnerPromocode({
      promocode: {
        uuid: ownerPromocode.promocode.uuid,
        codename: ownerPromocode.promocode.codename,
        impact: formImpactForOwnerPromoCode(ownerPromocode.promocode.impact.value, ownerPromocode.promocode.impact.case),
      },
      constraintSet: {
        minimalPriceOrder: ownerPromocode.constraintSet.minimalPriceOrder? BigInt(ownerPromocode.constraintSet.minimalPriceOrder) : BigInt(0),
        uniquePerUser:  ownerPromocode.constraintSet.uniquePerUser,
        firstOrder:  ownerPromocode.constraintSet.firstOrder,
      },
      enabled: ownerPromocode.enabled,
    }) 
    const ownerPromocodeForRequest = new PromocodeUpsertRequest({promocode: promocode})
    return  ownerPromocodeForRequest
  },
  formOwnerPromocodeForResponse(ownerPromocodes: PromocodeListResponse) {
    const ownerPromocodesList:IOwnerPromocode[]   =  ownerPromocodes.promocodes.map(promocode => {
      const resultPromocode: IOwnerPromocode = 
        {
          promocode: {
            uuid: promocode.promocode.uuid,
            codename:  promocode.promocode.codename,
            impact: {
              value: promocode.promocode.impact.value ? Number(promocode.promocode.impact.value.value) : undefined,
              case: promocode.promocode.impact.case ?  getTypeOfDiscountFromEnum(promocode.promocode.impact.case) : undefined,
            },
          },
          enabled: promocode.enabled,
          constraintSet: {
            minimalPriceOrder: promocode.constraintSet.minimalPriceOrder ? Number(promocode.constraintSet.minimalPriceOrder) : undefined,
            uniquePerUser:  promocode.constraintSet.uniquePerUser,
            firstOrder:  promocode.constraintSet.firstOrder,
          },
        }
      return resultPromocode
    })
    return ownerPromocodesList
  },
}
