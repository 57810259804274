import { NetworkStatus } from '../../utils/network/network.constant';
import { createAppAsyncThunk } from '../../hooks/redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { PromocodeTranslator } from '../translators/promocodeTranslator';

interface IPromoCodesSlice {
  promoCodes: IOwnerPromocode[];
  promoCodesListStatus: NetworkStatus;
  upsertPromoCodeStatus: NetworkStatus;
  promoCodeDeleteStatus: NetworkStatus;
}

export enum TypeOfDiscount {
  Unspecified = '',
  PriceImpact = 'priceImpact',
  PercentImpact = 'percentImpact',
}

export interface IOwnerPromocodeConstraintSet {
  minimalPriceOrder: number;
  uniquePerUser: boolean;
  firstOrder: boolean;
}


export interface IImpact {
  value: number | undefined,
  case: TypeOfDiscount; 
}

export interface IPromocode {
    uuid: string;
    codename: string;
    impact: IImpact
}
export interface IOwnerPromocode {
    promocode?: IPromocode;
    enabled: boolean;
    constraintSet?: IOwnerPromocodeConstraintSet;
}

export const promoCodeUpsert = createAppAsyncThunk(
  'PromoCodes/promoCodeUpsert',
  async (data: IOwnerPromocode, thunkAPI): Promise<IOwnerPromocode> => { 
    await thunkAPI.extra.portOwnerApi().promocodeUpsert(PromocodeTranslator.toOwnerPromocodeForRequest(data))
    return data
  },
);

export const getPromoCodesList = createAppAsyncThunk(
  'PromoCodes/getPromoCodesList',
  async (_, thunkAPI) => {
    const result = await thunkAPI.extra.portOwnerApi().promocodeList({})
    return PromocodeTranslator.formOwnerPromocodeForResponse(result)
  },
);

export const promoCodeDelete = createAppAsyncThunk(
  'PromoCodes/promoCodeDelete',
  async (promocodeUuid: string, thunkAPI) => {
    await thunkAPI.extra.portOwnerApi().promocodeDelete({promocodeUuid});
    return promocodeUuid;
  },
);


const initialState: IPromoCodesSlice  = {
  promoCodes: [],
  promoCodesListStatus: NetworkStatus.None,
  upsertPromoCodeStatus: NetworkStatus.None,
  promoCodeDeleteStatus: NetworkStatus.None,
};

export const promoCodesSlice = createSlice({
  name: 'PromoCodes',
  initialState,
  reducers: {
    resetNetworkStatus: (
      state,
      { payload }: PayloadAction<'promoCodesListStatus' | 'upsertPromoCodeStatus' | 'promoCodeDeleteStatus'>,
    ) => {
      state[payload] = NetworkStatus.None;
    },
  },
  extraReducers: builder => {
    builder.addCase(getPromoCodesList.pending, state => {
      state.promoCodesListStatus = NetworkStatus.Loading;
    });
    builder.addCase(getPromoCodesList.fulfilled,(state, action) => {
      state.promoCodes = action.payload;
      state.promoCodesListStatus = NetworkStatus.Done;
    });
    builder.addCase(getPromoCodesList.rejected, state => {
      state.promoCodesListStatus = NetworkStatus.Failed;
    });

    builder.addCase(promoCodeUpsert.pending, state => {
      state.upsertPromoCodeStatus = NetworkStatus.Loading;
    });
    builder.addCase(promoCodeUpsert.fulfilled, (state, action) => {
      const updatedPromoCodeData = action.payload;
      const promoCode = state.promoCodes.find(el => el.promocode.uuid === updatedPromoCodeData.promocode.uuid);
      if (promoCode) {
        const index = state.promoCodes.indexOf(promoCode);
        const newState = [...state.promoCodes];
        newState.splice(index, 1, updatedPromoCodeData)
        state.promoCodes = newState
      } else {
        state.promoCodes = [action.payload, ...state.promoCodes];
      }
      state.upsertPromoCodeStatus = NetworkStatus.Done;
    });
    builder.addCase(promoCodeUpsert.rejected, state => {
      state.upsertPromoCodeStatus = NetworkStatus.Failed;
    });

    builder.addCase(promoCodeDelete.pending, state => {
      state.promoCodeDeleteStatus = NetworkStatus.Loading;
    });
    builder.addCase(promoCodeDelete.fulfilled,(state, action) => {
      const promoCodeUuid = action.payload;
      state.promoCodes = state.promoCodes.filter(el => el.promocode.uuid !== promoCodeUuid)
      state.promoCodeDeleteStatus = NetworkStatus.Done;
    });
    builder.addCase(promoCodeDelete.rejected, state => {
      state.promoCodeDeleteStatus = NetworkStatus.Failed;
    });
  },
});

export const { resetNetworkStatus } = promoCodesSlice.actions;
export const promoCodesSelector = (state: RootState) => state[promoCodesSlice.name];
