import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { createAppAsyncThunk } from '../../hooks/redux';
import { NetworkStatus } from '../../utils/network/network.constant';
import { ModifiersTranslator } from '../translators/modifiersTranslator';

interface IInitialState {
  showModifiers: boolean;
  modifiers: IModifier[];
  networkStatus: NetworkStatus;
  networkUpsertStatus: NetworkStatus;
  networkDeleteStatus: NetworkStatus;
  errorMessageUpsert?: string;
}
export  enum IModifierType {
  UNSPECIFIED = 0,
  TOGGLE = 1,
  CHIPS = 2,
  LIST = 3,
}


export interface IModifierVariant  {
  uuid: string;
  title: string;
}
export interface IModifier  {
    uuid: string,
    uiType: IModifierType,
    title: string,
    descriptionLink: string,
    affectsPrice: boolean,
    variants: IModifierVariant[],
}

export const getModifiersList = createAppAsyncThunk(
  'modifiers/getModifiersList',
  async (_, thunkAPI) => {
    const result = await thunkAPI.extra.portClientApi().modifierList({});
    return ModifiersTranslator.formModifierForResponse(result)
  },
);

export const createUpdateModifier = createAppAsyncThunk(
  'modifiers/createUpdateModifier',
  async (data: IModifier, thunkAPI): Promise<IModifier> => {
    const modifierForRequest = ModifiersTranslator.formModifierForRequest(data)
    const result = await thunkAPI.extra.portOwnerApi().modifierUpsert(modifierForRequest);
    return {
      ...data,
      uuid: result.modifierUuid,
    }
  },
);

export const deleteModifier = createAppAsyncThunk(
  'modifiers/deleteModifier',
  async (uuid: string, thunkAPI) => {
    await thunkAPI.extra.portOwnerApi().modifierDelete({modifierUuid: uuid})
    return uuid
  }
)

// products services courses events
const initialState: IInitialState = {
  showModifiers: false,
  modifiers: [],
  networkStatus: NetworkStatus.None,
  networkUpsertStatus: NetworkStatus.None,
  networkDeleteStatus: NetworkStatus.None,
  errorMessageUpsert: undefined
};
export const modifiersSlice = createSlice({
  name: 'Modifiers',
  initialState,
  reducers: {
    setShowModifiers: (state, action: PayloadAction<boolean>) => {
      state.showModifiers = action.payload;
    },
    clearErrorMessage: state => {
      state.errorMessageUpsert = undefined
    },
    resetNetworkStatus: (
      state,
      { payload }: PayloadAction<'networkStatus' | 'networkUpsertStatus' | 'networkDeleteStatus'>,
    ) => {
      state[payload] = NetworkStatus.None;
    },
  },
  extraReducers: builder => {
    builder.addCase(getModifiersList.pending, state => {
      state.networkStatus = NetworkStatus.Loading;
    });
    builder.addCase(getModifiersList.rejected, state => {
      state.networkStatus = NetworkStatus.Failed;
    });
    builder.addCase(getModifiersList.fulfilled, (state, action) => {
      state.networkStatus = NetworkStatus.Done;
      state.modifiers = action.payload;
    });
    builder.addCase(createUpdateModifier.pending, state => {
      state.errorMessageUpsert = undefined
      state.networkUpsertStatus = NetworkStatus.Loading;
    });
    builder.addCase(createUpdateModifier.rejected, (state, action) => {
      const errorMessage = action.error.message
      if (errorMessage) state.errorMessageUpsert = errorMessage
      state.networkUpsertStatus = NetworkStatus.Failed;
    });
    builder.addCase(createUpdateModifier.fulfilled, (state, action) => {
      state.networkUpsertStatus = NetworkStatus.Done;
      const modifier = action.payload;
      const modifierExists = state.modifiers.find(m => m.uuid === modifier.uuid);
      if (!modifierExists) {
        state.modifiers.push(modifier);
      }
    });
    builder.addCase(deleteModifier.pending, state => {
      state.errorMessageUpsert = undefined
      state.networkDeleteStatus = NetworkStatus.Loading;
    });
    builder.addCase(deleteModifier.rejected, (state, action) => {
      const errorMessage = action.error.message
      if (errorMessage) state.errorMessageUpsert = errorMessage
      state.networkDeleteStatus = NetworkStatus.Failed;
    });
    builder.addCase(deleteModifier.fulfilled, (state, action) => {
      state.networkDeleteStatus = NetworkStatus.Done;
      state.modifiers = state.modifiers.filter(modifier => modifier.uuid !== action.payload )
    });
  }
});

export const { setShowModifiers, clearErrorMessage, resetNetworkStatus } = modifiersSlice.actions;

export const modifiersSelector = (state: RootState) => state[modifiersSlice.name];
