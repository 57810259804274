import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { NetworkStatus } from '../../utils/network/network.constant';
import { TextBlockTranslator } from '../translators/textBlockTranslator';
import { createAppAsyncThunk } from '../../hooks/redux';

export interface ITextBlock {
  uuid: string;
  title: string;
  text: string;
  accentColor: boolean;
  button?: {
    enabled: boolean;
    text: string;
    clickUrl: string;
  };
}

interface ITextBlockSlice {
  textBlocks: ITextBlock[];
  networkStatus: NetworkStatus;
  sendNetworkStatus: NetworkStatus;
  deleteNetworkStatus: NetworkStatus;
}

export const getRootTextBlockList = createAppAsyncThunk(
  'textBlock/getRootTextBlocksList',
  async (_, thunkAPI) => {
    const result = await thunkAPI.extra.portOwnerApi().textBlockList({});

    return TextBlockTranslator.fromRootTextBlockListResponse(result);
  },
);

interface ICreateTextBlockData {
  accentColor: boolean;
  title: string;
  text: string;
  button?: {
    enabled: boolean;
    text: string;
    clickUrl: string;
  };
}

export const createTextBlock = createAppAsyncThunk(
  'textBlock/createTextBlock',
  async (data: ICreateTextBlockData, thunkAPI) => {
    const { title, text, accentColor, button } = data;

    const textBlock = {
      title,
      text,
      accentColor,
      ...(button?.enabled && button?.text && button?.clickUrl
        ? {
          button: {
            enabled: true,
            text: button.text,
            clickUrl: button.clickUrl,
          },
        }
        : {}),
    };

    const { textBlockUuid } = await thunkAPI.extra.portOwnerApi().textBlockUpsert({ textBlock });

    return {
      ...textBlock,
      uuid: textBlockUuid,
    };
  },
);

export const updateTextBlock = createAppAsyncThunk(
  'textBlock/updateTextBlock',
  async (data: ITextBlock, thunkAPI) => {
    const { title, text, accentColor, button, uuid } = data;

    const textBlock = {
      uuid,
      title,
      text,
      accentColor,
      ...(button && button.text && button.clickUrl
        ? {
          button: {
            enabled: true,
            text: button.text,
            clickUrl: button.clickUrl,
          },
        }
        : {}),
    };

    await thunkAPI.extra.portOwnerApi().textBlockUpsert({ textBlock });

    return {
      ...textBlock,
      uuid,
    };
  },
);

export const deleteTextBlock = createAppAsyncThunk(
  'textBlock/deleteTextBlock',
  async (data: ITextBlock, thunkAPI) => {
    const { uuid } = data;

    await thunkAPI.extra.portOwnerApi().textBlockDelete({
      textBlockUuid: uuid,
    });

    return uuid;
  },
);

const initialState: ITextBlockSlice = {
  textBlocks: [],
  networkStatus: NetworkStatus.None,
  sendNetworkStatus: NetworkStatus.None,
  deleteNetworkStatus: NetworkStatus.None,
};

export const textBlockSlice = createSlice({
  name: 'TextBlock',
  initialState,
  reducers: {
    resetNetworkStatus: (state, action: PayloadAction<'networkStatus' | 'sendNetworkStatus' | 'deleteNetworkStatus'>) => {
      state[action.payload] = NetworkStatus.None
    }
  },
  extraReducers: builder => {
    builder.addCase(getRootTextBlockList.pending, state => {
      state.networkStatus = NetworkStatus.Loading;
    });
    builder.addCase(getRootTextBlockList.fulfilled, (state, action) => {
      state.textBlocks = action.payload;

      state.networkStatus = NetworkStatus.Done;
    });
    builder.addCase(getRootTextBlockList.rejected, state => {
      state.networkStatus = NetworkStatus.Failed;
    });
    builder.addCase(createTextBlock.pending, state => {
      state.sendNetworkStatus = NetworkStatus.Loading;
    });
    builder.addCase(createTextBlock.fulfilled, (state, action) => {
      state.textBlocks.push(action.payload);
      state.sendNetworkStatus = NetworkStatus.Done;
    });
    builder.addCase(createTextBlock.rejected, state => {
      state.sendNetworkStatus = NetworkStatus.Failed;
    });
    builder.addCase(updateTextBlock.pending, state => {
      state.sendNetworkStatus = NetworkStatus.Loading;
    });
    builder.addCase(updateTextBlock.fulfilled, (state, action) => {
      state.textBlocks = state.textBlocks.map(el => {
        if (el.uuid === action.payload.uuid) {
          return action.payload;
        } else {
          return el;
        }
      });
      state.sendNetworkStatus = NetworkStatus.Done;
    });
    builder.addCase(updateTextBlock.rejected, state => {
      state.sendNetworkStatus = NetworkStatus.Failed;
    });
    builder.addCase(deleteTextBlock.pending, state => {
      state.deleteNetworkStatus = NetworkStatus.Loading;
    });
    builder.addCase(deleteTextBlock.fulfilled, (state, action) => {
      state.textBlocks = state.textBlocks.filter(el => `${el.uuid}` !== `${action.payload}`);
      state.deleteNetworkStatus = NetworkStatus.Done;
    });
    builder.addCase(deleteTextBlock.rejected, state => {
      state.deleteNetworkStatus = NetworkStatus.Failed;
    });
  },
});

export const {resetNetworkStatus} = textBlockSlice.actions;
export const textBlockState = (state: RootState) => state[textBlockSlice.name];
