import { IRuleFn } from '../../utils/theme/fela.interface';
import { padding } from '../../utils/applyCSSProperty ';
import { createCachedStyle } from '../../utils/theme/createCachedStyle';

export interface IModalManageAdministratorKeys {
  modalInner: {};
  header: {};
  footer: {};
  deleteButton: {};
  removeBtnWrapper: {};
  fieldWrapper: {};
  toggleWrapper: {};
  infoIcon: {};
  toggleTextWrapper: {};
  noImage: {};
  userPreview: {};
  userPreviewImage: {};
  userPreviewUsername: {};
  spinnerWrapper: {};
}

interface IModalManageAdministratorProps {
  headerHeight: number;
  footerHeight: number;
}

export type IModalManageAdministrator = IRuleFn<IModalManageAdministratorKeys, IModalManageAdministratorProps>;

const modalManageAdministratorRuleFn: IModalManageAdministrator = props => {
  const { sidePadding, colorGrayLight, colorWhite } = props.theme;
  const { headerHeight, footerHeight } = props;

  return {
    modalInner: {
      position: 'relative',
      ...padding(12, sidePadding, `${footerHeight}px`),
      maxHeight: `calc(100% - ${headerHeight}px)`,
      overflow: 'auto',
    },
    header: {
      ...padding(10, sidePadding, 12),
    },
    footer: {
      position: 'fixed',
      backgroundColor: colorWhite,
      left: 0,
      bottom: 0,
      width: '100%',
      ...padding(12,sidePadding, 30),
    },
    removeBtnWrapper: {
      ...padding(8, 0),
    },
    deleteButton: {
      marginTop: 8,
      marginBottom: 8,
    },
    fieldWrapper: {
      ...padding(8, 0)
    },
    toggleWrapper: {
      paddingBottom: 13,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      columnGap: 8,
    },
    infoIcon: {
      fill: 'black',
    },
    toggleTextWrapper: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 8,
    },
    userPreview: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 12,
      ...padding(12, 0),
    },
    userPreviewUsername: {
      marginTop: 2,
      color: colorGrayLight
    },
    userPreviewImage: {
      width: 60,
      height: 60,
      flexShrink: 0,
      borderRadius: '50%',
    },
    noImage: {
      width: 60,
      height: 60,
      borderRadius: '50%',
      backgroundColor: colorGrayLight,
      opacity: .5,
    },
    spinnerWrapper: {
      ...padding(10, 0),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }
  };
};

export const modalManageAdministratorRules = createCachedStyle(modalManageAdministratorRuleFn);
