import { FC, useMemo } from 'react';
import Text from 'src/components/UI/Text/Text';
import { useStyle } from '../../utils/theme/useStyle';
import { PromoCodeCardRules } from './PromoCodeCard.style';
import { useAppSelector } from '../../hooks/redux';
import { wizardStateSelector } from '../../redux/slices/wizardSlice';
import useCurrencySymbol from '../../hooks/useCurrencySymbol';
import { generatePath, useNavigate } from 'react-router-dom';
import { useTranslation } from '../../utils/i18n/hooks/useTranslation';
import { RoutePath } from '../../routing/routeConfig';
import { IOwnerPromocode, TypeOfDiscount } from '../../redux/slices/promoCodesSlice';

interface IProps {
  data: IOwnerPromocode
}

export const PromoCodeCard: FC<IProps> = props => {
  const {css} = useStyle(PromoCodeCardRules);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { promocode, constraintSet, enabled } = props.data;
  const {
    uniquePerUser,
    firstOrder,
    minimalPriceOrder
  } = constraintSet;
  const {
    uuid,
    impact,
    codename,
  } = promocode;
  const typeOfDiscount = impact.case;
  const discountAmount = impact.value;

  const { wizard } = useAppSelector(wizardStateSelector)
  const currencySymbol = useCurrencySymbol(wizard.currency);
  const infoString = useMemo(() => {
    const discount = `${discountAmount}${typeOfDiscount === TypeOfDiscount.PercentImpact ? '%' : ' ' + currencySymbol}`;
    const disposableText = uniquePerUser ? t('promoCodeCard.oneTime') : '';
    const firstOrderText = firstOrder ? t('promoCodeCard.firstOrder') : '';
    const minOrder = minimalPriceOrder ? t('wizardPage.minimum', Number(minimalPriceOrder), currencySymbol) : '';
    const infoElements = [discount, disposableText, firstOrderText,  minOrder].filter(Boolean);
    return infoElements.join(' · ');
  }, [
    t,
    currencySymbol,
    discountAmount,
    typeOfDiscount,
    uniquePerUser,
    firstOrder,
    minimalPriceOrder
  ])

  const onClickCard = () => {
    navigate(generatePath(RoutePath.EditPromoCode, {id: uuid}));
  }

  return (
    <div className={css.card} onClick={onClickCard}>
      <div className={css.top}>
        <Text
          text={codename}
          mod='text'
          fontWeight={800}
          fontSize={14}
          lineHeight={16}
          extend={css.title}
        />
        {!enabled && <Text
          text={t('promoCodeCard.notActive')}
          mod='text'
          fontWeight={500}
          fontSize={12}
          lineHeight={16}
          extend={css.noActive}
        />}
      </div>
      <Text text={infoString} fontWeight={500} fontSize={12} lineHeight={16} mod='text' />
    </div>
  );
};
