export enum ModalUniversalContentType {
  color = 'color',
  products = 'products',
  storeСard = 'store-card',
  currency = 'currency',
  languages = 'languages',
  storeFocus = 'store-focus',
  usersSupport = 'users-support',
  welcomeMessage = 'welcome-message',
  integrationIntoChannel = 'integration-into-channel',
  maintenanceEnabled = 'maintenance-enabled',
  manageAdministrators = 'manageAdministrators'
}
