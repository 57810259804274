import {memo, type PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import { useScrollModal } from '../../hooks/useScrollModal';
import { useStyle } from '../../utils/theme/useStyle';
import Modal, { ModalNames } from '../Modal/Modal';
import { Button } from '../UI/Button/Button';
import Text from '../UI/Text/Text';
import { modalManageAdministratorRules } from './ModalManageAdministrator.style';
import { Input } from '../UI/Input/Input';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from '../../utils/i18n/hooks/useTranslation';
import { useTheme } from '../../utils/theme/useTheme';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {
  addAdmin,
  administratorsState, deleteAdmin,
  IUser,
  resetNetworkStatus,
  storeAdminSearch, transferOwnership,
} from '../../redux/slices/administratorsSlice';
import Toggler from '../UI/Toggler/Toggler';
import { Icon } from '../UI/Icon/Icon';
import { UserRole } from '@teleport/schemas-protobuf';
import { ImageComponent } from '../ImageComponent/ImageComponent';
import { UserCard } from '../UserCard/UserCard';
import ModalConfirmAdmin from '../ModalConfirmAdmin/ModalConfirmAdmin';
import { useModalState } from '../../hooks/useModalState';
import {debounce} from '../../utils/debounce';
import {NetworkStatus} from '../../utils/network/network.constant';
import {openErrorModal} from '../../redux/slices/modalErrorSlice';
import {Spinner} from '../UI/Spinner/Spinner';

interface ModalManageAdministratorProps {
  active: boolean;
  onClose: () => void;
  adminUuid?: string;
}

interface IFormManageAdministrator {
  username: string;
  transferRightsOwner?: boolean;
}

export const ModalManageAdministrator = memo(function ModalManageAdministrator(props: PropsWithChildren<ModalManageAdministratorProps>) {
  const {active, adminUuid, onClose} = props;
  const modalInnerRef = useRef<HTMLDivElement>(null);
  const modalFooterRef = useRef<HTMLDivElement>(null);
  const modalHeaderRef = useRef<HTMLDivElement>(null);
  const {css} = useStyle(modalManageAdministratorRules, {
    headerHeight: modalHeaderRef.current?.clientHeight,
    footerHeight: modalFooterRef.current?.clientHeight,
  });
  const dispatch = useAppDispatch();
  const {administrators, foundAdmins, addAdminNetworkStatus, searchAdminNetworkStatus, deleteAdminNetworkStatus, transferOwnershipNetworkStatus} = useAppSelector(administratorsState);
  const adminData = administrators.find(el => el.identity.uuid === adminUuid);
  const [renderConfirmModal, activeConfirmModal, openConfirmModal, closeConfirmModal] = useModalState();

  const editingAdministrator = Boolean(adminUuid);
  const {
    control,
    handleSubmit,
    formState: {errors},
    watch,
    register,
  } = useForm({
    defaultValues: {
      username: adminData ? adminData.username : '',
      transferRightsOwner: adminData ? adminData.role === UserRole.OWNER : false,
    },
  });
  const { theme } = useTheme();
  const { t }  = useTranslation();
  const [confirm, setConfirm] = useState(false);
  const [deletion, setDeletion] = useState(false);
  useScrollModal(modalInnerRef);

  const transferRightOwner = watch('transferRightsOwner');
  const queryUsername = `@${watch('username')}`;
  const [foundClient, setFoundClient] = useState<IUser | undefined>(undefined);

  const closeConfirmCustom = () => {
    setDeletion(false);
    closeConfirmModal();
  }

  const confirmCallback = (value: boolean) => {
    setConfirm(value);
  }

  const onClickDeleteButton = () => {
    setDeletion(true);
    openConfirmModal();
  }

  const onSubmit: SubmitHandler<IFormManageAdministrator> = useCallback(() => {
    if(!confirm && adminData && transferRightOwner) {
      openConfirmModal();
      return;
    }
    if (confirm && !deletion) {
      dispatch(transferOwnership(adminUuid));
      setConfirm(false);
      closeConfirmModal();
      return;
    }
    if (confirm && deletion) {
      dispatch(deleteAdmin(adminUuid));
      setConfirm(false);
      closeConfirmModal();
      return;
    }
    if (foundClient && !adminData) {
      dispatch(addAdmin(foundClient.identity.uuid));
      return;
    }
    onClose();
  }, [
    deletion,
    dispatch,
    adminUuid,
    adminData,
    confirm,
    foundClient,
    closeConfirmModal,
    transferRightOwner,
    onClose,
    openConfirmModal,
  ]);

  useEffect(() => {
    if (confirm) {
      handleSubmit(onSubmit)();
    }
  }, [
    confirm,
    handleSubmit,
    onSubmit
  ])

  const searchClients = (query: string) => {
    if (query.length < 4) return
    dispatch(storeAdminSearch(query));
  }

  // TODO removed eslint-disabled 86c1nrbkf
  // eslint-disable-next-line
  const searchClientsDebounce = useCallback(debounce(searchClients, 1000), []);

  useEffect(() => {
    if (queryUsername.length < 4 || editingAdministrator) return undefined
    searchClientsDebounce(queryUsername)
  }, [queryUsername, editingAdministrator, searchClientsDebounce]);

  useEffect(() => {
    if (searchAdminNetworkStatus === NetworkStatus.Done) {
      const client = foundAdmins.find(el => el.username?.toLowerCase() === queryUsername?.toLowerCase());
      setFoundClient(client);
    }
    if (searchAdminNetworkStatus === NetworkStatus.Failed) {
      dispatch(openErrorModal({
        errorTitle: t('modalManageAdministrator.errorWhileSearchingForAUser')
      }))
      dispatch(resetNetworkStatus('searchAdminNetworkStatus'));
      setFoundClient(undefined);
    }
  }, [t, searchAdminNetworkStatus, dispatch, foundAdmins, queryUsername]);

  useEffect(() => {
    if (addAdminNetworkStatus === NetworkStatus.Done) {
      onClose();
      dispatch(resetNetworkStatus('addAdminNetworkStatus'));
    }
    if (addAdminNetworkStatus === NetworkStatus.Failed) {
      dispatch(openErrorModal({
        errorTitle: t('modalManageAdministrator.failedToAddAdmin')
      }));
      dispatch(resetNetworkStatus('addAdminNetworkStatus'));
    }
  }, [t, dispatch, addAdminNetworkStatus, onClose]);

  useEffect(() => {
    if (deleteAdminNetworkStatus === NetworkStatus.Done) {
      onClose();
      dispatch(resetNetworkStatus('deleteAdminNetworkStatus'));
    }
    if (deleteAdminNetworkStatus === NetworkStatus.Failed) {
      dispatch(openErrorModal({
        errorTitle: t('modalManageAdministrator.failedToDeleteAdmin')
      }));
    }
  }, [t, dispatch, deleteAdminNetworkStatus, onClose]);

  useEffect(() => {
    return () => {
      dispatch(resetNetworkStatus('searchAdminNetworkStatus'));
    }
  }, [dispatch]);

  const foundUsersContent = useMemo(() => {
    if (searchAdminNetworkStatus === NetworkStatus.Loading) {
      return (
        <div className={css.spinnerWrapper}>
          <Spinner />
        </div>
      )
    }
    if (foundClient) {
      return (
        <div className={css.userPreview}>
          <ImageComponent
            className={css.userPreviewImage}
            alt="user-avatar"
            src={foundClient.photo}
            fallback={<div className={css.noImage}/>}
          />
          <div>
            <Text
              text={foundClient.fullName}
              mod='text'
              fontWeight={600}
              fontSize={14}
              lineHeight={16}
            />
            <Text
              text={foundClient.username}
              mod='text'
              fontWeight={400}
              fontSize={12}
              lineHeight={14}
              extend={css.userPreviewUsername}
            />
          </div>
        </div>
      )
    }
    if (!foundClient && searchAdminNetworkStatus === NetworkStatus.Done) {
      return (
        <Text text={t('modalManageAdministrator.userNotFound')} mod='text' fontWeight={500}  />
      )
    }
    return null
  }, [t, foundClient, css, searchAdminNetworkStatus])

  return (
    <Modal
      active={active}
      name={ModalNames.Color}
      onClose={onClose}
      propsStyles={{paddingLeft: 0, paddingRight: 0, paddingBottom: 0}}
    >
      <div ref={modalHeaderRef} className={css.header}>
        <Text text={t('modalManageAdministrator.administrator')} mod="title"/>
      </div>
      <div className={css.modalInner} ref={modalInnerRef}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {adminData?.role === UserRole.ADMIN
            && (
              <div className={css.toggleWrapper}>
                <div className={css.toggleTextWrapper}>
                  <Text
                    text={t('modalManageAdministrator.transferOwnership')}
                    mod="title" fontSize={12}
                    textTransform='uppercase'
                  />
                  <Icon name='info' properties={{className: css.infoIcon}}/>
                </div>
                <Controller
                  name='transferRightsOwner'
                  control={control}
                  render={({ field: { name, onChange, value } }) => (
                    <Toggler
                      name={name}
                      checked={value}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
            )
          }
          {
            adminData
              ? (
                <div className={css.fieldWrapper}>
                  <UserCard
                    data={adminData}
                    backgroundColor={theme.background}
                  />
                </div>
              )
              : (
                <>
                  <div className={css.fieldWrapper}>
                    <Input
                      controlled={false}
                      {...register('username', {
                        required: t('modalManageAdministrator.requiredField'),
                        minLength: {
                          value: 5,
                          message: t('wizardStepEnd.clientForm.mustBeFiveSymbolsOrMore'),
                        },
                      })}
                      type="text"
                      label={t('modalManageAdministrator.username')}
                      labelTextTransform='uppercase'
                      propsStyles={{backgroundColor: theme.background}}
                      maxLength={200}
                      errorMessage={errors.username?.message}
                      symbol={'@'}
                      symbolPosition='left'
                      addLeftIndentation={10}
                    />
                  </div>
                  {foundUsersContent}
                </>
              )
          }
          {editingAdministrator
            && <Button
              extend={css.deleteButton}
              text={t('modalManageAdministrator.delete')}
              propsStyles={{
                width: '100%',
                background: theme.colorBlack,
                color: theme.colorWhite,
              }}
              disabled={
                deleteAdminNetworkStatus === NetworkStatus.Loading
                || transferOwnershipNetworkStatus === NetworkStatus.Loading
              }
              onClick={onClickDeleteButton}
            />
          }
          <div className={css.footer} ref={modalFooterRef}>
            <Button
              type='submit'
              text={t('modalManageAdministrator.save')}
              propsStyles={{
                width: '100%',
              }}
              disabled={
                addAdminNetworkStatus === NetworkStatus.Loading
                || transferOwnershipNetworkStatus === NetworkStatus.Loading
                || deleteAdminNetworkStatus === NetworkStatus.Loading
                || (editingAdministrator ? false : !Boolean(foundClient))
              }
            />
          </div>
        </form>
      </div>
      {renderConfirmModal && adminData
        && <ModalConfirmAdmin
          isDeletion={deletion}
          active={activeConfirmModal}
          onClose={closeConfirmCustom}
          confirmFn={confirmCallback}
          adminData={adminData}
        />
      }
    </Modal>
  );
});
