import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { createUpdateModifierRules } from './CreateUpdateModifier.style';
import { useModalState } from 'src/hooks/useModalState';
import { ModalUniversal } from '../ModalUniversal/ModalUniversal';
import { ModalModifierType } from '../ModalModifierType/ModalModifierType';
import { CardInfoBtn } from '../CardInfoBtn/CardInfoBtn';
import { ModalModifierVariants } from '../ModalModifierVariants/ModalModifierVariants';
import { Input } from '../UI/Input/Input';
import Text from 'src/components/UI/Text/Text';
import Toggler from '../UI/Toggler/Toggler';
import { ListButton } from '../UI/ListButton/ListButton';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';
import { Trans } from 'src/utils/i18n/components/trans';
import { useModifierTypeList } from 'src/hooks/useModifierTypeList';
import { createUpdateModifier, deleteModifier, IModifier, modifiersSelector, resetNetworkStatus } from 'src/redux/slices/modifiersSlice';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Button } from '../UI/Button/Button';
import { VALIDATION_ERRORS } from 'src/types/validation';
import { validateUrl } from 'src/utils/validateUrl';
import { linkMaxLength } from 'src/utils/constants';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'src/utils/theme/useTheme';
import ModalConfirm from '../ModalConfirm/ModalConfirm';
import { useTelegram } from 'src/utils/telegramProvider';
import { openErrorModal } from '../../redux/slices/modalErrorSlice';
import { togglesState } from '../../redux/slices/configSlice';

interface IProps {
  modifierData?: IModifier;
  paddingBottom?: number;
  inputBackgroundColor?: string;
  saveModifierCallBack?: () => void;
  showLinkFieldCallBack?: (state: boolean) => void;
}

export const CreateUpdateModifier: FC<IProps> = memo(function CreateUpdateModifier(props) {
  const { modifierData, paddingBottom, inputBackgroundColor, showLinkFieldCallBack, saveModifierCallBack } = props;
  const [footerHeight, setFooterHeight] = useState(0);
  const { css } = useStyle(createUpdateModifierRules, {footerHeight, paddingBottom});
  const { t, tPlural } = useTranslation()
  const {  networkUpsertStatus, errorMessageUpsert, networkDeleteStatus } = useAppSelector(modifiersSelector);
  const { showBackendErrors } = useAppSelector(togglesState)
  const [linkDescr, setLinkDescr] = useState(modifierData?.descriptionLink? true : false);
  const [renderModal2, activeModal2, openModal2, closeModal2] = useModalState();
  const [renderModal3, activeModal3, openModal3, closeModal3] = useModalState();
  const [renderModal4, activeModal4, openModal4, closeModal4] = useModalState();

  const { theme } = useTheme();
  const { colorBlack, colorGrey } = theme;
  const Telegram = useTelegram();
  
  const navigate = useNavigate();

  const dispatch = useAppDispatch()

  const modifierTypes = useModifierTypeList()

  //state for modifier type
  const [modifierTypeInfoId, setModifierTypeInfoId] = useState<number | undefined>(undefined);
  const [renderDeleteModal, activeDeleteModal, openDeleteModal, closeDeleteModal] = useModalState();

  const { 
    handleSubmit, 
    watch, 
    register, 
    control, 
    setValue,
    formState: { errors }, 
    setError,
    clearErrors
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      uuid: modifierData?.uuid ?? '',
      affectsPrice: modifierData?.affectsPrice ?? false,
      title: modifierData?.title ?? '',
      uiType: modifierData?.uiType ?? 0,
      variants: modifierData?.variants ?? [
        { uuid: '', title: '',},
        { uuid: '', title: '', },
      ],
      descriptionLink: modifierData?.descriptionLink ?? '',
    },
  });

  const onSubmit: SubmitHandler<IModifier> = data => {
    if (data.uiType === 0) {
      setError('uiType', {
        type: 'manual',
        message: t('createUpdateModifier.selectModifierType'),
      });
      return;
    }
    const variantsWithCorrectIdAndTitle = data.variants.filter(variant => variant.title.length > 0)
    if (variantsWithCorrectIdAndTitle.length < 1) {
      setError('variants', {
        type: 'manual',
        message: t('createUpdateModifier.selectAtLeastOneOption'),
      });
      return;
    }

    dispatch(createUpdateModifier({
      ...data,
      variants: variantsWithCorrectIdAndTitle
    }))
  };

  const deleteModifierFn = () => {
    if (modifierData?.uuid) dispatch(deleteModifier(modifierData.uuid))
  };

  const closeConfirmModal = (isClickOk: boolean) => {
    if (isClickOk) {
      deleteModifierFn();
    }
  };

  const onClickDelete = () => {
    if (Telegram.webApp.initData) {
      Telegram.webApp.showConfirm(t('createUpdateModifier.delete'), closeConfirmModal);
    } else {
      openDeleteModal();
    }
  };

  useEffect(() => {
    if (!modifierTypeInfoId) return;
    openModal3();
  }, [modifierTypeInfoId, openModal3]);

  const variantsFromForm = watch('variants')

  const variantsPlaceholder = useMemo(() => {
    const filledVariants = variantsFromForm
      .filter(variant => variant.title.trim() !== '')
      .map(variant => variant.title); 
    return filledVariants.length > 0 ? filledVariants.join(', ') : t('createUpdateModifier.fillInOptionsForUserSelection');
  }, [variantsFromForm, t]);

  const modalFooterRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setFooterHeight(node.clientHeight);
    }
  }, []);

  const setVariantsFunction = variants => {
    clearErrors('variants');
    setValue('variants', variants)
  }

  const toggleDescriptionLink = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.checked) setValue('descriptionLink', '')
    setLinkDescr(e.target.checked);
    showLinkFieldCallBack && showLinkFieldCallBack(e.target.checked);
  }

  useEffect(() => {
    if (networkUpsertStatus === NetworkStatus.Done) {
      dispatch(resetNetworkStatus('networkUpsertStatus'));
      dispatch(resetNetworkStatus('networkStatus'))
      if (saveModifierCallBack) saveModifierCallBack();
    }
  }, [networkUpsertStatus, dispatch, navigate, saveModifierCallBack])

  useEffect(() => {
    if ( networkUpsertStatus === NetworkStatus.Failed ) {
      dispatch(openErrorModal({
        errorTitle: t('createUpdateModifier.failedToUpdateTheModifier'),
        errorText: showBackendErrors ? errorMessageUpsert : ''
      }))
    }
    if (networkDeleteStatus === NetworkStatus.Failed) {
      dispatch(openErrorModal({
        errorTitle: t('createUpdateModifier.failedToDeleteTheModifier'),
        errorText: showBackendErrors ? errorMessageUpsert : ''
      }))
    }
  }, [
    t,
    dispatch,
    showBackendErrors,
    errorMessageUpsert,
    networkUpsertStatus,
    networkDeleteStatus,
  ])


  return (
    <form className={css.form} onSubmit={handleSubmit(onSubmit)}>
      <div className={css.wrapper}>
        <Input
          controlled={false}
          name={'title'}
          type="text"
          label={t('createUpdateModifier.name')}
          placeholder={t('createUpdateModifier.enterModifierName')}
          labelTextTransform={'uppercase'}
          {...register('title', {
            required: VALIDATION_ERRORS.REQUIRED,
          })}
          propsStyles={{ backgroundColor:  inputBackgroundColor}}
          errorMessage={errors.title?.message}
        />
        <div>
          <div className={css.justifyWrapper}>
            <Text text={t('createUpdateModifier.affectsPrice')} mod="title" fontSize={12} textTransform={'uppercase'} />
            <Controller
              name="affectsPrice"
              control={control}
              render={({ field: { name, onChange } }) => (
                <Toggler
                  name={name}
                  checked={watch('affectsPrice')}
                  value="show-in-recomendation"
                  onChange={onChange}
                />
              )}
            />
          </div>
          <ListButton
            text={
              modifierTypes.find(el => el.id === watch('uiType'))
                ? modifierTypes.find(el => el.id === watch('uiType')).title
                : undefined
            }
            titleUppercase={true}
            padding="12px 0"
            titleFontSize={12}
            bg="transparent"
            title={t('createUpdateModifier.type')}
            placeholder={errors.uiType?.message? errors.uiType.message : t('createUpdateModifier.chooseFromTheList')}
            onClick={() => openModal2()}
            redPlaceholder={errors.uiType?.message.length > 0}
          />
          <ListButton
            titleUppercase={true}
            padding="12px 0"
            titleFontSize={12}
            bg="transparent"
            title={t('createUpdateModifier.options')}
            placeholder={errors.variants?.message? errors.variants.message : variantsPlaceholder}
            onClick={() => openModal4()}
            redPlaceholder={errors.variants?.message.length > 0}
          />
          <div className={css.justifyWrapper}>
            <Text text={t('createUpdateModifier.linkWithModifierDescription')} mod="title" fontSize={12} textTransform={'uppercase'} />
            <Toggler
              checked={linkDescr}
              name="show-in-recomendation"
              value="show-in-recomendation"
              onChange={toggleDescriptionLink}
            />
          </div>
          {linkDescr && (
            <Input
              {...register('descriptionLink', {
                required: VALIDATION_ERRORS.REQUIRED,
                validate: {
                  checkUrl: validateUrl,
                },
                maxLength: {
                  value: linkMaxLength,
                  message: tPlural('createUpdateModifier.linkShouldBeLessThanCharacters', linkMaxLength)
                },
              })}
              propsStyles={{ backgroundColor:  inputBackgroundColor}}
              controlled={false}
              name={'descriptionLink'}
              type="text"
              label={t('createUpdateModifier.link')}
              placeholder={t('createUpdateModifier.enterLink')}
              errorMessage={errors.descriptionLink?.message}
              message={
                <Trans
                  text={t(
                    'createUpdateModifier.weRecommendToUseTeletypeSoUsersDontLeaveYourStore',
                    '<0>',
                    '</0>',
                  )}
                  components={[
                    <a key={1} href="https://teletype.in/" target="_blank" rel="noreferrer" />,
                  ]}
                />
              }
            />
          )}
        </div>
        {modifierData && (
          <div className={css.dellButtonWrapper}>
            <Button
              text={t('createUpdateModifier.delete')}
              propsStyles={{ background: colorGrey, color: colorBlack, width: '100%' }}
              hasGradient={false}
              onClick={onClickDelete}
              disabled={networkDeleteStatus === NetworkStatus.Loading}
            />
          </div>
        )}
      </div>

      <div className={css.footer} ref={modalFooterRef}>
        <Button
          text={t('createUpdateModifier.saveModifier')}
          propsStyles={{ width: '100%' }}
          type="submit"
          disabled={networkUpsertStatus === NetworkStatus.Loading || networkDeleteStatus === NetworkStatus.Loading}
        />
      </div>
      {renderModal2 && (
        <ModalModifierType active={activeModal2} onClose={closeModal2}>
          {modifierTypes.map(el => {
            const onClick = () => {
              closeModal2();
              setValue('uiType', el.id)
              clearErrors('uiType');
            }
            const onInfoClick = () => {
              setModifierTypeInfoId(el.id)
            }
            return (
              <CardInfoBtn
                key={el.id}
                title={el.title}
                text={el.text}
                isActive={watch('uiType') === el.id}
                onClick={onClick}
                onInfoClick={onInfoClick}
              />
            );
          })}
        </ModalModifierType>
      )}
      {renderModal3 && (
        <ModalUniversal
          deep={2}
          title={modifierTypes.find(el => el.id === modifierTypeInfoId)?.title}
          subtitle={modifierTypes.find(el => el.id === modifierTypeInfoId)?.infoText}
          active={activeModal3}
          onClose={() => {
            closeModal3();
            setModifierTypeInfoId(undefined);
          }}
        >
          <div className={css.templateWrapper}>
            <img src={modifierTypes.find(el => el.id === modifierTypeInfoId)?.infoImg} alt="" />
          </div>
        </ModalUniversal>
      )}
      {renderModal4 && (
        <ModalModifierVariants
          active={activeModal4}
          onClose={closeModal4}
          variantsArr={variantsFromForm}
          setVariantsArr={setVariantsFunction}
        />
      )}
      {renderDeleteModal && (
        <ModalConfirm
          active={activeDeleteModal}
          onClose={closeDeleteModal}
          title={t('createUpdateModifier.delete')}
          confirmAction={deleteModifierFn}
        />
      )}
    </form>
  );
});
