import { SubsctiptionStatusGetResponse } from '@teleport/schemas-protobuf-billing-v1/billing_grpc_pb';

export const SubscriptionTranslator = {
  fromSubscriptionStatusGetResponse(data: SubsctiptionStatusGetResponse) {
    return {
      active: data.status.active,
      subscriptionTariff: {
        uuid: data.status.subscriptionTariff.uuid,
        subscriptionPeriod: data.status.subscriptionTariff.subscriptionPeriod,
        currency: data.status.subscriptionTariff.currency,
        price: Number(data.status.subscriptionTariff.price),
      },
      subscriptionTriggerType: data.status.subscriptionTriggerType,
      startedAt: data.status.startedAt,
      finishedAt: data.status.finishedAt,
      deletedAt: data.status.deletedAt,
    }
  },
};
