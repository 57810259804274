import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { padding } from 'src/utils/applyCSSProperty ';

export interface IIntegrationsBlockKeys {
  header: {};
  wrapperJustify: {};
  grid: {};
  content: {};
  titleWrp: {};
}

export type IIntegrationsBlock = IRuleFn<IIntegrationsBlockKeys>;

const IntegrationsBlockRuleFn: IIntegrationsBlock = props => {

  const { colorBlack } = props.theme;
  return {
    header: {
      ...padding(16),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 10,
    },
    grid: {
      display: 'grid',
      flexDirection: 'column',
      gap: 12,
    },
    content: {
      display: 'grid',
      flexDirection: 'column',
      gap: 14,
      paddingTop: 9,
      paddingBottom: 9,
    },
    wrapperJustify: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    titleWrp: {
      display: 'flex',
      alignItems: 'center',
      gap: 12,
      '& svg': {
        fill: colorBlack,
      }
    }
  };
};

export const integrationsBlockRules = createCachedStyle(IntegrationsBlockRuleFn);
