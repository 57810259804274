import { Navigate, Outlet } from 'react-router-dom';
import {InfraProvisioningStatus, UserRole} from '@teleport/schemas-protobuf';
import { useAppSelector } from '../../hooks/redux';
import { wizardStateSelector } from '../../redux/slices/wizardSlice';
import { NetworkStatus } from '../../utils/network/network.constant';
import { FC, memo } from 'react';
import useLoader from '../../hooks/useLoader';
import { RoutePath } from '../routeConfig';
import {userStateSelector} from '../../redux/slices/userSlice';
import {multipleStoreState} from '../../redux/slices/multipleStoreSlice';

interface IProps {
  isWizard?: boolean;
  isManageAdmins?: boolean;
}

export const ProtectedRoutes: FC<IProps> = memo(function ProtectedRoutes(props) {
  const {isWizard, isManageAdmins} = props;
  const {
    complete: wizardIsComplete,
    infraProvisioningStatus,
    networkStatus,
    startCreatingStore,
  } = useAppSelector(wizardStateSelector);
  const { userRole } = useAppSelector(userStateSelector);
  const { networkStatus: getStoreListNetworkStatus } = useAppSelector(multipleStoreState);
  const { hideLoader } = useLoader();

  // todo use separate network status
  if (networkStatus !== NetworkStatus.Done && !startCreatingStore) {
    return null;
  }

  const shouldRedirectToWizard = !wizardIsComplete
    || infraProvisioningStatus === InfraProvisioningStatus.CREATE_IN_PROGRESS
    || infraProvisioningStatus === InfraProvisioningStatus.UNSPECIFIED
    || infraProvisioningStatus === InfraProvisioningStatus.CREATE_ERROR;

  hideLoader();

  if (isWizard) {
    return startCreatingStore || shouldRedirectToWizard
      ? <Outlet/>
      : <Navigate to={RoutePath.Main} replace/>
  }

  if (isManageAdmins && !shouldRedirectToWizard) {
    return userRole !== UserRole.OWNER && getStoreListNetworkStatus === NetworkStatus.Done
      ? <Navigate to={RoutePath.StoreSetup} replace/>
      : <Outlet/>
  }

  return shouldRedirectToWizard
    ? <Navigate to={RoutePath.Wizard} replace/>
    : <Outlet/>;
});
