import { FC, memo, useCallback } from 'react';
import { Step } from 'src/components/Step/Step';
import { useStyle } from 'src/utils/theme/useStyle';
import { WizardAppFocusRules } from './WizardAppFocus.style';
import { useTranslation } from '../../../utils/i18n/hooks/useTranslation';
import { setAppFocus, wizardStateSelector } from '../../../redux/slices/wizardSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { OptionCard } from '../../../components/OptionCard/OptionCard';
import { useAppFocusList } from '../../../hooks/useAppFocusList';

export const WizardAppFocus: FC = memo(function WizardAppFocus() {
  const { wizard: { appFocus } } = useAppSelector(wizardStateSelector);
  const { css } = useStyle(WizardAppFocusRules);

  const dispatch = useAppDispatch();
  const focusList = useAppFocusList();

  const { t } = useTranslation();

  const onChangeMethod = useCallback(event => {
    const newAppCase = event.target.value;
    dispatch(setAppFocus({
      value: 0,
      case: newAppCase
    }));
  }, [dispatch]);

  return (
    <Step
      stepNumber={3}
      title={t('wizardAppFocus.chooseApplicationType')}
    >
      <div className={css.wrapperForList}>
        {
          focusList.map(el => (
            <OptionCard
              key={el.id}
              name="theme-store"
              title={el.title}
              text={el.text}
              type="radio"
              value={el.type}
              checked={appFocus?.case === el.type}
              onChange={onChangeMethod}
            />
          ))
        }
      </div>
    </Step>
  );
});
