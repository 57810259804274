import { FC, memo, useEffect } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { Layout } from 'src/components/Layout/Layout';
import { NoSettings } from 'src/components/NoSettings/NoSettings';
import Footer from 'src/components/Footer/Footer';
import { Button } from 'src/components/UI/Button/Button';
import Text from 'src/components/UI/Text/Text';
import Container from 'src/components/Container/Container';
import { useModalState } from 'src/hooks/useModalState';
import { SettingSliderPageRules } from './SettingSliderPage.style';
import { SlidesWrapper } from 'src/blocks/settings-main/setting-slider/SlidesWrapper/SlidesWrapper';
import { ModalSettingSlider } from 'src/blocks/settings-main/setting-slider/ModalSettingSlider/ModalSettingSlider';
import useLoader from 'src/hooks/useLoader';
import useBackButton from 'src/hooks/useBackButton';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { SliderState } from 'src/redux/slices/sliderSlice';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { getSliderList } from 'src/redux/api/slider/getSliderList';
import { createSlider } from 'src/redux/api/slider/createSlider';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';
import { openErrorModal } from '../../redux/slices/modalErrorSlice';

export const SettingSliderPage: FC = memo(function SettingSliderPage(props) {
  const {} = props;
  const { css } = useStyle(SettingSliderPageRules);
  const [renderModal, activeModal, openModal, closeModal] = useModalState();
  const { hideLoader } = useLoader();
  const BackButton = useBackButton();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { slider, networkStatus, upsertNetworkStatus } = useAppSelector(SliderState);
  const { t } = useTranslation();

  useEffect(() => {
    if (upsertNetworkStatus === NetworkStatus.Failed) {
      dispatch(openErrorModal({
        errorTitle: t('settingSliderPage.theSliderCouldNotBeUpdated')
      }));
    }
  }, [t, dispatch, upsertNetworkStatus]);

  useEffect(() => {
    BackButton.onClickCustom(goBack);
    function goBack() {
      navigate('/');
    }
    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  useEffect(() => {
    if (networkStatus === NetworkStatus.None) {
      dispatch(getSliderList()).then(() => {
        hideLoader();
      });
    }
  }, [networkStatus, dispatch, hideLoader]);

  function openSettingSliderModal() {
    if (!slider) {
      dispatch(createSlider());
    }
    openModal();
  }

  return (
    <Layout
      padding={'4px 0 0'}
      header={
        <header className={`${css.header} header`}>
          <Text
            text={t('settingSliderPage.slider')}
            mod="title"
            fontWeight={800}
            fontSize={20}
            lineHeight={22}
          />
        </header>
      }
      footer={
        <Footer>
          <Button
            text={t('settingSliderPage.createSlide')}
            propsStyles={{ width: '100%' }}
            onClick={openSettingSliderModal}
          />
        </Footer>
      }
    >
      <Container extend={css.container}>
        {slider?.items.length > 0 ? (
          <SlidesWrapper slides={slider.items} />
        ) : (
          <NoSettings
            title={t('settingSliderPage.youHaventCreatedAnySlidesYet')}
            text={t('settingSliderPage.slidesAreLargeImagesOnTheMainScreenToGrabAttention')}
            icon="slider-large"
          />
        )}
      </Container>
      {renderModal && <ModalSettingSlider active={activeModal} onClose={closeModal} />}
    </Layout>
  );
});
