import { IRuleFn } from '../../utils/theme/fela.interface';
import { padding } from '../../utils/applyCSSProperty ';
import { createCachedStyle } from '../../utils/theme/createCachedStyle';

export interface IModalConfirmAdminKeys {
  modalInner: {};
  footer: {};
  info: {};
  images: {};
  image: {};
  noImage: {};
  adminAvatar: {};
  title: {};
}

interface IModalConfirmAdminProps {
  footerHeight: number;
  isDeletion: boolean;
}

export type IModalConfirmAdmin = IRuleFn<IModalConfirmAdminKeys, IModalConfirmAdminProps>;

const modalConfirmAdminRuleFn: IModalConfirmAdmin = props => {
  const { sidePadding, colorGrayLight, colorWhite } = props.theme;
  const { footerHeight, isDeletion } = props;

  return {
    modalInner: {
      position: 'relative',
      ...padding(12, sidePadding, footerHeight),
      overflow: 'auto',
    },
    footer: {
      position: 'fixed',
      backgroundColor: colorWhite,
      left: 0,
      bottom: 0,
      width: '100%',
      ...padding(12,sidePadding, 30),
    },
    title: {
      marginBottom: 8,
    },
    info: {
      paddingTop: 48,
      paddingBottom: 40,
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
    },
    images: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      marginBottom: 16,
    },
    image: {
      width: 64,
      height: 64,
      borderRadius: '50%'
    },
    adminAvatar: {
      marginLeft: isDeletion ? 0 : -15,
    },
    noImage: {
      width: 64,
      height: 64,
      borderRadius: '50%',
      backgroundColor: colorGrayLight
    },
  };
};

export const modalConfirmAdminRules = createCachedStyle(modalConfirmAdminRuleFn)
