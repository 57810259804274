import { createAppAsyncThunk } from 'src/hooks/redux';

export const getProductDetail = createAppAsyncThunk(
  'product/getProductDetail',
  async (uuid: string, thunkAPI) => {
    return await thunkAPI.extra.portOwnerApi().ownerCatalogProductDetail({
      productUuid: uuid,
    })
  },
);
