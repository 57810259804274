import { FC, memo, useEffect } from 'react';
import Footer from 'src/components/Footer/Footer';
import { Layout } from 'src/components/Layout/Layout';
import { Button } from 'src/components/UI/Button/Button';
import Text from 'src/components/UI/Text/Text';
import { useStyle } from 'src/utils/theme/useStyle';
import { telegramIntegrationPageRules } from './TelegramIntegrationPage.style';
import useLoader from 'src/hooks/useLoader';
import { wizardStateSelector } from 'src/redux/slices/wizardSlice';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { useNavigate } from 'react-router-dom';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { wizardGetState } from 'src/redux/api/wizard/wizardGetState';
import useBackButton from 'src/hooks/useBackButton';
import { useTelegram } from 'src/utils/telegramProvider';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';
import { getChannelIntegrationDetail, integrationState } from 'src/redux/slices/integrationSlice';
import { togglesState } from 'src/redux/slices/configSlice';
import { openErrorModal } from '../../redux/slices/modalErrorSlice';
import { IntegrationBlock } from 'src/components/IntegrationBlock/IntegrationBlock';
import { useTheme } from 'src/utils/theme/useTheme';

export const TelegramIntegrationPage: FC = memo(function TelegramIntegrationPage() {
  const { css } = useStyle(telegramIntegrationPageRules);
  const { hideLoader } = useLoader();
  const navigate = useNavigate();
  const BackButton = useBackButton();
  const dispatch = useAppDispatch();
  const { networkStatus } = useAppSelector(wizardStateSelector);
  const { networkStatus: getIntegrationDetailNetworkStatus, saveNetworkStatus: upsertIntegrationNetworkStatus, errorText} = useAppSelector(integrationState);
  const Telegram = useTelegram();

  const { theme } = useTheme();

  const { t } = useTranslation();

  const { showBackendErrors } = useAppSelector(togglesState);

  useEffect(() => {
    if (getIntegrationDetailNetworkStatus === NetworkStatus.None) {
      dispatch(getChannelIntegrationDetail())
    }
    if (networkStatus === NetworkStatus.None) {
      dispatch(wizardGetState({}));
    }
    if (networkStatus === NetworkStatus.Done) {
      hideLoader();
    }
  }, [networkStatus, dispatch, hideLoader, getIntegrationDetailNetworkStatus]);

  useEffect(() => {
    if (getIntegrationDetailNetworkStatus === NetworkStatus.Failed || upsertIntegrationNetworkStatus === NetworkStatus.Failed) {
      dispatch(openErrorModal({
        errorTitle: t('telegramIntegrationPage.failedToIntegrateIntoTheChannel'),
        errorText: showBackendErrors ? errorText : ''
      }))
    }
  }, [
    t,
    dispatch,
    errorText,
    showBackendErrors,
    upsertIntegrationNetworkStatus,
    getIntegrationDetailNetworkStatus
  ])

  useEffect(() => {
    BackButton.onClickCustom(goBack);
    function goBack() {
      navigate(-1);
    }
    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  const connectToSupport = () => {
    Telegram.webApp.openTelegramLink('https://t.me/TeleportHelpBot');
  };

  return (
    <Layout
      header={
        <header className={`${css.header} header`}>
          <Text
            text={t('telegramIntegrationPage.telegram')}
            mod='title'
            fontWeight={800}
            fontSize={20}
            lineHeight={22}
          />
        </header>
      }
      footer={
        <Footer>
          <Button hasGradient={false} text={t('telegramIntegrationPage.contactSupport')} onClick={connectToSupport} propsStyles={{ background: theme.colorBlack, width: '100%' }} />
        </Footer>
      }
    >
      <IntegrationBlock />
    </Layout>
  );
});
