import { CatalogMetadataResponse } from '@teleport/schemas-protobuf';
import { ICategoryMetadata, ISubCategoryMetadata } from 'src/types/categories';

export const catalogMetadataTranslator = {
  fromCatalogMetadataResponse(response: CatalogMetadataResponse): ICategoryMetadata[] {
    const categories = response.categories
      .map(item => {
        if (item.category.type.case === 'rootCategory') {
          return {
            category: item.category.type.value,
            productsCount: item.productsCount,
          };
        }
        return null;
      })
      .filter(Boolean);
    const subcategories = response.categories
      .map(item => {
        if (item.category.type.case === 'subCategory') {
          return {
            subCategory: item.category.type.value,
            productsCount: item.productsCount,
          };
        }
        return null;
      })
      .filter(Boolean);

    const translatorResult = categories.map(item => {
      const childSubcategories = subcategories.filter(
        el => item.category.uuid === el.subCategory.categoryUuid,
      );
      const translatedSubcategories: ISubCategoryMetadata[] = childSubcategories.map(el => ({
        categoryId: el.subCategory.categoryUuid,
        uuid: el.subCategory.uuid,
        title: el.subCategory.title,
        productsCount: el.productsCount,
      }));

      return {
        title: item.category.title,
        uuid: item.category.uuid,
        productsCount: item.productsCount,
        subCategories: translatedSubcategories,
      };
      // { ...item, subCategories: childSubcategories };
    });

    return translatorResult;
  },
};
