import { FC, memo, useEffect, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { welcomeMessageRules } from './WelcomeMessage.style';
import { ImageLoader } from '../UI/ImageLoader/ImageLoader';
import { VALIDATION_ERRORS } from 'src/types/validation';
import { useTheme } from 'src/utils/theme/useTheme';
import { Input } from '../UI/Input/Input';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button } from '../UI/Button/Button';
import { WizardTranslator } from 'src/redux/translators/wizardTranslator';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { wizardStateSelector } from 'src/redux/slices/wizardSlice';
import { wizardSaveState } from 'src/redux/api/wizard/wizardSaveState';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';
import { minimumResolution} from './welcomeMessage.constant';

interface IForm {
  welcomeMessage: string;
}

export const WelcomeMessage: FC = memo(function WelcomeMessage() {
  const { css } = useStyle(welcomeMessageRules);

  const { theme } = useTheme();
  const dispatch = useAppDispatch();

  const { wizard, saveNetworkStatus } = useAppSelector(wizardStateSelector);
  const [savedSuccessfully, setSavedSuccessfully] = useState(false);

  const [welcomeImgFile, setWelcomeImgFile] = useState<null | File>();
  const [welcomeImgUrl, setWelcomeImgUrl] = useState<string>('');

  useEffect(() => {
    if(wizard.welcomeImage) {
      setWelcomeImgUrl(wizard.welcomeImage);
    }
  }, [wizard]);

  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      welcomeMessage: wizard.welcomeMessage,
    },
  });

  useEffect(() => {
    if (saveNetworkStatus === NetworkStatus.Done) {
      setSavedSuccessfully(true);
      setTimeout(() => setSavedSuccessfully(false), 1500);
    }
  }, [saveNetworkStatus]);

  const onSubmit: SubmitHandler<IForm> = data => {
    const { welcomeMessage } = data;
    const wizardStateRequest = WizardTranslator.toWizardSaveStateRequest(
      // 'end',
      { ...wizard, welcomeMessage },
      true,
    );

    dispatch(
      wizardSaveState({
        requestData: wizardStateRequest,
        welcomeImageFile: welcomeImgFile,
      }),
    );
  };

  const changeWelcomeImg = (img: File) => {
    setWelcomeImgFile(img);
  };

  return (
    <form className={css.form} onSubmit={handleSubmit(onSubmit)}>
      <ImageLoader
        imageUrl={welcomeImgUrl}
        onChangeFile={changeWelcomeImg}
        name="welcome-message-image"
        propsStyles={{ width: 284, height: 164, borderRadius: '8px' }}
        message={t('storeSetupPage.minimumResolution', minimumResolution)}
      />
      <div className={css.textBlock}>
        <Input
          {...register('welcomeMessage', { required: VALIDATION_ERRORS.REQUIRED })}
          type="text"
          label={t('storeSetupPage.textPlaceholder')}
          controlled={false}
          letterCounter={true}
          placeholder={t('storeSetupPage.enterTheMessage')}
          propsStyles={{ backgroundColor: theme.background }}
          errorMessage={errors.welcomeMessage?.message}
          maxLength={512}
        />
      </div>
      <Button
        disabled={saveNetworkStatus === NetworkStatus.Loading || savedSuccessfully}
        successfully={savedSuccessfully}
        text={t('storeSetupPage.save')}
        propsStyles={{ width: '100%' }}
        type="submit"
      />
    </form>
  );
});
