import { FC, memo, useEffect, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { BlocksOrderWrapperRules } from './BlocksOrderWrapper.style';
import {
  DndContext,
  PointerSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { BlockCard } from '../BlockCard/BlockCard';
import {BlockType, setUiBlockOrder, uiBlocksState} from 'src/redux/slices/uiBlocksSlice';
import {useAppDispatch, useAppSelector} from 'src/hooks/redux';
// https://docs.dndkit.com/presets/sortable - документация dragAndDrop

export const BlocksOrderWrapper: FC = memo(function BlocksOrderWrapper() {
  const dispatch = useAppDispatch();
  const { order: orderBlocks, uiBlocks } = useAppSelector(uiBlocksState);

  const { css } = useStyle(BlocksOrderWrapperRules);
  const [disabledItems, setDisabledItems] = useState<string[]>([]);

  useEffect(() => {
    setDisabledItems(orderBlocks.filter(id => id === BlockType.StoriesBlock));
  }, [orderBlocks]);

  const sensors = useSensors(
    useSensor(TouchSensor, {
      // activationConstraint: {
      //   delay: 250,
      //   tolerance: 5,
      // },
    }),
    useSensor(PointerSensor),
  );

  let ts: number | undefined;
  const onTouchStart = (e: TouchEvent) => {
    ts = e.touches[0].clientY;
  };
  const onTouchMove = (e: TouchEvent) => {
    const te = e.changedTouches[0].clientY;
    if (ts < te) {
      e.preventDefault();
    }
  };

  function handleDragStart() {
    document.documentElement.addEventListener('touchstart', onTouchStart, { passive: false });
    document.documentElement.addEventListener('touchmove', onTouchMove, { passive: false });
  }

  // Функция изменяющая порядок элементов в нашем массиве items
  function handleDragEnd(event) {
    document.documentElement.removeEventListener('touchstart', onTouchStart);
    document.documentElement.removeEventListener('touchmove', onTouchMove);

    const { active, over } = event;

    if (active.id !== over.id) {
      const dragEl = orderBlocks.find(blockId => blockId === active.id);
      const dropEl = orderBlocks.find(blockId => blockId === over.id);

      const oldIndex = orderBlocks.indexOf(dragEl);
      const newIndex = orderBlocks.indexOf(dropEl);

      let newArr = arrayMove(orderBlocks, oldIndex, newIndex);

      // После каждого перетаскивания
      // ставим заблокированные элементы на прежние места.
      const disabledItemsIndexes: Record<number, string> = {};
      disabledItems.map(el => (disabledItemsIndexes[orderBlocks.indexOf(el)] = el));

      newArr = newArr.filter(el => el !== BlockType.StoriesBlock);
      Object.entries(disabledItemsIndexes).forEach(disabledItem => {
        newArr.splice(Number(disabledItem[0]), 0, disabledItem[1]);
      });

      dispatch(setUiBlockOrder(newArr));
    }
  }

  return (
    <div className={css.wrapper}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={orderBlocks} strategy={verticalListSortingStrategy}>
          {orderBlocks.map(item => {
            const block = uiBlocks[item];
            if (!block) return null;
            return (
              <BlockCard
                key={item}
                blockData={block}
                disabled={item === BlockType.StoriesBlock}
              />
            )
          })}
        </SortableContext>
      </DndContext>
    </div>
  );
});
