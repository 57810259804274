import { FC, memo } from 'react';
import { useStyle } from '../../utils/theme/useStyle';
import { ImageComponent } from '../ImageComponent/ImageComponent';
import { userCardRules } from './UserCard.style';
import { useTranslation } from '../../utils/i18n/hooks/useTranslation';
import { UserRole } from '@teleport/schemas-protobuf';
import {IUser} from '../../redux/slices/administratorsSlice';


interface IProps {
  data: IUser,
  backgroundColor?: string;
  onClick?: (uuid: string) => void
}

export const UserCard: FC<IProps> = memo(function Step(props) {
  const { data, onClick, backgroundColor } = props;
  const { username, identity, photo, role } = data;
  const { css } = useStyle(userCardRules, {
    backgroundColor
  });
  const { t } = useTranslation();

  const onClickCard = () => {
    if (onClick) {
      onClick(identity.uuid);
    }
  }

  return (
    <div className={css.card} onClick={onClickCard}>
      <div className={css.imgWrapper}>
        <ImageComponent
          className={css.image}
          alt="slide-img"
          src={photo}
          fallback={<div className={css.noImage} />}
        />
      </div>
      <div className={css.textInfo}>
        <p className={css.username}>{username}</p>
        <p className={css.role}>{role === UserRole.OWNER ? t('userCard.owner') : t('userCard.admin')}</p>
      </div>
    </div>
  );
});
